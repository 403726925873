<template>
    <span class="relative inline-flex">
        <button
            id="navNotificationsButton"
            class="inline-flex flex-row bg-gray-800 hover:bg-gray-300 p-1.5 rounded-lg text-gray-400 hover:text-gray-600 focus:outline-hidden focus:ring-2 focus:ring-offset-gray-800 focus:ring-offset-2 focus:ring-primary-500"
            @click="openNotifications"
        >
            <span class="sr-only">View notifications</span>
            <BellIcon class="h-6 w-6" aria-hidden="true" />
            <span>{{ allUnreadNotificationsCount }}</span>
        </button>

        <span
            v-if="allUnreadNotificationsCount"
            class="flex absolute h-3 w-3 top-0 right-0"
        >
            <span
                class="animate-ping absolute inline-flex h-full w-full rounded-full bg-pink-400 opacity-75"
            />
            <span class="relative inline-flex rounded-full h-3 w-3 bg-pink-500" />
        </span>
    </span>

    <teleport to="body">
        <Notifications
            :open="showNotifications"
            @close="showNotifications = false"
            @update-all-unread-notifications-count="allUnreadNotificationsCount = $event"
        />
    </teleport>
</template>

<script lang="ts" setup>
import { BellIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import Notifications from '../overlays/Notifications.vue';

const showNotifications = ref(false);
const openNotifications = () => {
    showNotifications.value = true;
};
const allUnreadNotificationsCount = ref(0);
</script>
