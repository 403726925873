<template>
    <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-1">
        <div class="flex flex-row gap-2 items-center">
            <slot />
        </div>
    </th>
</template>

<script lang="ts" setup>

</script>
