<template>
    <!-- Session Expired -->
    <base-modal
        :icon="ExclamationTriangleIcon"
        :colour="BaseColour.Red"
        :open="sessionExpiredStore.getSessionExpired"
        :close-func="() => null"
    >
        <h4 class="text-xl mb-2">
            Session Expired
        </h4>Your session has expired. Please login again to continue.
        <template #footer>
            <base-button
                class="w-full sm:mt-0 sm:w-auto ml-2"
                text="Login"
                :colour="BaseColour.Red"
                :click-func="goToLogin"
            />
        </template>
    </base-modal>
</template>

<script lang="ts" setup>
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid';

import { BaseColour } from '@conveyr/shared-types';
import { useSessionExpiredStore } from '@/stores/overlays/sessionExpired';

const sessionExpiredStore = useSessionExpiredStore();

const goToLogin = () => {
    sessionStorage.clear();
    location.href = '/login';
};
</script>
