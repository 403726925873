import posthog from 'posthog-js';
import { Spark } from '@/compiler/types';

export default {
    install() {
        const spark = (window as any).Spark as Spark;
        const { posthog: { apiKey, apiHost } = {} } = spark.analyticsKeys;

        if (apiKey && apiHost) {
            posthog.init(apiKey, {
                api_host: apiHost,
                autocapture: false,
                cross_subdomain_cookie: false,
                enable_recording_console_log: true,
                opt_in_site_apps: true,
            });
        }
    },
};
