<template>
    <base-slideover
        :open="open"
        :close-func="close"
        title="Notifications"
        size="lg"
    >
        <div class="mt-2 px-2">
            <ul
                v-if="notifications?.length"
                class="divide-y divide-gray-200 overflow-y-auto"
            >
                <li
                    v-for="notification in notifications"
                    :key="notification.id"
                    class="relative px-4 py-2"
                >
                    <div class="flex items-center">
                        <div class="min-w-0 flex-1 flex items-center">
                            <div class="min-w-0 flex-1">
                                <div>
                                    <div class="mt-1 flex items-center text-sm leading-5 text-gray-500">
                                        <span v-html="notification.body" />
                                    </div>
                                </div>
                                <div class="mt-1 flex justify-between text-sm items-center">
                                    <div class="flex items-center text-gray-500">
                                        <CalendarIcon class="shrink-0 mr-1.5 h-5 w-5 text-gray-400" />

                                        <span>
                                            <time>{{ relative(notification.createdAt) }}</time>
                                        </span>
                                    </div>
                                    <a
                                        v-if="notification.actionText"
                                        class="flex items-center text-primary-500"
                                        :href="notification.actionUrl"
                                    >
                                        <ArrowTopRightOnSquareIcon class="shrink-0 mr-1.5 h-5 w-5" />
                                        {{ notification.actionText }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <base-alert
                v-if="!loadingNotifications && !notifications?.length"
                class="my-2"
            >
                We don't have any notifications for you right now, but when we do, we'll be sure to let you
                know.
            </base-alert>
        </div>
    </base-slideover>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { ArrowTopRightOnSquareIcon, CalendarIcon } from '@heroicons/vue/20/solid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import useFormatters from '@/composables/useFormatters';
import API from '@/api';
import { userNotificationInterface } from '@/api/notifications';

const props = defineProps<{
    open: boolean
}>();

const emit = defineEmits<{
    (e: 'close'): void
    (e: 'updateAllUnreadNotificationsCount', value: number): void
}>();

dayjs.extend(utc);

const { relative } = useFormatters();
const loadingNotifications = ref(true);
const notifications = ref<userNotificationInterface[]>([]);

watch(() => props.open, () => {
    if (props.open) {
        getNotifications().then(() => {
            const unreadNotifications = notifications.value.filter(notification => notification.read === false);

            if (!unreadNotifications.length) return;

            API.notifications.updateUserNotificationsRead();

            notifications.value = notifications.value.map((notification) => {
                notification.read = true;

                return notification;
            });

            emit('updateAllUnreadNotificationsCount', unreadNotificationsCount.value);
        });
    }
});

const close = () => {
    emit('close');
};

const getNotifications = async () => {
    notifications.value = await API.notifications.fetchRecentUserNotifications();
    loadingNotifications.value = false;

    emit('updateAllUnreadNotificationsCount', unreadNotificationsCount.value);
};

const unreadNotificationsCount = computed(() => {
    if (notifications.value) {
        return notifications.value.filter((notification) => {
            return !notification.read;
        }).length;
    }

    return 0;
});

getNotifications();
</script>
