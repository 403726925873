<template>
    <div class="relative z-10 shrink-0 flex h-16 bg-gray-800 shadow-sm">
        <button
            class="px-4 border-r border-gray-200 text-gray-500 focus:outline-hidden focus:ring-2 focus:ring-inset focus:ring-primary-500 md:hidden"
            @click="openSidebar"
        >
            <span class="sr-only">Open sidebar</span>
            <Bars3BottomLeftIcon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div v-if="!isServiceProviderSelectPage" class="flex-1 px-4 flex">
            <div class="relative flex items-center justify-between h-16 w-full">
                <SelectMerchant v-if="userHasRoleOnCurrentServiceProvider && !isAdminRoute" />
                <h2
                    v-else-if="!isAdminRoute"
                    class="self-center mr-4 text-gray-300"
                >
                    {{ currentMerchantOptional?.name }}
                </h2>

                <UniversalSearch />
            </div>
            <div class="ml-4 flex items-center md:ml-6 justify-end shrink-0 space-x-1">
                <StationStatuses v-if="userHasRoleOnCurrentServiceProvider" />

                <OperationsTopbarIcon />

                <NotificationsTopbarIcon />

                <HelpDropdownMenu />

                <!-- Profile dropdown -->
                <ProfileDropdownMenu />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { Bars3BottomLeftIcon } from '@heroicons/vue/24/outline';
import ProfileDropdownMenu from './ProfileDropdownMenu.vue';
import HelpDropdownMenu from './HelpDropdownMenu.vue';
import NotificationsTopbarIcon from './NotificationsTopbarIcon.vue';
import OperationsTopbarIcon from './OperationsTopbarIcon.vue';
import SelectMerchant from '@/components/layouts/nav/SelectMerchant.vue';
import UniversalSearch from '@/components/layouts/nav/UniversalSearch.vue';
import StationStatuses from '@/components/layouts/nav/StationStatuses.vue';
import { useNarrative } from '@/composables/useNarrative';

const emit = defineEmits<{
    (e: 'openSidebar'): void
}>();

const { userHasRoleOnCurrentServiceProvider, currentMerchantOptional } = useNarrative();

const openSidebar = () => {
    emit('openSidebar');
};

const isServiceProviderSelectPage = computed(
    () => window.location.href.includes('subdomain-select'),
);

const isAdminRoute = computed(() => window.location.pathname.startsWith('/admin'));
</script>
