<template>
    <div class="fixed bottom-0 right-0 flex flex-row-reverse items-end gap-2 pr-2 max-w-lg">
        <template v-for="operation in operations" :key="operation.getId()">
            <OperationToast :operation="operation" />
        </template>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import OperationToast from './OperationToast.vue';
import { Operation, isDisplayableInFooterOperation, useOperationsStore } from '@/stores/operations/operations';

const operationsStore = useOperationsStore();
const operations = computed<Operation[]>(() => {
    return operationsStore.operations.filter((operation) => {
        if (isDisplayableInFooterOperation(operation)) {
            return operation.displayInFooter();
        }
        return false;
    });
});
</script>
