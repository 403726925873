import { userInterface } from './users';
import axios from '@/helpers/forms/axios';

export interface printerInfoInterface {
    identifier: string
    brand?: string
    widthCm: string
    heightCm: string
    density: string
    rawType: 'epl' | 'zpl' | 'none'
}

export interface stationInterface {
    id: number
    userId: number | null
    user?: userInterface
    name: string
    hostAddress: string
    scanner?: string
    shippingLabelPrinter?: string
    shippingLabelPrinterInfo?: printerInfoInterface
    barcodeLabelPrinter?: string
    barcodeLabelPrinterInfo?: printerInfoInterface
    paperPrinter?: string
    scale?: string
    scannerConfiguration?: object
    shippingLabelPrinterConfiguration?: {
        density?: 80 | 120
        orientation?: 'auto' | 'landscape' | 'portrait'
    }
    barcodeLabelPrinterConfiguration?: {
        size?: string
        flip?: boolean
        density?: 80 | 120
        orientation?: 'auto' | 'landscape' | 'portrait'
        useDivider?: boolean
    }
    paperPrinterConfiguration?: object
    scaleConfiguration?: object
}

export const fetchStation = async (stationId: number): Promise<stationInterface> => {
    const response = await axios.get<{ station: stationInterface }>(`/api/staff/stations/${stationId}`);

    return response.data.station;
};

export const fetchStations = async (): Promise<stationInterface[]> => {
    const response = await axios.get<{ stations: stationInterface[] }>('/api/staff/stations');

    return response.data.stations;
};

export const fetchCurrentStation = async (): Promise<stationInterface> => {
    const response = await axios.get<{ data: stationInterface }>('/api/staff/stations/current');

    return response.data.data;
};

export const deleteStation = async (stationId: number): Promise<void> => {
    await axios.delete(`/api/staff/stations/${stationId}`);
};
