import { ItemGroupConfiguration } from '@conveyr/shared-types';
import axios from '@/helpers/forms/axios';

export interface ItemGroupConfigurationRequest {
    quantity: number
    type: 'box' | 'pallet'
    lengthMm: number
    widthMm: number
    heightMm: number
    weightGm: number
    contains: {
        configurationId: number
        quantity: number
    }[]
}

const fetchConfigurations = async (itemIds: number[]): Promise<ItemGroupConfiguration[]> => {
    let uri = '/api/inventory/group-configurations?';
    itemIds.forEach(itemId => uri += `item_ids[]=${itemId}&`);

    const response = await axios.get<{ data: ItemGroupConfiguration[] }>(uri);

    return response.data.data;
};

const createConfiguration = async (itemId: number, data: ItemGroupConfigurationRequest): Promise<ItemGroupConfiguration> => {
    const response = await axios.post<{ data: ItemGroupConfiguration }>(`/api/inventory/${itemId}/group-configurations`, data);

    return response.data.data;
};

const updateConfiguration = async (itemId: number, configurationId: number, data: ItemGroupConfigurationRequest): Promise<ItemGroupConfiguration> => {
    const response = await axios.patch<{ data: ItemGroupConfiguration }>(`/api/inventory/${itemId}/group-configurations/${configurationId}`, data);

    return response.data.data;
};

const deleteConfigurations = async (itemId: number, configurationId: number): Promise<void> => {
    return await axios.delete(`/api/inventory/${itemId}/group-configurations/${configurationId}`);
};

const makeDefaultConfiguration = async (itemId: number, configurationId: number): Promise<void> => {
    return await axios.get(`/api/inventory/${itemId}/group-configurations/${configurationId}/default`);
};

export default {
    fetchConfigurations,
    createConfiguration,
    updateConfiguration,
    deleteConfigurations,
    makeDefaultConfiguration,
};
