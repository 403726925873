<template>
    <teleport to="body">
        <base-badge
            v-if="isScanning"
            size="sm"
            :icon="BarcodeLabelSolidIcon"
            :colour="BaseColour.Yellow"
            class="absolute bottom-5 right-10 z-20"
        >
            Scanning...
        </base-badge>

        <base-badge
            v-if="scannedBarcode"
            size="sm"
            :icon="BarcodeLabelSolidIcon"
            :colour="BaseColour.Green"
            class="absolute bottom-5 right-10 z-20"
        >
            Scan: {{ scannedBarcode }}
        </base-badge>
    </teleport>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue';
import { BaseColour } from '@conveyr/shared-types';
import { providerKeys } from '@/composables/useProviders';
import { BarcodeLabelSolidIcon } from '@/helpers/icons';

const barcodeListener = inject(providerKeys.barcodeListener);
const isScanning = computed((): boolean => {
    if (!barcodeListener) return false;

    if (barcodeListener.scannedBarcode.value) {
        return false;
    }

    return Boolean(barcodeListener.isScanning.value);
});

const scannedBarcode = computed((): string => {
    if (!barcodeListener) return '';

    return barcodeListener.scannedBarcode.value;
});
</script>
