import axios from '@/helpers/forms/axios';

export interface universalSearchResultInterface {
    id: number
    teamId: number
    name?: string
    title?: string
    merchantSku?: string
    referenceId?: string
    isCaseForwarding?: string
    fbaShipmentId?: string
    inboundPlanId?: string
}

export const universalSearch = async (query: string): Promise<universalSearchResultInterface[]> => {
    const response = await axios.get<{ items: universalSearchResultInterface[] }>(`/api/search?q=${query}`);

    return response.data.items;
};
