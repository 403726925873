import { Currency } from 'dinero.js';
import { merchantInterface } from '@/api/repositories/merchants';
import { ServiceProviderPermissionEnum, serviceProviderInterface } from '@/api/repositories/serviceProviders';
import { tableItemInterface } from '@/helpers/tables';
import { AnalyticsKeys } from '@/composables/useAnalytics';

export enum AppEntitlementEnum {
    BaseAccess = 'base_access',
    Locations = 'locations',
    AmazonShipments = 'amazon_shipments',
    Orders = 'orders',
}

export type AppPermissionEnum = ServiceProviderPermissionEnum | 'viewCompanyServicesPrice' | 'editCompanyService';

export interface Spark {
    env: 'local' | 'production' | 'demo' | 'testing' | 'staging'
    stripeKey: string
    appName: string
    appBaseDomain: string
    serviceProvider?: serviceProviderInterface
    merchant?: merchantInterface
    impersonatingUserId: number | null
    pusherAppKey?: string
    pusherHost?: string
    sentryPublicDsn?: string
    releaseTag?: string
    analyticsKeys: AnalyticsKeys
    chargebeeSite: string
    featurebaseUserIdHash: string | null
    featurebaseServiceProviderIdHash: string | null
    featurebaseOrganization: string | null
}

export interface dropdownItemInterface {
    text: string
    href?: string
    icon?: Function
    clickFunc?: Function
}

export interface shipmentNameFormInterface {
    name: string
}

export interface SelectOption<
    TValue = string | number | boolean,
> {
    value: TValue
    name: string
    disabled?: boolean
}

export interface navigationInterface {
    name: string
    href: string
    icon: Function
    activeIf: RegExp
    count?: number
}

export interface defaultIntertiaPropsInterface {
    errors: { [key: string]: string }
    flash: {
        successMessages: string[]
        errorMessages: string[]
    }
}

export interface moneyInterface {
    amount: number
    currency: Currency
}

export interface requestFilterInterface {
    name: string
    value: string
}

export interface DataFetcherSort {
    field: string
    direction: 'asc' | 'desc'
    order: number
}

export interface DataFetcherResponse<TData> {
    data: TData[]
    total: number
    perPage: number
    currentPage: number
    lastPage: number
    from: number
    to: number
}

export interface DataFetcherFunction<TData> {
    (page: number, pageSize: number, sorts: DataFetcherSort[], filters: string): Promise<DataFetcherResponse<TData>>
}

export interface PaginatedRequestFunction<T> {
    (page: number, filters?: requestFilterInterface[]): Promise<PaginatedResults<T>>
};

export interface PaginatedResults<T> {
    data: (T & tableItemInterface)[]
    total: number
    perPage: number
    currentPage: number
    lastPage: number
    firstPageUrl: string
    lastPageUrl: string
    nextPageUrl: string
    prevPageUrl: string
    path: string
    from: number
    to: number
};
