<template>
    <div class="bg-primary-500 w-full px-3 rounded-b-xs border-b border-primary-500" :class="{ 'pb-2 pt-3': progressDescription, 'py-3': !progressDescription }">
        <div class="relative w-full h-4">
            <div class="absolute inset-0 bg-primary-50 rounded-full" />
            <div
                class="absolute inset-y-0 left-0 bg-primary-200 text-xs font-medium text-primary-500 flex items-center justify-center rounded-full"
                :class="{ 'animate-pulse-slow': progressNumber < 100 }"
                :style="{ width: `${width}%` }"
            >
                {{ progressNumber }}%
            </div>
            <div v-if="progressNumber < 100" class="absolute right-0 rounded-full">
                <OperationChecker :operation="operation" />
            </div>
        </div>
        <p v-if="progressDescription" class="text-xs italic text-primary-200/90 text-loading text-left -mb-1">
            {{ progressDescription }}
        </p>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import OperationChecker from './OperationChecker.vue';
import { Operation, isDescribableProgressOperation } from '@/stores/operations/operations';

const props = defineProps<{
    operation: Operation
}>();

const progressNumber = computed(() => {
    return Math.ceil(Math.min(Math.max(props.operation.getProgress(), 1), 100));
});

const width = computed(() => {
    return Math.max(progressNumber.value, 10);
});

const progressDescription = computed((): string | null => {
    if (!isDescribableProgressOperation(props.operation)) return null;

    const description = props.operation.getProgressDescription();
    if (!description) return null;

    return description.trim();
});
</script>
