import { UnwrapRef } from 'vue';
import { BaseColour, Tag, TagType } from '@conveyr/shared-types';
import axios from '@/helpers/forms/axios';
import { SparkFormType, sendForm } from '@/helpers/forms/form';

export interface createTagFormInterface {
    name: string
    colour: BaseColour
    type: TagType
}

export interface editTagFormInterface {
    name: string
    colour: BaseColour
}

const fetchTags = async (type?: TagType): Promise<Tag[]> => {
    const url = new URL('/api/tags', location.origin);
    if (type) {
        url.searchParams.append('type', type);
    }

    const response = await axios.get<Tag[]>(url.toString());

    return response.data;
};

const deleteTag = async (tagId: number): Promise<void> => {
    return axios.delete(`/api/tags/${tagId}`);
};

const createTag = async (form: UnwrapRef<SparkFormType<createTagFormInterface>>): Promise<Tag> => {
    const response = await sendForm({ method: 'POST', uri: '/api/tags', form });

    return response.tag;
};

const editTag = async (tagId: number, form: UnwrapRef<SparkFormType<editTagFormInterface>>): Promise<void> => {
    await sendForm({ method: 'put', uri: `/api/tags/${tagId}`, form });
};

export default {
    fetchTags,
    deleteTag,
    createTag,
    editTag,
};
