import { Item } from '@conveyr/shared-types';
import adjustments from './adjustments';
import bundles from './bundles';
import identifiers from './identifiers';
import images from './images';
import fixItems from './fixItems';
import reconcile from './reconcile';
import search from './search';
import stocks from './stocks';
import axios from '@/helpers/forms/axios';

export interface CreateInventoryItemRequest {
    title: string
    merchantSku: string
    condition?: string | null
    conditionNote?: string | null
    lengthMm?: number | null
    widthMm?: number | null
    heightMm?: number | null
    weightGm?: number | null
}

const createInventoryItem = async (request: CreateInventoryItemRequest): Promise<Item> => {
    const response = await axios.post<{ data: Item }>('/api/inventory', request);

    return response.data.data;
};

export default {
    adjustments,
    bundles,
    identifiers,
    images,
    fixItems,
    reconcile,
    search,
    stocks,

    createInventoryItem,
};
