<template>
    <BaseBanner
        v-if="status?.hasPaymentDueInvoices"
        :colour="BaseColour.Red"
        text="A recent payment for your subscription has failed, please update your billing information."
        action-url="/staff/service-provider-billing"
        action-text="Update Billing Information"
        :is-external-url="true"
    />

    <BaseBanner
        v-if="status?.hasTrialSubscription && status.trialEndsAt"
        :colour="BaseColour.Primary"
        :text="`You are on a free trial ending on ${date(status.trialEndsAt)}.`"
    />
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { BaseColour } from '@conveyr/shared-types';
import { useNarrative } from '@/composables/useNarrative';
import { ServiceProviderBillingStatus } from '@/api/serviceProviderBilling';
import API from '@/api';
import BaseBanner from '@/components/elements/BaseBanner.vue';
import useFormatters from '@/composables/useFormatters';

const { userIsOfficeEmployeeOnServiceProvider, userIsOwnerOnServiceProvider } = useNarrative();

const { date } = useFormatters();

const status = ref<ServiceProviderBillingStatus | null>(null);
if (userIsOfficeEmployeeOnServiceProvider.value || userIsOwnerOnServiceProvider.value) {
    status.value = await API.serviceProviderBilling.checkStatus();
}
</script>
