import { FbaTransportV2024ItemPrepDetail, ItemPrepDetailInput } from '@conveyr/shared-types';
import axios from '@/helpers/forms/axios';

const fetchPrepDetails = async (channelId: number, mskus: string[], fresh: boolean = false): Promise<FbaTransportV2024ItemPrepDetail[]> => {
    const response = await axios.get<{ data: FbaTransportV2024ItemPrepDetail[] }>('/api/fba-transport/v2024/item-prep-details', {
        params: {
            channel_id: channelId.toString(),
            mskus,
            fresh: fresh ? '1' : '0',
        },
    });

    return response.data.data;
};

const setPrepDetails = async (channelId: number, prepDetails: ItemPrepDetailInput[]): Promise<string> => {
    const response = await axios.post(`/api/fba-transport/v2024/item-prep-details`, {
        channelId,
        prepDetails,
    });

    return response.data;
};

export default {
    fetchPrepDetails,
    setPrepDetails,
};
