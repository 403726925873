import { OperationProblem } from './operations';
import { WorkflowStatus } from './temporal';

export type WebsocketEventType = UserWebsocketEventType | PrintJobWebsocketEventType | ServiceProviderWebsocketEventType;
export interface WebsocketEvent {
    event: WebsocketEventType
    data: WebsocketEventData
}

export enum ServiceProviderWebsocketEventType {
    WorkflowUpdate = 'WorkflowUpdate',
    FbaTransportV2024PlanUpdated = 'FbaTransportV2024PlanUpdated',
}

export interface ServiceProviderWebsocketEvent extends WebsocketEvent {
    event: ServiceProviderWebsocketEventType
}

export interface WorkflowUpdateWebsocketEvent extends ServiceProviderWebsocketEvent {
    event: ServiceProviderWebsocketEventType.WorkflowUpdate
    data: {
        workflowId: string
        progress: number
        progressDescription: string | null
        status: WorkflowStatus
        problems: OperationProblem[] | null
    }
}

type WebsocketEventDataType = string | number | object | boolean | null;
export interface WebsocketEventData {
    [key: string]: WebsocketEventDataType | Array<WebsocketEventDataType>
}

export enum UserWebsocketEventType {
    MarkAmazonShipmentAsDeletedFailed = 'MarkAmazonShipmentAsDeletedFailed',
    MarkShipmentAsShippedFailed = 'MarkShipmentAsShippedFailed',
    LabelPrintReady = 'LabelPrintReady',
    LabelPrintFailed = 'LabelPrintFailed',
}

export enum PrintJobWebsocketEventType {
    PrintJobCreated = 'PrintJobCreated',
}
