import { defineStore } from 'pinia';
import { FnskuLabel } from '@/api/labels';

interface State {
    printLabelItems: FnskuLabel[]
    useSlideoverId: string
}

export const useLabelPrintsStore = defineStore('labelPrints', {
    state: (): State => ({
        printLabelItems: [],
        useSlideoverId: 'printItemFnskuLabelsSlideover',
    }),
    getters: {
        getPrintLabelItems: state => state.printLabelItems,
    },
    actions: {
        printLabels(value: FnskuLabel[]): void {
            this.printLabelItems = value;
        },
        clearLabels(): void {
            this.printLabelItems = [];
        },
        resetSlideoverId(): void {
            this.useSlideoverId = 'printItemFnskuLabelsSlideover';
        },
    },
});
