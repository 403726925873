import { ServiceProviderBillingStatus } from '../serviceProviderBilling';
import axios, { fetchPaginatedDataFunction } from '@/helpers/forms/axios';

export interface ChargebeeServiceProviderAccount {
    id: number
    name: string
    createdAt: string
    updatedAt: string
    serviceProviderId: number
    chargebeeAccountId: string
    subscriptionIds: string[]
}

const fetchChargebeeAccounts = fetchPaginatedDataFunction<ChargebeeServiceProviderAccount>(
    '/api/admin/service-provider-billing/chargebee/accounts',
);

const createChargebeeAccountLink = async (serviceProviderId: number, chargebeeAccountId: string): Promise<void> => {
    return await axios.post(`/api/admin/service-provider-billing/chargebee/accounts`, {
        serviceProviderId,
        chargebeeAccountId,
    });
};

const getAccountStatus = async (serviceProviderId: number): Promise<ServiceProviderBillingStatus> => {
    const response = await axios.get<ServiceProviderBillingStatus>(`/api/admin/service-provider-billing/status?service_provider_id=${serviceProviderId}`);

    return response.data;
};

export default {
    fetchChargebeeAccounts,
    createChargebeeAccountLink,
    getAccountStatus,
};
