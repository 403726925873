import axios, { fetchPaginatedDataFunction } from '@/helpers/forms/axios';

export enum UsageType {
    OutboundShipmentItems = 'outbound_shipment_items',
}

export interface UsageMetricEvent {
    id: string
    event: string
    eventData: object
    occurredAt: string
    usageType: UsageType
    usageEffect: number
}

export interface UsageMetricOutboundShipmentItemEvent extends UsageMetricEvent {
    eventData: {
        itemId: number
        itemSku: string
        quantity: number
        outboundShipmentId: number
        outboundShipmentItemId: number
    }
    usageType: UsageType.OutboundShipmentItems
}

export interface ServiceProviderBillingStatus {
    hasPaymentDueInvoices: boolean
    unpaidInvoicesTotal: {
        amount: number
        currency: string
    }
    hasTrialSubscription: boolean
    trialEndsAt: string | null
}

const fetchUsageMetricEvents = fetchPaginatedDataFunction<UsageMetricEvent>(
    '/api/service-provider-billing/usage-metric-events',
);

const checkStatus = async (): Promise<ServiceProviderBillingStatus | null> => {
    const response = await axios.get<ServiceProviderBillingStatus | null>('/api/service-provider-billing/status');

    return response.data;
};

const fetchPortal = async (): Promise<object> => {
    const response = await axios.get('/api/service-provider-billing/portal');

    return response.data.data?.portalSession;
};

const generateNewCheckout = async (serviceProviderId: number): Promise<object> => {
    const response = await axios.get(`/api/service-provider-billing/hosted-page-new-checkout`, {
        headers: {
            'X-Service-Provider-Id': serviceProviderId,
        },
    });

    return response.data.data;
};

export default {
    fetchUsageMetricEvents,
    checkStatus,
    generateNewCheckout,
    fetchPortal,
};
