<template>
    <div>
        <label v-if="label" :for="id" class="block text-sm font-medium text-gray-700">{{ label }}</label>
        <div class="mt-1 rounded-md shadow-xs flex">
            <div class="relative flex items-stretch grow focus-within:z-10">
                <!-- Textarea -->
                <textarea
                    :id="id"
                    :value="calculatedValue()"
                    :name="formProp ? formProp : name"
                    :class="inputClasses"
                    :placeholder="placeholder"
                    :autofocus="autofocus"
                    :required="required"
                    :disabled="disabled"
                    @input="handleInput(getValueFromEvent($event))"
                />

                <!-- Errors don't show if this field not present, weird Vue issue -->
                <span v-if="form && form.busy" class="hidden" />

                <!-- Error Icon -->
                <div
                    v-if="isForm && form.errors.has(formProp)"
                    class="inset-y-0 right-0 pr-3 pt-2 flex pointer-events-none"
                    :class="prefix ? '-ml-8' : 'absolute'"
                >
                    <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
            </div>
        </div>

        <!-- Error Text -->
        <p v-if="isForm && form.errors.has(formProp)" class="mt-1 text-sm text-red-600">
            <span>{{ form.errors.get(formProp) }}</span>
        </p>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { ExclamationCircleIcon } from '@heroicons/vue/20/solid';
import get from 'lodash/get';
import { SparkFormType } from '../../../helpers/forms/form';

const props = defineProps({
    id: {
        type: String,
        default: () => {
            return `Input-${Math.floor(Math.random() * 100000)}${1}`;
        },
    },
    label: {
        type: String,
        default: null,
    },
    prefix: {
        type: String,
        default: null,
    },
    suffix: {
        type: String,
        default: null,
    },
    icon: {
        type: Function,
        default: null,
    },
    name: {
        type: String,
        default: null,
    },
    placeholder: {
        type: String,
        default: null,
    },
    disabled: {
        type: Boolean,
        default: null,
    },
    form: {
        type: Object,
        validator: (prop: SparkFormType<any>) => Object.prototype.hasOwnProperty.call(prop, 'busy'),
        default: null,
    },
    formProp: {
        type: String,
        default: null,
    },
    weightFunc: {
        type: Function,
        default: null,
    },
    weightBusy: {
        type: Boolean,
        default: false,
    },
    autofocus: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    inputClass: {
        type: String,
        default: '',
    },
});

const model = defineModel();

const inputClasses = computed(() => {
    let classes = 'block w-full sm:text-sm shadow-xs';

    /* This line required to fix weird Vue issue, otherwise error classes don't show */
    if (props.form?.busy.value) {
        classes += '';
    };

    if (props.form?.errors.has(props.formProp)) {
        classes += ' pr-10 border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500';
    } else {
        classes += ' border-gray-300 placeholder-gray-300 focus:ring-primary-500 focus:border-primary-500';
    }

    if (props.icon) {
        classes += ' pl-10';
    }
    if (props.suffix && props.prefix) {
        classes += ' flex-1 px-3 py-2 rounded-none';
    } else if (props.suffix) {
        classes += ' flex-1 px-3 py-2 rounded-none rounded-l-md';
    } else if (props.prefix) {
        classes += ' flex-1 px-3 py-2 rounded-none rounded-r-md';
    } else {
        // No prefix or suffix
        classes += ' rounded-md';
    }

    return `${classes} ${props.inputClass}`;
});

const isForm = computed(() => {
    return Boolean(props.form && props.formProp);
});

const calculatedValue = () => {
    if (isForm.value) {
        return get(props.form, props.formProp);
    } else if (model.value !== null) {
        return model.value;
    }

    return null;
};

const handleInput = (value: any) => {
    if (isForm.value) {
        props.form.setProperty(props.formProp, value);
    } else {
        model.value = value;
    }
};

const getValueFromEvent = (event: Event): string | number => {
    const targetValue = (event.target as HTMLInputElement).value;

    return targetValue;
};
</script>
