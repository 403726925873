<template>
    <BaseSlideover
        :id="id"
        :open="Boolean(items.length)"
        :close-func="close"
        title="Print FNSKU Labels"
        size="xl"
        zindex="30"
    >
        <div class="mt-2 px-2">
            <base-alert
                v-if="items.length === 0"
                class="my-2"
                :colour="BaseColour.Yellow"
            >
                No Items Found
            </base-alert>

            <base-table v-if="items.length" class="mt-1">
                <template #headers>
                    <base-table-header>Item</base-table-header>
                    <base-table-header>Quantity</base-table-header>
                    <base-table-header v-if="showExpiryDate">
                        Expiry Date
                    </base-table-header>
                    <base-table-header />
                </template>
                <tr
                    v-for="item in items"
                    :id="`printItemRow-${item.fnsku}`"
                    :key="item.fnsku"
                    class="bg-white"
                >
                    <base-table-cell padding="narrow">
                        <div class="flex flex-col">
                            <p>{{ item.title }} </p>
                            <p class="text-sm text-gray-400">
                                {{ item.fnsku }}
                            </p>
                        </div>
                    </base-table-cell>

                    <base-table-cell padding="narrow">
                        <div class="mt-1 rounded-md shadow-xs relative">
                            <input
                                :id="`printItemQuantityInput-${item.fnsku}`"
                                v-model="item.count"
                                class="border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200/50 block min-w-0 w-full sm:text-sm sm:leading-5"
                                type="number"
                            >
                        </div>
                    </base-table-cell>
                    <base-table-cell v-if="showExpiryDate" padding="narrow">
                        <div class="mt-1 rounded-md shadow-xs relative">
                            <input
                                v-model="item.expiryDate"
                                class="rounded-md border border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200/50 block min-w-0 w-full sm:text-sm sm:leading-5"
                                type="date"
                                name="expiry-date"
                                :min="today"
                            >
                        </div>
                    </base-table-cell>
                    <base-table-cell padding="narrow">
                        <base-button
                            size="xs"
                            :icon="TrashIcon"
                            :colour="BaseColour.Red"
                            :click-func="() => deleteItem(item)"
                        />
                    </base-table-cell>
                </tr>
            </base-table>
        </div>

        <template #footer>
            <base-button :icon="PrinterIcon" text="Print" :click-func="print" :busy="printing" />
        </template>
    </BaseSlideover>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { PrinterIcon, TrashIcon } from '@heroicons/vue/20/solid';
import dayjs from 'dayjs';
import { BaseColour } from '@conveyr/shared-types';
import API from '@/api';
import useValidation from '@/composables/useValidation';
import { useLabelPrintsStore } from '@/stores/overlays/labelPrints';
import { useNarrative } from '@/composables/useNarrative';
import BaseSlideover from '@/components/elements/BaseSlideover.vue';
import usePopupNotifications from '@/composables/usePopupNotifications';
import { FnskuLabel } from '@/api/labels';

const props = defineProps<{
    id: string
}>();

const items = ref<(FnskuLabel[])>([]);

const labelPrintsStore = useLabelPrintsStore();
const { currentServiceProvider } = useNarrative();

labelPrintsStore.$onAction(({ after }) => {
    after(() => {
        if (labelPrintsStore.useSlideoverId !== props.id) {
            return;
        }

        items.value = labelPrintsStore.getPrintLabelItems;
    });
});

const { validateExpiryDate } = useValidation();
const { notifyError } = usePopupNotifications();
const printing = ref(false);

const showExpiryDate = computed((): boolean => {
    return Boolean(currentServiceProvider.value.viewSettings?.shipments?.addItems?.showExpiryDate?.enabled);
});

const close = () => {
    labelPrintsStore.clearLabels();
};

const print = () => {
    items.value.forEach((item) => {
        if (item.expiryDate && !validateExpiryDate(item.expiryDate)) {
            notifyError(`Invalid expiry date for ${item.title}`);
        }
    });

    printing.value = true;

    API.labels.requestFnskuLabels(items.value).finally(() => {
        printing.value = false;
    });
};

const deleteItem = (item: FnskuLabel) => {
    const itemIndex = items.value.findIndex(findItem => findItem.fnsku !== item.fnsku);

    items.value.splice(itemIndex, 1);
};

const today = dayjs().add(1, 'day').format('YYYY-MM-DD');
</script>
