<template>
    <div ref="target" class="flex-1 hidden justify-center px-2 md:flex w-full">
        <div class="w-full">
            <label for="search" class="sr-only">Search</label>
            <div class="relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <MagnifyingGlassIcon
                        v-if="!isSearching"
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                    <base-spinner v-if="isSearching" class="text-gray-500 w-4 h-4 mr-3" />
                </div>
                <input
                    id="universalSearch"
                    v-model="searchTerm"
                    name="search"
                    class="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-gray-700 text-gray-300 placeholder-gray-400 focus:outline-hidden focus:bg-white focus:border-white focus:ring-white focus:text-gray-900 sm:text-sm"
                    :placeholder="searchText"
                    type="search"
                    @keyup="searchRequest"
                >

                <Popover>
                    <transition
                        enter-active-class="transition ease-out duration-100"
                        enter-from-class="opacity-0 translate-y-1"
                        enter-to-class="opacity-100 translate-y-0"
                        leave-active-class="transition ease-in duration-100"
                        leave-from-class="opacity-100 translate-y-0"
                        leave-to-class="opacity-0 translate-y-1"
                    >
                        <PopoverPanel
                            v-if="results.length"
                            class="absolute z-10 left-0 mt-3 px-2 w-full min-w-[450px] sm:px-0"
                            static
                        >
                            <div
                                class="rounded-lg shadow-lg ring-1 ring-black/5 overflow-hidden"
                            >
                                <div class="relative grid bg-white py-2 px-2">
                                    <div v-for="(item, index) in results" :key="index" class="group">
                                        <BaseLink
                                            :id="`universalSearchResult-${index}`"
                                            :key="item.name"
                                            :href="item.href"
                                            class="flex flex-row items-start justify-between rounded-sm group-hover:bg-primary-500 p-1"
                                            @click="clear"
                                        >
                                            <div class="flex flex-row">
                                                <component
                                                    :is="item.icon"
                                                    class="shrink-0 h-5 w-5 text-gray-600 group-hover:text-gray-50 self-center"
                                                    aria-hidden="true"
                                                />
                                                <div class="ml-2">
                                                    <p
                                                        class="text-sm font-medium text-gray-700 group-hover:text-gray-50"
                                                    >
                                                        {{ truncate(item.name, (merchantName(item.teamId) ? 30 : 50)) }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                v-if="merchantName(item.teamId)"
                                                class="ml-2 text-gray-400 group-hover:text-gray-200 flex flex-row"
                                            >
                                                <UserGroupIcon
                                                    class="shrink-0 h-4 w-4 mr-1"
                                                    aria-hidden="true"
                                                />
                                                <p class="text-sm">
                                                    {{ truncate(merchantName(item.teamId) as string, 15)
                                                    }}
                                                </p>
                                            </div>
                                        </BaseLink>
                                    </div>
                                </div>
                            </div>
                        </PopoverPanel>
                    </transition>
                </Popover>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ArchiveBoxIcon, InboxArrowDownIcon, MagnifyingGlassIcon, TruckIcon, UserGroupIcon } from '@heroicons/vue/20/solid';
import { Popover, PopoverPanel } from '@headlessui/vue';
import { computed, ref } from 'vue';
import debounce from 'lodash/debounce';
import { onClickOutside } from '@vueuse/core';
import useFormatters from '@/composables/useFormatters';
import API from '@/api';
import { universalSearchResultInterface } from '@/api/universalSearch/search';
import useInertia from '@/composables/useInertia';
import { useNarrative } from '@/composables/useNarrative';
import { useBarcodeScannerStore } from '@/stores/barcodeScanner';
import BaseLink from '@/components/elements/BaseLink.vue';
import { AmazonIcon } from '@/helpers/icons';

const searchTerm = ref('');
const isSearching = ref(false);

document.addEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key === '/' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault();

        document.getElementById('universalSearch')?.focus();
    }

    if (event.key === 'ArrowDown' && document.activeElement?.id === 'universalSearch') {
        document.getElementById('universalSearchResult-0')?.focus();

        return;
    }

    if (event.key === 'ArrowDown' && document.activeElement?.id.includes('universalSearchResult')) {
        const nextElement = document.activeElement?.parentElement?.nextElementSibling?.firstElementChild;

        if (nextElement instanceof HTMLElement) {
            nextElement.focus();
        }
        return;
    }

    if (event.key === 'ArrowUp' && document.activeElement?.id.includes('universalSearchResult')) {
        const previousElement = document.activeElement?.parentElement?.previousElementSibling?.firstElementChild;

        if (previousElement instanceof HTMLElement) {
            previousElement.focus();
        }

        return;
    }

    if (event.key === 'Enter' && document.activeElement?.id.includes('universalSearchResult')) {
        if (document.activeElement instanceof HTMLAnchorElement) {
            useInertia().visit(document.activeElement.href);
            clear();
        }
    }
});

const { truncate, sharedUrl } = useFormatters();
const { userHasRoleOnCurrentServiceProvider, currentServiceProvider } = useNarrative();

const isMac = navigator.platform.toUpperCase().includes('MAC');
const isDesktop = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) > 1280;

const searchText = computed(() => {
    let text = 'Search';
    if (!isDesktop) {
        return text;
    }

    text += ` [${isMac ? '⌘' : 'Ctrl'}+/]`;

    return text;
});

const searchRequest = debounce(() => {
    search(searchTerm.value);
}, 300);

const searchResults = ref<universalSearchResultInterface[]>([]);

const search = async (searchQry?: string) => {
    isSearching.value = true;
    searchResults.value = await API.universalSearch(searchQry || '*');
    isSearching.value = false;
};

const target = ref(null);
onClickOutside(target, (event) => {
    clear();
});

const clear = () => {
    searchTerm.value = '';
    searchResults.value = [];
};

const merchantName = (merchantId?: number): string | undefined => {
    if (!merchantId) {
        return undefined;
    }

    if (!userHasRoleOnCurrentServiceProvider.value) {
        return undefined;
    }

    return currentServiceProvider.value.merchants.find(merchant => merchant.id === merchantId)?.name;
};

interface UniversalSearchResult {
    id: number
    type: 'item' | 'inbound_shipment' | 'outbound_shipment' | 'fba_transport_v2024_plan'
    name: string
    teamId?: number
    href: string
    icon: any
}

const results = computed((): UniversalSearchResult[] => {
    const mappedResults: UniversalSearchResult[] = [];
    for (let i = 0; i < searchResults.value.length; i++) {
        const result = searchResults.value[i];

        if (result.referenceId !== undefined) {
            // Inbound Shipment
            mappedResults.push({
                id: result.id,
                type: 'inbound_shipment',
                name: result.name || '',
                teamId: result.teamId,
                href: sharedUrl(`/shipments/inbound/${result.id}`),
                icon: InboxArrowDownIcon,
            });
        }

        if (result.isCaseForwarding !== undefined) {
            // Outbound Shipment
            mappedResults.push({
                id: result.id,
                type: 'outbound_shipment',
                name: result.name || '',
                teamId: result.teamId,
                href: sharedUrl(`/shipments/outbound/${result.id}`),
                icon: TruckIcon,
            });
        }

        if (result.merchantSku !== undefined) {
            // Item
            mappedResults.push({
                id: result.id,
                type: 'item',
                name: result.title || '',
                teamId: result.teamId,
                href: sharedUrl(`/inventory/${result.id}`),
                icon: ArchiveBoxIcon,
            });
        }

        if (result.inboundPlanId !== undefined) {
            // Fba Transport V2024 Plan
            mappedResults.push({
                id: result.id,
                type: 'fba_transport_v2024_plan',
                name: result.name || '',
                teamId: result.teamId,
                href: sharedUrl(`/fba-transport/v2024/plans/${result.id}`),
                icon: AmazonIcon,
            });
        }
    }

    const typeOrder = ['item', 'inbound_shipment', 'outbound_shipment', 'fba_transport_v0_plan'];

    return mappedResults.sort((a, b) => typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type));
});

const handleScan = (code: string) => {
    searchTerm.value = code;
    searchRequest();
};

const barcodeScannerStore = useBarcodeScannerStore();
barcodeScannerStore.$onAction(({ name, after }) => {
    after(() => {
        if (name === 'setHardwareScan') {
            handleScan(barcodeScannerStore.hardwareScan);
        }
    });
});
</script>
