<template>
    <div class="rounded-lg border border-gray-200">
        <div class="flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <slot name="headers" />
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="row in table.getCurrentData()" :key="table.getId(row)">
                                <slot name="cells" :row="row" />
                            </tr>
                        </tbody>
                    </table>
                    <div class="flex flex-col xl:flex-row items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 rounded-b-lg">
                        <div class="flex flex-row items-center mb-2">
                            <TablePageInformation
                                v-if="isSimplePaginationTabular(table)"
                                :data-count="table.totalDataCount || 0"
                                :from="table.pageStart"
                                :to="Math.min(table.pageEnd, table.totalDataCount || Number.POSITIVE_INFINITY)"
                            />
                            <a
                                v-if="table.search.length"
                                class="ml-2 text-primary-500 cursor-pointer italic text-sm whitespace-nowrap"
                                @click="$emit('clear-filters')"
                            >(Clear Filters)</a>
                            <LoadingIndicator v-if="isRemoteSimplePaginationTabular(table)" :is-loading="table.isLoading" :is-pending="table.isPending" />
                        </div>
                        <div class="flex flex-row justify-end px-4 sm:px-6 space-x-4">
                            <TablePageSizeSelect
                                v-if="isSimplePaginationTabular(table)"
                                :page-size="table.pageSize"
                                :available-page-sizes="availablePageSizes"
                                @change-page-size="handlePageSizeChange"
                            />

                            <TablePagination
                                v-if="isSimplePaginationTabular(table)"
                                :current-page-number="table.page"
                                :data-count="table.totalDataCount"
                                :page-size="table.pageSize"
                                @goto="table.goToPage($event)"
                                @previous="table.goToPage(table.page - 1)"
                                @next="table.goToPage(table.page + 1)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts" generic="TData extends {[key: string]: any}">
import LoadingIndicator from '../LoadingIndicator.vue';
import TablePageInformation from './TablePageInformation.vue';
import TablePageSizeSelect from './TablePageSizeSelect.vue';
import TablePagination from './TablePagination.vue';
import { Tabular, isRemoteSimplePaginationTabular, isSimplePaginationTabular } from '@/helpers/tables/types';

const { table, availablePageSizes = [5, 10, 20, 50, 100, 200] } = defineProps<{
    availablePageSizes?: number[]
    table: Tabular<TData>
}>();

const emit = defineEmits<{
    (e: 'clear-filters'): void
}>();

const handlePageSizeChange = (size: number) => {
    if (!isSimplePaginationTabular(table)) {
        return;
    }

    if (size === table.pageSize) {
        return;
    }

    table.setPageSize(size);
};
</script>
