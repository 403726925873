<template>
    <div
        v-touch:swipe.top="openDetails"
        v-touch:swipe.bottom="closeDetails"
        class="fixed bottom-0 w-full transition-height duration-300 ease-in-out z-20"
        :class="detailsHeight"
    >
        <div
            class="w-full bg-white rounded-t-lg p-4 transition-height duration-300 ease-in-out"
            :class="detailsHeight"
        >
            <div class="flex flex-col">
                <hr class="w-1/4 self-center border-t-2 border-gray-400 rounded-sm">
                <div v-if="showDetails" class="flex flex-row justify-end">
                    <span
                        class="text-gray-400"
                    >Pick {{ options.currentItem }} of {{ options.totalItems }}</span>
                </div>
                <div
                    v-if="showDetails && options.pick && options.pick.stocks.length && hasLocationsEntitlement"
                    class="flex flex-row"
                >
                    <span class="mr-2">Location:</span>
                    <base-badge size="sm">
                        {{ options.pick.stocks[0].location.code }}
                    </base-badge>
                </div>
                <div v-if="showDetails" class="bg-gray-50 px-1 py-2 rounded-lg">
                    <span>Item Details</span>
                    <div
                        v-if="options.pick && options.pick.item"
                        class="flex flex-row w-full justify-between"
                    >
                        <div class="flex flex-row">
                            <img
                                v-if="options.pick.item.images[0]?.thumbnailUrl"
                                :src="options.pick.item.images[0]?.thumbnailUrl || ''"
                                class="max-h-16 max-w-16 cursor-pointer rounded-md mr-1"
                                alt="Product Image"
                                @click="openLargeImage(options.pick.item.images[0]?.largeUrl || '')"
                            >

                            <div class="flex flex-col">
                                <span
                                    v-tooltip="options.pick.item.title"
                                    class="whitespace-nowrap text-xs"
                                >{{ truncate(options.pick.item.title, 30) }}</span>
                                <a
                                    class="-mb-1.5 -mt-2"
                                    :href="sharedUrl(`/inventory/${options.pick.item.id}`)"
                                    target="_blank"
                                >
                                    <LinkToItem
                                        :item="options.pick.item"
                                        text-class="text-gray-400 text-xs"
                                    />
                                </a>
                                <span
                                    v-for="identifier in options.pick.item.identifiers"
                                    :key="identifier.id"
                                    class="whitespace-nowrap text-xs text-gray-400 -mb-1"
                                >{{ identifier.identifierType }}: {{ identifier.identifier }}</span>
                            </div>
                        </div>
                        <div
                            v-if="options.pick"
                            class="flex flex-col self-center text-xs space-y-1"
                        >
                            <span>
                                Qty Required:
                                <base-badge size="sm">{{ pickRequiredQuantity(options.pick) }}</base-badge>
                            </span>
                            <span>
                                Qty Remaining:
                                <base-badge size="sm">{{ pickRemainingQuantity(options.pick) }}</base-badge>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { PropType, computed, ref } from 'vue';
import usePickOrders, { scannerOptions } from '@/composables/usePickOrders';
import useImages from '@/composables/useImages';
import useFormatters from '@/composables/useFormatters';
import LinkToItem from '@/components/inventory/LinkToItem.vue';
import useAppEntitlements from '@/composables/useAppEntitlements';

defineProps({
    options: {
        type: Object as PropType<scannerOptions>,
        default: null,
    },
});

const { openLargeImage } = useImages();
const { truncate, sharedUrl } = useFormatters();
const { hasLocationsEntitlement } = useAppEntitlements();
const { pickRemainingQuantity, pickRequiredQuantity } = usePickOrders();

const showDetails = ref(true);

const openDetails = () => {
    showDetails.value = true;
};

const closeDetails = () => {
    showDetails.value = false;
};

const detailsHeight = computed(() => {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    if (showDetails.value) {
        return iOSSafari ? 'h-52' : 'h-52';
    } else {
        return iOSSafari ? 'h-16' : 'h-8';
    }
});
</script>
