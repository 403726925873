<template>
    <div
        id="navStationStatuses"
        class="group bg-gray-800 overflow-hidden rounded-lg hidden lg:flex items-center text-gray-400 focus:outline-hidden focus:ring hover:bg-gray-400 hover:text-gray-800 cursor-pointer mx-1"
        @click="openStationAndPrinterManager"
    >
        <div class="px-2 py-2 flex items-center">
            <span class="relative inline-flex rounded-md shadow-xs mx-1">
                <ShippingLabelSolidIcon class="h-5 w-5" />
                <span
                    :class="classFromDeviceStatus(currentStationStatuses.shippingLabelPrinter)"
                    class="absolute top-0 right-0 block h-2 w-2 rounded-full ring-gray-900 group-hover:ring-gray-400 ring-2"
                />
            </span>
            <span class="relative inline-flex rounded-md shadow-xs mx-1">
                <BarcodeLabelSolidIcon class="h-5 w-5" />
                <span
                    :class="classFromDeviceStatus(currentStationStatuses.barcodeLabelPrinter)"
                    class="absolute top-0 right-0 block h-2 w-2 rounded-full ring-gray-900 group-hover:ring-gray-400 ring-2"
                />
            </span>
            <span class="relative inline-flex rounded-md shadow-xs mx-1">
                <ScaleIcon class="h-5 w-5 opacity-80" />
                <span
                    :class="classFromDeviceStatus(currentStationStatuses.scale)"
                    class="absolute top-0 right-0 block h-2 w-2 rounded-full ring-gray-900 group-hover:ring-gray-400 ring-2"
                />
            </span>
        </div>
    </div>

    <teleport to="body">
        <StationAndPrinterManager :open="showStationAndPrinterManager" @close="showStationAndPrinterManager = false" />
    </teleport>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { ScaleIcon } from '@heroicons/vue/20/solid';
import StationAndPrinterManager from '../overlays/stations/StationAndPrinterManager.vue';
import { BarcodeLabelSolidIcon, ShippingLabelSolidIcon } from '@/helpers/icons';
import { Spark } from '@/compiler/types';
import useStations from '@/composables/useStations';

const { currentStation, currentStationStatuses, loadCurrentStation, refreshDevices } = useStations();

onMounted(() => {
    const spark = (window as any).Spark as Spark;

    if (spark.pusherAppKey && !location.href.includes('staff/stations/')) {
        // Don't get stations in CI or on stations pages to avoid conflicts with device searches
        loadCurrentStation();
    }
});

const classFromDeviceStatus = (status: string) => {
    switch (status) {
        case 'pending':
            return 'bg-amber-400';
        case 'available':
            return 'bg-green-400';
        case 'offline':
            return 'bg-red-400';
        default:
            return 'bg-gray-400';
    }
};

const showStationAndPrinterManager = ref(false);
const openStationAndPrinterManager = () => {
    showStationAndPrinterManager.value = true;
};

const stationStatusesChecker = ref<NodeJS.Timeout | null>(null);
const checkStationStatuses = () => {
    if (stationStatusesChecker.value) {
        clearInterval(stationStatusesChecker.value);
    }
    if (!currentStation.value) return;
    stationStatusesChecker.value = setInterval(() => {
        if (document.visibilityState === 'hidden') return;

        refreshDevices();
    }, 30000);
};

watch(() => currentStation.value, (station) => {
    if (station) {
        checkStationStatuses();
    } else {
        if (stationStatusesChecker.value) {
            clearInterval(stationStatusesChecker.value);
        }
    }
});

onUnmounted(() => {
    if (stationStatusesChecker.value) {
        clearInterval(stationStatusesChecker.value);
    }
});
</script>
