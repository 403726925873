import { OutboundShipment, OutboundShipmentItem, Shipment } from '@conveyr/shared-types';
import { PaginatedRequestFunction, PaginatedResults, moneyInterface, requestFilterInterface } from '@/compiler/types';
import axios from '@/helpers/forms/axios';

export const assessCharges = async (shipmentId: number): Promise<moneyInterface> => {
    const response = await axios.get<{ expectedChargeTotal: moneyInterface }>(`/api/shipments/outbound/${shipmentId}/assessCharges`);

    return response.data.expectedChargeTotal;
};

const setShipFromAddress = async (shipmentId: number, addressId: number): Promise<void> => {
    return await axios.post(`/api/shipments/outbound/${shipmentId}/set-ship-from-address`, {
        shipFromAddressId: addressId,
    });
};

const loadArchivedShipments: PaginatedRequestFunction<OutboundShipment> = async (page: number) => {
    const response = await axios.get<PaginatedResults<OutboundShipment>>(`/api/shipments/outbound/archived?page=${page}`);

    return response.data;
};

const batchArchive = async (shipmentIds: number[]): Promise<void> => {
    return await axios.post('/api/shipments/outbound/batch/archive', {
        shipmentIds,
    });
};

const batchUnarchive = async (shipmentIds: number[]): Promise<void> => {
    return await axios.post('/api/shipments/outbound/batch/unarchive', {
        shipmentIds,
    });
};

const markAsShipped = async (shipmentId: number): Promise<void> => {
    return await axios.get(`/api/shipments/outbound/${shipmentId}/markShipped`);
};

const markAsDeleted = async (shipmentId: number): Promise<void> => {
    return await axios.get(`/api/shipments/outbound/${shipmentId}/markDeleted`);
};

const switchCaseForwarding = async (shipmentId: number): Promise<void> => {
    return await axios.get(`/api/shipments/outbound/${shipmentId}/switchCaseForwarding`);
};

const toggleLock = async (shipmentId: number): Promise<void> => {
    return await axios.get(`/api/shipments/outbound/${shipmentId}/toggleLock`);
};

const close = async (shipmentId: number): Promise<void> => {
    return await axios.post(`/api/shipments/outbound/${shipmentId}/close`);
};

const isOutboundShipment = (shipment: Shipment): shipment is OutboundShipment => {
    return (<OutboundShipment>shipment).isCasePacked !== undefined;
};

const fetchOutboundShipments: PaginatedRequestFunction<OutboundShipment> = async (page: number, filters?: requestFilterInterface[]) => {
    const url = new URL('/api/shipments/outbound', location.origin);
    url.searchParams.append('page', page.toString());
    if (filters) {
        filters.forEach((filter) => {
            url.searchParams.append(`filters[${filter.name}]`, filter.value);
        });
    }

    const response = await axios.get<PaginatedResults<OutboundShipment>>(url.toString());

    return response.data;
};

const fetchOutboundShipment = async (shipmentId: number): Promise<OutboundShipment> => {
    const response = await axios.get<{ shipment: OutboundShipment }>(`/api/shipments/outbound/${shipmentId}`);

    return response.data.shipment;
};

const createOutboundShipment = async (name: string, warehouseId: number, notes?: string): Promise<{ shipmentId: number }> => {
    const response = await axios.post<{ shipmentId: number }>('/api/shipments/outbound', {
        name,
        warehouseId,
        notes,
    });

    return response.data;
};

export interface UpdateShipmentRequestData {
    name?: string
    notes?: string
    internalNotes?: string
}

export type UpdateShipmentFn = (shipmentId: number, data: UpdateShipmentRequestData) => Promise<Shipment>;

const updateOutboundShipment: UpdateShipmentFn = async (shipmentId: number, data: UpdateShipmentRequestData): Promise<OutboundShipment> => {
    const response = await axios.put<{ shipment: OutboundShipment }>(`/api/shipments/outbound/${shipmentId}`, data);

    return response.data.shipment;
};

const removeTagFromOutboundShipment = async (shipmentId: number, tagId: number): Promise<void> => {
    return await axios.delete(`/api/shipments/outbound/${shipmentId}/tags/delete-tag/${tagId}`);
};

const addTagToOutboundShipment = async (shipmentId: number, tagId: number): Promise<void> => {
    return await axios.post(`/api/shipments/outbound/${shipmentId}/tags/add-tag/${tagId}`);
};

// Items

const fetchOutboundShipmentItems = async (shipmentId: number): Promise<OutboundShipmentItem[]> => {
    const response = await axios.get<{ items: OutboundShipmentItem[] }>(`/api/shipments/outbound/${shipmentId}/outbound-shipment-item`);

    return response.data.items;
};

const createOutboundShipmentItem = async (shipmentId: number, itemId: number, quantity: number, expiryDate?: string): Promise<void> => {
    return await axios.post(`/api/shipments/outbound/${shipmentId}/outbound-shipment-item`, { itemId, quantity, expiryDate });
};

const updateOutboundShipmentItem = async (item: OutboundShipmentItem): Promise<void> => {
    return await axios.patch(`/api/shipments/outbound/${item.shipmentId}/outbound-shipment-item/${item.id}`, item);
};

const deleteOutboundShipmentItem = async (item: OutboundShipmentItem, force: boolean): Promise<void> => {
    const url = new URL(`/api/shipments/outbound/${item.shipmentId}/outbound-shipment-item/${item.id}`, location.origin);
    if (force) {
        url.searchParams.append('force', 'true');
    }

    return await axios.delete(url.toString());
};

export default {
    assessCharges,
    setShipFromAddress,
    loadArchivedShipments,
    batchArchive,
    batchUnarchive,
    markAsShipped,
    markAsDeleted,
    switchCaseForwarding,
    toggleLock,
    close,

    isOutboundShipment,
    fetchOutboundShipments,
    fetchOutboundShipment,
    createOutboundShipment,
    updateOutboundShipment,
    fetchOutboundShipmentItems,
    createOutboundShipmentItem,
    updateOutboundShipmentItem,
    deleteOutboundShipmentItem,
    removeTagFromOutboundShipment,
    addTagToOutboundShipment,
};
