<template>
    <!-- Station Switcher Modal -->
    <base-slideover
        :open="open"
        :close-func="() => $emit('close')"
        title="Station and Print Manager"
        size="3xl"
    >
        <BaseTabs class="-mt-4" :tabs="tabs">
            <template #stations>
                <StationsTab :is-open="isOpen" />
            </template>
            <template #print_queue>
                <PrintQueueTab :open="isOpen" />
            </template>
        </BaseTabs>
    </base-slideover>
</template>

<script lang="ts" setup>
import { nextTick, ref, watch } from 'vue';
import StationsTab from './StationsTab.vue';
import PrintQueueTab from './PrintQueueTab.vue';
import BaseTabs from '@/components/elements/BaseTabs.vue';
import { Tab } from '@/composables/useTabs';

const props = defineProps<{
    open: boolean
}>();

defineEmits<{
    (e: 'close'): void
}>();

const tabs: Tab[] = [
    {
        name: 'stations',
        active: true,
    },
    {
        name: 'print_queue',
        active: false,
    },
];

const isOpen = ref(false);

watch(() => props.open, () => {
    // For some reason, passing props.open directly to the tab component breaks the watcher
    // so we update a ref on the next tick to trigger the watcher inside the component
    nextTick(() => {
        isOpen.value = props.open;
    });
});
</script>
