import { defineStore } from 'pinia';
import { Ref } from 'vue';
import dayjs from 'dayjs';
import { OperationProblem } from '@conveyr/shared-types';
import { DataFetcherFunction } from '@/compiler/types';
import useRelativeTimeCheck from '@/composables/useRelativeTimeCheck';

export enum OperationStatus {
    InProgress = 'in_progress',
    Succeeded = 'succeeded',
    Failed = 'failed',
}

export interface Operation {
    getId(): string
    getCreatedAt(): string
    getStatus(): OperationStatus
    getProgress(): number
    getDescription(): string
    getWarnings(): OperationProblem[]
    getErrors(): OperationProblem[]
    update(data: OperationWebsocketEventData): void
}

export interface PollableOperation extends Operation {
    updateIntervalSeconds(): number
    fetchUpdate(): Promise<any>
}

export interface DescribableProgressOperation extends Operation {
    getProgressDescription(): string | null
}

export function isPollableOperation(operation: Operation): operation is PollableOperation {
    return 'updateIntervalSeconds' in operation && 'fetchUpdate' in operation;
}

export function isDescribableProgressOperation(operation: Operation): operation is DescribableProgressOperation {
    return 'getProgressDescription' in operation;
}

export interface ActionableOnSuccessOperation extends Operation {
    executeAction(): Promise<void>
    actionText(): string
    actionIcon(): Function
}

export interface DisplayableInFooterOperation extends Operation {
    displayInFooter(): boolean
}

export function isDisplayableInFooterOperation(operation: Operation): operation is DisplayableInFooterOperation {
    return 'displayInFooter' in operation;
}

export interface OperationCallbacks {
    onSuccess?: () => void
    onFailure?: () => void
}

export function isActionableOperation(operation: Operation): operation is ActionableOnSuccessOperation {
    return (operation as ActionableOnSuccessOperation).actionIcon !== undefined;
};

export interface OperationCheck {
    lastCheckedAt: string
    relativeLastCheck: Ref<string | null> | string | null
    checking: boolean
    stopChecking: () => void
    check: () => void
}

export interface OperationWebsocketEventData {
    id: string
    progress: number
    progressDescription?: string | null
    status: OperationStatus
    problems: OperationProblem[] | null
}

interface State {
    _operations: Operation[]
    operationSources: { [key: string]: DataFetcherFunction<Operation> | (() => Promise<Operation[]>) }
    operationChecks: { [key: string]: OperationCheck }
    highlightOperationId: string | null
}

export const useOperationsStore = defineStore('operations', {
    state: (): State => ({
        _operations: [],
        operationSources: {},
        operationChecks: {},
        highlightOperationId: null,
    }),
    getters: {
        operations(): Operation[] {
            return this._operations.sort((a, b) => b.getCreatedAt().localeCompare(a.getCreatedAt()));
        },
        activeOperationsCount(): number {
            return this._operations.filter(operation => operation.getStatus() === OperationStatus.InProgress).length;
        },
    },
    actions: {
        set(operation: Operation): void {
            const existingOperationIndex = this._operations.findIndex(existingOperation => existingOperation.getId() === operation.getId());

            if (existingOperationIndex >= 0) {
                this._operations[existingOperationIndex] = operation;
            } else {
                this._operations.unshift(operation);

                if (isPollableOperation(operation)) {
                    this.operationChecks[operation.getId()] = initOperationCheck(operation);
                }
            }
        },
        addOperationSource(key: string, source: DataFetcherFunction<Operation> | (() => Promise<Operation[]>)): void {
            this.operationSources[key] = source;
        },
        setHighlightOperationId(id: string | null): void {
            this.highlightOperationId = id;

            setTimeout(() => {
                this.highlightOperationId = null;
            }, 1000);
        },
        async loadOperationsFromSources(): Promise<void> {
            const promises: Promise<any>[] = [];

            Object.keys(this.operationSources).forEach((key) => {
                promises.push(this.operationSources[key](1, 10, [], '').then((response) => {
                    if ('data' in response) {
                        response.data.forEach(operation => this.set(operation));
                    } else {
                        response.forEach(operation => this.set(operation));
                    }
                }));
            });

            await Promise.all(promises);
        },
        updateOperationFromWebsocket(data: OperationWebsocketEventData): void {
            const operation = this.operations.find(op => op.getId() === data.id);
            if (!operation) return;

            operation.update(data);
        },
        recordOperationCheck(operationId: string): void {
            if (!this.operationChecks[operationId]) return;

            this.operationChecks[operationId].lastCheckedAt = dayjs().toISOString();
        },
    },
});

const initOperationCheck = (operation: PollableOperation): OperationCheck => {
    const operationsStore = useOperationsStore();

    const callback = () => {
        const operations = operationsStore.operations;

        if (!operations) return;

        if (useOperationsStore().operationChecks[operation.getId()].checking) return;

        const currentOperation = operations.find(op => op.getId() === operation.getId());
        if (!currentOperation || currentOperation.getStatus() !== OperationStatus.InProgress) {
            stopChecking();

            return;
        };

        useOperationsStore().operationChecks[operation.getId()].checking = true;

        operation.fetchUpdate().then(() => {
            const updatedOperation = operations.find(op => op.getId() === operation.getId());
            if (!updatedOperation || updatedOperation.getStatus() !== OperationStatus.InProgress) {
                stopChecking();
            };

            useOperationsStore().operationChecks[operation.getId()].lastCheckedAt = dayjs().toISOString();
        }).finally(() => {
            useOperationsStore().operationChecks[operation.getId()].checking = false;
        });
    };

    const { relativeLastCheck, stopChecking } = useRelativeTimeCheck(() => operationsStore.operationChecks[operation.getId()].lastCheckedAt, {
        seconds: operation.updateIntervalSeconds(),
        callback,
    });

    return {
        lastCheckedAt: dayjs().toISOString(),
        relativeLastCheck,
        checking: false,
        stopChecking,
        check: callback,
    };
};
