import { ServiceProviderWebsocketEvent, ServiceProviderWebsocketEventType, WebsocketEvent, WorkflowStatus, WorkflowUpdateWebsocketEvent } from '@conveyr/shared-types';
import { WebsocketSubscriber } from '@/composables/websockets/websocketListener';
import { OperationStatus, useOperationsStore } from '@/stores/operations/operations';

const isServiceProviderEvent = (event: WebsocketEvent): event is ServiceProviderWebsocketEvent => {
    return Object.values(ServiceProviderWebsocketEventType).includes(event.event as ServiceProviderWebsocketEventType);
};

const isWorkflowUpdateEvent = (event: WebsocketEvent): event is WorkflowUpdateWebsocketEvent => {
    return event.event === ServiceProviderWebsocketEventType.WorkflowUpdate;
};

export default function useServiceProviderWebsocketListener() {
    const defaultWebsocketSubscriber: WebsocketSubscriber = {
        id: 'default',
        callback: (event: WebsocketEvent) => {
            if (!isServiceProviderEvent(event)) return;

            if (isWorkflowUpdateEvent(event)) {
                handleWorkflowUpdate(event);
            }
        },
    };

    const handleWorkflowUpdate = (event: WorkflowUpdateWebsocketEvent) => {
        useOperationsStore().updateOperationFromWebsocket({
            id: event.data.workflowId,
            progress: event.data.progress,
            progressDescription: event.data.progressDescription,
            status: mapWorkflowToOperationStatus(event.data.status),
            problems: event.data.problems,
        });

        useOperationsStore().recordOperationCheck(event.data.workflowId);
    };

    const mapWorkflowToOperationStatus = (status: WorkflowStatus): OperationStatus => {
        switch (status) {
            case WorkflowStatus.Running:
                return OperationStatus.InProgress;
            case WorkflowStatus.Completed:
                return OperationStatus.Succeeded;
            case WorkflowStatus.Failed:
            case WorkflowStatus.Canceled:
            case WorkflowStatus.Terminated:
            case WorkflowStatus.TimedOut:
                return OperationStatus.Failed;
            case WorkflowStatus.ContinuedAsNew:
                return OperationStatus.InProgress;
            case WorkflowStatus.Unspecified:
            default:
                return OperationStatus.InProgress;
        }
    };

    return {
        defaultWebsocketSubscriber,
    };
}
