import { Item, LengthAwarePage } from '@conveyr/shared-types';
import axios from '@/helpers/forms/axios';

const fetchReconcileItems = (skus: Array<string> = [], pageNumber: number = 1) => axios
    .get<LengthAwarePage<Item>>(skus.reduce((url, sku) => `${url}skus[]=${sku}&`, `/api/inventory/reconcile?page=${pageNumber}&`))
    .then(response => response.data);

export default {
    fetchReconcileItems,
};
