import fulfillment from './fulfillment';
import items from './items';
import itemGroupConfigurations from './itemGroupConfigurations';
import merchants from './merchants';
import orders from './orders';
import orderItems from './orderItems';
import packages from './packages';
import packageConfigurations from './packageConfigurations';
import serviceProviders from './serviceProviders';
import tags from './tags';
import trackingNumbers from './trackingNumbers';
import users from './users';
import webhooks from './webhooks';

export default {
    fulfillment,
    items,
    itemGroupConfigurations,
    merchants,
    orders,
    orderItems,
    packages,
    packageConfigurations,
    serviceProviders,
    tags,
    trackingNumbers,
    users,
    webhooks,
};
