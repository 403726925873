<template>
    <a
        v-tooltip="item.merchantSku.length > 20 ? item.merchantSku : ''"
        :href="viewItemUrl()"
        class="whitespace-nowrap"
        target="_blank"
    >
        <span :class="textClass">{{ truncate(item.merchantSku, 20) }}</span>
        <span class="text-primary-400 -ml-0.5">
            <ChevronRightIcon class="h-4 w-4 inline" />
        </span>
    </a>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import { ChevronRightIcon } from '@heroicons/vue/20/solid';
import { Item } from '@conveyr/shared-types';
import useFormatters from '@/composables/useFormatters';

const props = defineProps({
    item: {
        type: Object as PropType<Item>,
        default: null,
    },
    textClass: {
        type: String,
        default: null,
    },
});

const { truncate, sharedUrl } = useFormatters();

const viewItemUrl = () => {
    return sharedUrl(`/inventory/${props.item.id}`);
};
</script>
