import { defineStore } from 'pinia';
import { merchantInterface } from '@/api/repositories/merchants';
import { Spark } from '@/compiler/types';
import API from '@/api';

interface State extends Spark {
    selectedMerchantLocked: boolean
}

const selectedMerchantStorageKey = 'selectedMerchantId';

const getSelectedMerchantIdFromStorage = (): number | null => {
    const sessionStorageSelectedMerchantId = sessionStorage.getItem(selectedMerchantStorageKey);
    if (sessionStorageSelectedMerchantId) {
        return Number.parseInt(sessionStorageSelectedMerchantId);
    }

    const localStorageSelectedMerchantId = localStorage.getItem(selectedMerchantStorageKey);
    if (localStorageSelectedMerchantId) {
        return Number.parseInt(localStorageSelectedMerchantId);
    }

    return null;
};

export const useNarrativeStore = defineStore('narrative', {
    state: (): State => ({
        env: 'production',
        appName: 'Portal',
        appBaseDomain: '',
        stripeKey: '',
        serviceProvider: undefined,
        merchant: undefined,
        selectedMerchantLocked: false,
        impersonatingUserId: null,
        sentryPublicDsn: undefined,
        releaseTag: undefined,
        analyticsKeys: {
            posthog: {
                apiKey: '',
                apiHost: '',
            },
        },
        chargebeeSite: '',
        featurebaseUserIdHash: null,
        featurebaseServiceProviderIdHash: null,
        featurebaseOrganization: null,
    }),
    getters: {
        currentServiceProvider: state => state.serviceProvider,
        currentMerchant: state => state.merchant,
        currentAppName: state => state.appName,
        currentAppBaseDomain: state => state.appBaseDomain,
        getSelectedMerchantLocked: state => state.selectedMerchantLocked,
    },
    actions: {
        setCurrentMerchant(merchant: merchantInterface): void {
            if (this.selectedMerchantLocked) {
                return;
            }

            sessionStorage.setItem(selectedMerchantStorageKey, merchant.id.toString());
            localStorage.setItem(selectedMerchantStorageKey, merchant.id.toString());

            this.merchant = merchant;
        },
        clearCurrentMerchant(): void {
            sessionStorage.removeItem(selectedMerchantStorageKey);
            localStorage.removeItem(selectedMerchantStorageKey);

            this.merchant = undefined;
        },
        addClientMerchant(value: merchantInterface): void {
            if (!this.serviceProvider?.merchants) return;
            this.serviceProvider.merchants.push(value);
        },
        removeClientMerchant(value: merchantInterface): void {
            if (!this.serviceProvider?.merchants) return;
            const findIndex = this.serviceProvider.merchants.findIndex(findMerchant => findMerchant.id === value.id);

            this.serviceProvider.merchants.splice(findIndex, 1);
        },
        setServiceProviderLogo(value: string): void {
            if (!this.serviceProvider) return;
            this.serviceProvider.logoFile = value;
        },
        setServiceProviderLogoBackground(value: string): void {
            if (!this.serviceProvider) return;
            this.serviceProvider.logoBackground = value;
        },
        setImpersonatedUserId(value: number): void {
            this.impersonatingUserId = value;
        },
        clearImpersonatedUserId(): void {
            this.impersonatingUserId = null;
        },
        toggleSelectedMerchantLocked(): void {
            this.selectedMerchantLocked = !this.selectedMerchantLocked;
        },
        async refreshServiceProvider(): Promise<void> {
            this.serviceProvider = await API.repositories.serviceProviders.fetchCurrentServiceProvider();
        },
        async refreshMerchant(): Promise<void> {
            await this.refreshServiceProvider();

            this.merchant = this.serviceProvider?.merchants?.find(merchant => merchant.id === this.merchant?.id);
        },
        async loadNarrative(): Promise<void> {
            if (this.serviceProvider) {
                return;
            }

            const spark = (window as any).Spark as Spark;
            this.env = spark.env;
            this.appName = spark.appName;
            this.appBaseDomain = spark.appBaseDomain;
            this.stripeKey = spark.stripeKey;
            this.serviceProvider = spark.serviceProvider;
            this.merchant = spark.merchant;
            this.impersonatingUserId = spark.impersonatingUserId;
            this.sentryPublicDsn = spark.sentryPublicDsn;
            this.releaseTag = spark.releaseTag;
            this.analyticsKeys = spark.analyticsKeys;
            this.chargebeeSite = spark.chargebeeSite;
            this.featurebaseUserIdHash = spark.featurebaseUserIdHash;
            this.featurebaseServiceProviderIdHash = spark.featurebaseServiceProviderIdHash;
            this.featurebaseOrganization = spark.featurebaseOrganization;

            if (!this.merchant) {
                const storedSelectedMerchantId = getSelectedMerchantIdFromStorage();

                if (!storedSelectedMerchantId) {
                    return;
                }

                if (!this.serviceProvider?.merchants?.find(merchant => merchant.id === storedSelectedMerchantId)) {
                    this.clearCurrentMerchant();

                    return;
                }

                this.merchant = this.serviceProvider.merchants?.find(merchant => merchant.id === storedSelectedMerchantId);
            }
        },
    },
});
