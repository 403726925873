export enum WorkflowStatus {
    Unspecified = 0,
    Running = 1,
    Completed = 2,
    Failed = 3,
    Canceled = 4,
    Terminated = 5,
    ContinuedAsNew = 6,
    TimedOut = 7,
}

export enum FbaTransportWorkflowType {
    CopyBoxGroupsFromPlanToPlan = 'fbaTransport_copyBoxGroupsFromPlanToPlan',
    Autoplanner = 'fbaTransport_autoplanner',
    GenerateTransportation = 'fbaTransport_generateTransportation',
    SplitPackingGroupsIntoPlans = 'fbaTransport_splitPackingGroupsIntoPlans',
}

export const WorkflowType = {
    FbaTransport: FbaTransportWorkflowType,
};

export interface WorkflowParamsMap {
    [FbaTransportWorkflowType.CopyBoxGroupsFromPlanToPlan]: {
        sourcePlanId: string
        destinationPlanId: string
    }
    [FbaTransportWorkflowType.Autoplanner]: {
        planId: string
    }
    [FbaTransportWorkflowType.GenerateTransportation]: {
        planId: string
        placementOptionId: string
        shipmentIds: string[]
    }
    [FbaTransportWorkflowType.SplitPackingGroupsIntoPlans]: {
        planId: string
        packingOptionId: string
    }
};

export interface ApiTokenParam {
    apiToken: string
}

export type WorkflowFunction<T extends keyof WorkflowParamsMap> = (
    params: WorkflowParamsMap[T] & ApiTokenParam,
) => Promise<void>;
