<template>
    <button
        class="border-b-[2.5px] leading-none inline-block cursor-pointer"
        :class="`${textSize} ${colourClasses}`"
        @click="click"
    >
        <div class="flex flex-row gap-1 items-end">
            <div v-if="!busy && icon">
                <component
                    :is="icon"
                    v-if="icon && !clicked"
                    :class="iconSize"
                />
                <Transition
                    enter-active-class="origin-right transition-transform ease-in-out duration-200"
                    enter-from-class="scale-y-0"
                    enter-to-class="scale-y-100"
                    leave-active-class="hidden"
                >
                    <CheckIcon
                        v-if="clicked"
                        :class="iconSize"
                        class="text-green-500"
                    />
                </Transition>
            </div>

            <ArrowPathIcon
                v-if="busy"
                :class="iconSize"
                class="animate-spin"
            />

            <slot />
        </div>
    </button>
</template>

<script lang="ts" setup>
import { BaseColour } from '@conveyr/shared-types';
import { ArrowPathIcon, CheckIcon } from '@heroicons/vue/20/solid';
import { computed, ref } from 'vue';

const { size = 'md', busy = false, icon, colour = BaseColour.Primary } = defineProps<{
    icon?: Function
    busy?: boolean
    size?: 'xs' | 'sm'
    colour?: BaseColour
}>();

const colourClasses = computed(() => {
    const colourClasses: { [key in BaseColour]: string } = {
        primary: 'border-primary-500 hover:border-primary-700 text-primary-700',
        amber: 'border-amber-500 hover:border-amber-700 text-amber-700',
        red: 'border-red-500 hover:border-red-700 text-red-700',
        gray: 'border-gray-500 hover:border-gray-700 text-gray-700',
        teal: 'border-teal-500 hover:border-teal-700 text-teal-700',
        green: 'border-green-500 hover:border-green-700 text-green-700',
        blue: 'border-blue-500 hover:border-blue-700 text-blue-700',
        indigo: 'border-indigo-500 hover:border-indigo-700 text-indigo-700',
    };

    return colourClasses[colour];
});

const iconSize = computed(() => {
    if (size === 'xs') {
        return 'size-3';
    }

    return 'size-4';
});

const textSize = computed(() => {
    if (size === 'xs') {
        return 'text-xs';
    }

    return 'text-sm';
});

const clicked = ref(false);
const click = () => {
    clicked.value = true;

    setTimeout(() => {
        clicked.value = false;
    }, 2000);
};
</script>
