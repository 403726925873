<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" static :class="dialogClasses" :open="dialogOpen" @close="close">
            <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div
                    class="fixed inset-0 bg-gray-500/50 transition-opacity slideoverBackgroundOverlay"
                    aria-hidden="true"
                    @click.prevent="close"
                />
            </TransitionChild>
            <div :id="id" class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <TransitionChild
                            as="template"
                            enter="transform transition ease-in-out duration-300"
                            enter-from="translate-x-full"
                            enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-300"
                            leave-from="translate-x-0"
                            leave-to="translate-x-full"
                        >
                            <DialogPanel class="pointer-events-auto" :class="rootDivClasses">
                                <div
                                    class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
                                >
                                    <div
                                        class="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll"
                                    >
                                        <div class="px-4 sm:px-6">
                                            <div class="flex items-start justify-between">
                                                <DialogTitle
                                                    v-if="title"
                                                    class="text-lg font-medium text-gray-900"
                                                >
                                                    {{ title }}
                                                </DialogTitle>
                                                <div class="ml-3 h-7 flex items-center">
                                                    <button />
                                                    <!-- To avoid autofocusing the x button -->
                                                    <button
                                                        class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-hidden focus:ring-2 focus:ring-primary-500 slideoverCloseButton"
                                                        @click="close"
                                                    >
                                                        <span class="sr-only">Close panel</span>
                                                        <XMarkIcon
                                                            class="h-6 w-6"
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-6 relative flex-1 px-4 sm:px-6 @container">
                                            <slot />
                                        </div>
                                    </div>
                                    <div
                                        v-if="$slots.footer"
                                        class="shrink-0 px-4 py-4 flex justify-end"
                                    >
                                        <slot name="footer" />
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref, watch } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
    open: {
        type: Boolean,
        default: false,
    },
    closeFunc: {
        type: Function,
        default: null,
    },
    id: {
        type: String,
        default: null,
    },
    title: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        validator: (prop: string) => ['3xl', '2xl', 'xl', 'lg', 'md', 'sm', 'full'].includes(prop),
        default: 'md',
    },
    zindex: {
        type: String,
        validator: (prop: string) => ['10', '20', '30'].includes(prop),
        default: '10',
    },
});

const close = () => {
    props.closeFunc();
};
const dialogClasses = computed(() => {
    let classes = 'fixed inset-0 overflow-hidden';

    const zClasses: { [key: string]: string } = {
        10: 'z-10',
        20: 'z-20',
        30: 'z-30',
    };

    classes += ` ${zClasses[props.zindex]}`;

    return classes;
});
const rootDivClasses = computed(() => {
    let classes = 'w-screen ';

    const widthClasses: { [key: string]: string } = {
        '3xl': 'max-w-3xl',
        '2xl': 'max-w-2xl',
        'xl': 'max-w-xl',
        'lg': 'max-w-lg',
        'md': 'max-w-md',
        'sm': 'max-w-sm',
    };

    classes += ` ${widthClasses[props.size]}`;

    return classes;
});

// smooth open
const dialogOpen = ref(props.open);
watch(() => props.open, () => nextTick(() => dialogOpen.value = props.open));
</script>
