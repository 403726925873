interface IdentifyParams {
    organization: string
    name: string
    email: string
    id: string
    userHash: string
    profilePicture: string | null
    companies: {
        name: string
        id: string
        companyHash: string
    }[]
}

export type Featurebase = (method: 'identify', params: IdentifyParams) => void;

export default function useFeaturebase() {
    const loadFeaturebase = async (): Promise<Featurebase> => {
        if ((window as any).Featurebase) return Promise.resolve((window as any).Featurebase);

        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = 'https://do.featurebase.app/js/sdk.js';
            script.id = 'featurebase-sdk';

            script.onload = () => {
                if (typeof (window as any).Featurebase !== 'function') {
                    (window as any).Featurebase = function (...args: any) {
                        ((window as any).Featurebase.q = (window as any).Featurebase.q || []).push(args);
                    };
                }

                const featurebase = (window as any).Featurebase;

                resolve(featurebase);
            };

            script.onerror = error => reject(error);
            document.head.appendChild(script);
        });
    };

    return {
        loadFeaturebase,
    };
}
