<template>
    <div class="px-2 sm:px-6">
        <div
            v-if="currentStation"
            class="mt-2"
        >
            <div class="flex flex-row justify-between mb-1">
                <span class="font-medium">Station: {{ currentStation.name }}</span>
                <base-badge size="sm">
                    <base-spinner v-if="isCheckingDevices" class="h-4 mr-1" />
                    Devices Checked: {{ relativeLastDeviceCheck }}
                </base-badge>
            </div>

            <base-table>
                <template #headers>
                    <base-table-header padding="narrow" />
                    <base-table-header padding="narrow">
                        Type
                    </base-table-header>
                    <base-table-header padding="narrow">
                        Device
                    </base-table-header>
                    <base-table-header>Station</base-table-header>
                </template>

                <tr>
                    <base-table-cell padding="narrow">
                        <span class="relative inline-flex rounded-md shadow-xs">
                            <ShippingLabelSolidIcon class="h-5 w-5 mr-1" />

                            <span
                                :class="classFromDeviceStatus(currentStationStatuses.shippingLabelPrinter)"
                                class="absolute top-0 right-0 -translate-y-1/2 translate-x-1/4 block h-2 w-2 rounded-full ring-white ring-2"
                            />
                        </span>
                    </base-table-cell>
                    <base-table-cell padding="narrow">
                        Shipping Label Printer
                    </base-table-cell>

                    <base-table-cell padding="narrow">
                        {{
                            slugToTitleCase(currentStation.shippingLabelPrinter || 'Download to PDF')
                        }}
                    </base-table-cell>

                    <base-table-cell>{{ slugToTitleCase(currentStationStatuses.shippingLabelPrinter) }}</base-table-cell>
                </tr>

                <tr class="bg-white">
                    <base-table-cell padding="narrow">
                        <span class="relative inline-flex rounded-md shadow-xs">
                            <BarcodeLabelSolidIcon class="h-5 w-5 mr-1" />

                            <span
                                :class="classFromDeviceStatus(currentStationStatuses.barcodeLabelPrinter)"
                                class="absolute top-0 right-0 block h-2 w-2 rounded-full ring-white ring-2"
                            />
                        </span>
                    </base-table-cell>
                    <base-table-cell padding="narrow">
                        Barcode Label Printer
                    </base-table-cell>

                    <base-table-cell padding="narrow">
                        {{
                            slugToTitleCase(currentStation.barcodeLabelPrinter || 'Download to PDF')
                        }}
                    </base-table-cell>

                    <base-table-cell>{{ slugToTitleCase(currentStationStatuses.barcodeLabelPrinter) }}</base-table-cell>
                </tr>
                <tr class="bg-white">
                    <base-table-cell padding="narrow">
                        <span class="relative inline-flex rounded-md shadow-xs">
                            <ScaleIcon class="h-5 w-5 mr-1" />

                            <span
                                :class="classFromDeviceStatus(currentStationStatuses.scale)"
                                class="absolute top-0 right-0 block h-2 w-2 rounded-full ring-white ring-2"
                            />
                        </span>
                    </base-table-cell>
                    <base-table-cell padding="narrow">
                        Scale
                    </base-table-cell>
                    <base-table-cell padding="narrow">
                        {{
                            slugToTitleCase(currentStation.scale || '')
                        }}
                    </base-table-cell>
                    <base-table-cell>{{ slugToTitleCase(currentStationStatuses.scale) }}</base-table-cell>
                </tr>
            </base-table>
        </div>

        <base-select
            v-if="filteredStations.length"
            id="stationSelect"
            class="mt-2"
            label="Select New Station"
            :form="form"
            form-prop="stationId"
            :options="selectOptions"
        />

        <base-alert v-if="!filteredStations.length" class="my-2">
            <span v-if="!currentStation">No stations are available. </span>
            <span v-if="currentStation">No stations are available to switch to. </span>
            <a
                href="/staff/stations/create"
                class="font-semibold"
            >Create a Station</a>
        </base-alert>

        <div
            v-if="selectedStation"
            class="mt-2"
        >
            <span
                id="selectedStationName"
                class="font-medium"
            >{{ selectedStation.name }}</span>
            <span v-if="selectedStation.user">(Assigned To: {{ selectedStation.user.name }})</span>

            <base-table>
                <template #headers>
                    <base-table-header padding="narrow" />
                    <base-table-header padding="narrow">
                        Type
                    </base-table-header>
                    <base-table-header padding="narrow">
                        Device
                    </base-table-header>
                </template>

                <tr class="bg-white">
                    <base-table-cell padding="narrow">
                        <ShippingLabelSolidIcon class="h-5 w-5 mr-2" />
                    </base-table-cell>
                    <base-table-cell padding="narrow">
                        Shipping Label Printer
                    </base-table-cell>
                    <base-table-cell padding="narrow">
                        {{ slugToTitleCase(selectedStation.shippingLabelPrinter || 'Download to PDF') }}
                    </base-table-cell>
                </tr>
                <tr class="bg-white">
                    <base-table-cell padding="narrow">
                        <BarcodeLabelSolidIcon class="h-5 w-5 mr-2" />
                    </base-table-cell>
                    <base-table-cell padding="narrow">
                        Barcode Label Printer
                    </base-table-cell>
                    <base-table-cell padding="narrow">
                        {{ slugToTitleCase(selectedStation.barcodeLabelPrinter || 'Download to PDF') }}
                    </base-table-cell>
                </tr>
                <tr class="bg-white">
                    <base-table-cell padding="narrow">
                        <ScaleIcon class="h-5 w-5 mr-2" />
                    </base-table-cell>
                    <base-table-cell padding="narrow">
                        Scale
                    </base-table-cell>
                    <base-table-cell padding="narrow">
                        {{
                            slugToTitleCase(selectedStation.scale || '')
                        }}
                    </base-table-cell>
                </tr>
            </base-table>
        </div>

        <base-alert
            v-if="selectedStation && selectedStation.userId"
            :colour="BaseColour.Red"
            class="mt-2"
        >
            Switching to this station will remove the station from the current user.
        </base-alert>

        <div class="mt-2 float-right">
            <base-button
                v-if="selectedStation"
                :icon="CheckCircleIcon"
                text="Switch"
                :click-func="switchStation"
                :busy="form.busy"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onUnmounted, reactive, watch } from 'vue';
import { CheckCircleIcon, ScaleIcon } from '@heroicons/vue/20/solid';
import { BaseColour } from '@conveyr/shared-types';
import useStations from '@/composables/useStations';
import { SparkForm, sendForm } from '@/helpers/forms/form';
import { stationInterface } from '@/api/repositories/stations';
import { BarcodeLabelSolidIcon, ShippingLabelSolidIcon } from '@/helpers/icons';
import useFormatters from '@/composables/useFormatters';
import { SelectOption } from '@/compiler/types';
import { useStationsStore } from '@/stores/stations';
import useRelativeTimeCheck from '@/composables/useRelativeTimeCheck';

const props = defineProps<{
    isOpen: boolean
}>();

const stationsStore = useStationsStore();
const { slugToTitleCase } = useFormatters();
const { allStations, getAllStations, currentStation, currentStationStatuses, refreshDevices, isCheckingDevices, lastDeviceCheck } = useStations();

const selectOptions = computed<SelectOption[]>(() => {
    const options = filteredStations.value.map(station => ({ value: station.id, name: stationOptionText(station) }));

    options.unshift({ value: 0, name: 'Select a Station' });

    return options;
});

const classFromDeviceStatus = (status: string) => {
    switch (status) {
        case 'pending':
            return 'bg-amber-400';
        case 'available':
            return 'bg-green-400';
        case 'offline':
            return 'bg-red-400';
        default:
            return 'bg-gray-400';
    }
};

const form = reactive(new SparkForm({
    stationId: 0,
}));

const filteredStations = computed(() => {
    return allStations.value.filter(station => station.id !== currentStation.value?.id);
});
const selectedStation = computed(() => {
    return allStations.value.find(station => station.id === form.stationId);
});

const stationOptionText = (station: stationInterface) => {
    let text = station.name;
    if (station.userId && station.user) {
        text += ` (${station.user.name})`;
    }

    return text;
};

const switchStation = async () => {
    sendForm({ method: 'post', uri: '/api/staff/stations/switch', form })
        .then(() => {
            stationsStore.loadCurrentStation();
            form.stationId = 0;

            close();
        });
};

const { relativeLastCheck: relativeLastDeviceCheck, startChecking, stopChecking } = useRelativeTimeCheck(() => lastDeviceCheck.value);

watch(() => props.isOpen, () => {
    if (props.isOpen === true) {
        getAllStations();
        refreshDevices();
        startChecking();
    }
    if (props.isOpen === false) {
        stopChecking();
    }
}, { deep: true });

onUnmounted(() => {
    stopChecking();
});
</script>
