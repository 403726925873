<template>
    <div>
        <div class="flex items-center justify-between">
            <div class="flex flex-1 justify-between sm:hidden">
                <a
                    href="#"
                    class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    @click="$emit('previous')"
                >Previous</a>
                <a
                    href="#"
                    class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    @click="$emit('next')"
                >Next</a>
            </div>
            <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <nav
                        class="relative z-0 inline-flex rounded-md shadow-xs -space-x-px"
                        aria-label="Pagination"
                    >
                        <a
                            href="#selectPage"
                            class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            @click="$emit('previous')"
                        >
                            <span class="sr-only">Previous</span>
                            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                        </a>
                        <template v-for="pos in [-3, -2, -1]" :key="pos">
                            <a
                                v-if="dataCount && currentPageNumber > -pos"
                                href="#selectPage"
                                aria-current="page"
                                class="relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                                @click="$emit('goto', currentPageNumber + pos)"
                            >{{ currentPageNumber + pos }}</a>
                        </template>
                        <a
                            href="#selectPage"
                            class="z-10 bg-primary-50 border-primary-500 text-primary-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                        >{{ currentPageNumber }}</a>
                        <template v-for="pos in [1, 2, 3]" :key="pos">
                            <a
                                v-if="maxPages && currentPageNumber + pos <= maxPages"
                                href="#selectPage"
                                aria-current="page"
                                class="relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                                @click="$emit('goto', currentPageNumber + pos)"
                            >{{ currentPageNumber + pos }}</a>
                        </template>
                        <a
                            href="#selectPage"
                            class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            @click="$emit('next')"
                        >
                            <span class="sr-only">Next</span>
                            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
                        </a>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup generic="TData extends {[key: string]: any}">
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid';
import { PropType, computed } from 'vue';

const props = defineProps({
    dataCount: {
        type: Number as PropType<number | null>,
        required: true,
    },
    currentPageNumber: {
        type: Number,
        required: true,
    },
    pageSize: {
        type: Number,
        required: true,
    },
});

const emit = defineEmits<{
    (e: 'previous'): void
    (e: 'next'): void
    (e: 'goto', value: number): void
}>();

const maxPages = computed((): number | null => {
    if (props.dataCount === null) {
        return null;
    }

    return Math.ceil(props.dataCount / props.pageSize);
});
</script>
