<template>
    <!-- Customer Support -->
    <base-modal
        id="emailSupportModal"
        :icon="EnvelopeIcon"
        size="xl"
        :open="open"
        :close-func="close"
    >
        <div class="mt-2">
            <!-- Subject -->
            <base-input
                id="support-subject"
                class="mt-2"
                label="Subject"
                placeholder="Subject"
                :form="supportForm"
                form-prop="subject"
            />

            <!-- Message -->
            <base-textarea
                id="support-message"
                class="mt-2"
                label="Message"
                placeholder="Message"
                :form="supportForm"
                form-prop="message"
            />

            <!-- Sku -->
            <div
                v-if="formType === 'fix-item'"
                id="support-subject"
                class="mt-4"
                label="Sku"
                placeholder="Sku"
                :form="supportForm"
                form-prop="sku"
            >
                Related to SKU: {{ sku }}
            </div>
        </div>

        <template #footer>
            <base-button
                class="w-full sm:mt-0 sm:w-auto ml-2"
                :icon="EnvelopeIcon"
                text="Send"
                :click-func="sendSupportRequest"
                :busy="supportForm?.busy.value"
            />
        </template>
    </base-modal>
</template>

<script lang="ts" setup>
import { EnvelopeIcon } from '@heroicons/vue/20/solid';
import { SparkForm, sendForm } from '@/helpers/forms/form';

const {
    formType = 'general',
    sku = '',
} = defineProps<{
    open: boolean
    formType?: string
    sku?: string
}>();

const emit = defineEmits<{
    (e: 'close'): void
}>();

const supportForm = new SparkForm({
    subject: '',
    message: '',
});

const close = () => {
    emit('close');
    supportForm?.reset();
};

const sendSupportRequest = () => {
    if (formType === 'fix-item') {
        supportForm!.message += `\n\nSent from Fix Item Issues for SKU: ${sku}`;
    } else {
        supportForm!.message += `\n\nSent from page: ${window.location.href}`;
    }

    sendForm({
        method: 'post',
        uri: '/api/support/email',
        form: supportForm,
        successMessage: 'Your message has been sent.',
    }).then(() => {
        close();
    });
};
</script>
