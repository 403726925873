<template>
    <div>
        <BaseSelect
            :value="pageSize"
            :options="pageSizeOptions"
            options-placement="top-start"
            @update:model-value="handleSelectChange"
        />
    </div>
</template>

<script lang="ts" setup generic="TData extends {[key: string]: any}">
import { computed } from 'vue';
import BaseSelect from '../form/BaseSelect.vue';

const { availablePageSizes } = defineProps<{
    pageSize: number
    availablePageSizes: number[]
}>();

const emit = defineEmits<{
    (e: 'change-page-size', value: number): void
}>();

const pageSizeOptions = computed(() => {
    return availablePageSizes.map(size => ({
        name: `Show ${size}`,
        value: size,
    }));
});

const handleSelectChange = (value: number | string | boolean | null | undefined) => {
    if (typeof value !== 'number') {
        return;
    }

    emit('change-page-size', value);
};
</script>
