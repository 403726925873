export enum BaseColour {
    Primary = 'primary',
    Indigo = 'indigo',
    Gray = 'gray',
    Red = 'red',
    Yellow = 'amber',
    Teal = 'teal',
    Green = 'green',
    Blue = 'blue',
}
