<template>
    <div
        v-if="showProgress"
        class="shrink flex flex-col overflow-y-auto bg-gray-800 justify-end h-48"
    >
        <h3
            class="pt-4 pb-1 px-3 text-sm leading-4 font-semibold text-gray-300 uppercase tracking-wider"
        >
            Account Setup
        </h3>
        <div class="py-2 px-4 sm:px-6 lg:px-8">
            <nav class="flex justify-center" aria-label="Progress">
                <ol class="space-y-3">
                    <li v-for="step in steps" :key="step.name">
                        <a
                            v-if="stepStatus(step) === 'complete'"
                            class="group"
                            :class="step.clickFunc ? 'cursor-pointer' : ''"
                            @click="handleClick(step)"
                        >
                            <span class="flex items-start">
                                <span
                                    class="shrink-0 relative h-5 w-5 flex items-center justify-center"
                                >
                                    <CheckCircleIcon
                                        class="h-full w-full text-primary-500"
                                        :class="step.clickFunc ? 'group-hover:text-primary-400' : ''"
                                        aria-hidden="true"
                                    />
                                </span>
                                <span
                                    class="ml-3 text-sm font-medium text-gray-300"
                                    :class="step.clickFunc ? 'group-hover:text-gray-200' : ''"
                                >{{ step.name }}</span>
                            </span>
                        </a>
                        <a
                            v-else-if="stepStatus(step) === 'current'"
                            class="flex items-start group cursor-pointer"
                            aria-current="step"
                            @click="handleClick(step)"
                        >
                            <span
                                class="shrink-0 h-5 w-5 relative flex items-center justify-center"
                                aria-hidden="true"
                            >
                                <span
                                    class="absolute h-3 w-3 rounded-full bg-amber-300 opacity-50 animate-ping-slow"
                                />
                                <span class="relative block w-2 h-2 bg-amber-500 rounded-full" />
                            </span>
                            <span
                                class="ml-3 text-sm font-medium text-amber-500 group-hover:text-amber-600"
                            >
                                {{
                                    step.name
                                }}
                            </span>
                        </a>
                        <a v-else class="group cursor-pointer" @click="handleClick(step)">
                            <div class="flex items-start">
                                <div
                                    class="shrink-0 h-5 w-5 relative flex items-center justify-center"
                                    aria-hidden="true"
                                >
                                    <div
                                        class="h-2 w-2 bg-red-500 rounded-full group-hover:bg-red-600 opacity-50"
                                    />
                                </div>
                                <p
                                    class="ml-3 text-sm font-medium text-red-500 group-hover:text-red-600 opacity-50"
                                >
                                    {{
                                        step.name
                                    }}
                                </p>
                            </div>
                        </a>
                    </li>
                </ol>
            </nav>
        </div>
    </div>

    <div class="shrink flex flex-col overflow-y-auto bg-gray-800 justify-end h-10 px-4">
        <Transition
            enter-active-class="origin-left transition ease-in-out duration-200"
            enter-from-class="scale-x-0"
            enter-to-class="scale-x-100"
        >
            <base-badge v-if="showCompleteBadge" :colour="BaseColour.Green" class="mb-2">
                <CheckCircleIcon class="text-green-500 h-6 w-6 mr-2 animate-pulse" />Setup Complete
            </base-badge>
        </Transition>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { CheckCircleIcon } from '@heroicons/vue/20/solid';
import confetti from 'canvas-confetti';
import { BaseColour } from '@conveyr/shared-types';
import useUserOnboarding, { availableToursEnum, progressStepInterface } from '@/composables/useUserOnboarding';
import useAppEntitlements from '@/composables/useAppEntitlements';
import { useChannelsStore } from '@/stores/channels';
import { useNarrative } from '@/composables/useNarrative';
import useInertia from '@/composables/useInertia';

const { hasAmazonShipmentsEntitlement } = useAppEntitlements();

const { currentUser } = useNarrative();

const clientUserOnboarding = computed(() => currentUser.value.onboarding?.clientUser);
const showCompleteBadge = ref(false);
const channelNameText = hasAmazonShipmentsEntitlement.value ? 'Amazon' : 'Channel';

const { startTour, resetTour } = useUserOnboarding();

const activeChannel = computed((): 'upcoming' | 'complete' | 'current' => {
    if (!useChannelsStore().hasActiveChannels) {
        return 'upcoming';
    }

    return 'complete';
});

const featuresGuideStatus = computed((): 'upcoming' | 'complete' | 'current' => {
    if (!clientUserOnboarding.value) {
        return 'upcoming';
    }
    if (clientUserOnboarding.value === 'complete') {
        return 'complete';
    }

    return 'current';
});

const showProgress = computed(() => {
    if (activeChannel.value === 'complete' && featuresGuideStatus.value === 'complete') {
        return false;
    }

    return true;
});

const steps: progressStepInterface[] = [];
steps.push({
    name: 'Features Guide',
    clickFunc: () => resetClientUserTutorial(),
});
steps.push({
    name: `Configure ${channelNameText}`,
    clickFunc: () => { useInertia().visit('/channels'); },
});

const resetClientUserTutorial = () => {
    resetTour(availableToursEnum.ClientUser).then(() => {
        startTour(availableToursEnum.ClientUser);
    });
};

const stepStatus = (step: any) => {
    switch (step.name) {
        case 'Features Guide':
            return featuresGuideStatus.value;
        case `Configure ${channelNameText}`:
            return activeChannel.value;

        default:
            return 'complete';
    }
};

const handleClick = (step: any) => {
    step.clickFunc();
};

const pageLoadDelayComplete = ref(false);
setTimeout(() => {
    pageLoadDelayComplete.value = true;
}, 2000);
watch(showProgress, (newValue, oldValue) => {
    if (!pageLoadDelayComplete.value) {
        return;
    }

    if (!newValue && oldValue) {
        showCompleteBadge.value = true;

        confetti({
            particleCount: 150,
            spread: 70,
            origin: { y: 0.9, x: 0.05 },
            angle: 55,
            startVelocity: 50,
            drift: 1,
            disableForReducedMotion: true,
            ticks: 100,
            gravity: 1.5,
        });
    }
});
</script>
