import { PrintJobWebsocketEventType, UserWebsocketEventType, WebsocketEvent, WebsocketEventData } from '@conveyr/shared-types';
import useFormatters from '../useFormatters';
import useStations from '../useStations';
import usePopupNotifications from '../usePopupNotifications';
import API from '@/api';
import { WebsocketSubscriber } from '@/composables/websockets/websocketListener';

interface DatabaseNotificationWebsocketEventData extends WebsocketEventData {
    id: string
    createdAt: string
    notificationType: 'action' | 'error' | 'success'
    body: string
    actionText: string
    actionUrl: string
    actionType: 'print_labels'
    actionData: object
    read: boolean
}

export interface DatabaseNotificationWebsocketEvent extends WebsocketEvent {
    event: UserWebsocketEventType
    data: DatabaseNotificationWebsocketEventData
}

export interface PrintJobCreatedWebsocketEvent extends WebsocketEvent {
    event: PrintJobWebsocketEventType
    data: {
        printJobId: string
        userId: number
    }
}

const isUserEvent = (event: WebsocketEvent): event is DatabaseNotificationWebsocketEvent => {
    return Object.values(UserWebsocketEventType).includes(event.event as UserWebsocketEventType);
};

const isPrintJobCreatedEvent = (event: WebsocketEvent): event is PrintJobCreatedWebsocketEvent => {
    return Object.values(PrintJobWebsocketEventType).includes(event.event as PrintJobWebsocketEventType);
};

export default function useUserWebsocketListener() {
    const { keysToCamelCase } = useFormatters();
    const { downloadLabelsIfNoAvailablePrinter } = useStations();
    const { notifyError, notifyAction } = usePopupNotifications();

    const defaultWebsocketSubscriber: WebsocketSubscriber = {
        id: 'default',
        onOneTab: true,
        callback: (event: WebsocketEvent) => {
            if (isUserEvent(event)) {
                handleUserNotification(event);
                return;
            }
            if (isPrintJobCreatedEvent(event)) {
                handlePrintJobCreated(event);
            }
        },
    };

    const handlePrintJobCreated = (event: PrintJobCreatedWebsocketEvent) => {
        API.printing.attemptPrint(event.data.printJobId).then((printJob) => {
            useStations().handlePrintJob(printJob);
        });
    };

    const handleUserNotification = (event: DatabaseNotificationWebsocketEvent) => {
        if (event.data.notificationType === 'error') {
            notifyError(event.data.body);
            return;
        }

        if (event.data.notificationType === 'action') {
            notifyAction(
                event.data.body,
                event.data.actionText,
                () => window.open(event.data.actionUrl, '_blank'),
            );

            if (event.data.actionType) {
                if (event.data.actionType === 'print_labels') {
                    const printData = keysToCamelCase(event.data.actionData);

                    if (printData.printerInfo) {
                        printData.printerInfo = keysToCamelCase(printData.printerInfo);
                    }

                    downloadLabelsIfNoAvailablePrinter(printData);
                }
            }
        }
    };

    return {
        defaultWebsocketSubscriber,
    };
}
