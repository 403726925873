import { Item, ItemIdentifier } from '@conveyr/shared-types';
import axios from '@/helpers/forms/axios';

export interface createIdentifierInterface {
    identifierType: string
    identifier: string
}

const createIdentifier = async (item: Item, payload: createIdentifierInterface): Promise<ItemIdentifier> => {
    const response = await axios.post<{ identifier: ItemIdentifier }>(`/api/inventory/${item.id}/identifier`, payload);

    return response.data.identifier;
};

const deleteIdentifier = async (identifierId: number): Promise<void> => {
    return await axios.delete(`/api/identifier/${identifierId}`);
};

export default {
    createIdentifier,
    deleteIdentifier,
};
