import axios from '@/helpers/forms/axios';

export interface barcodeLabelInterface {
    barcode: string
    title: string
}

export interface FnskuLabel {
    fnsku: string
    title: string
    condition: string | null
    count: number
    expiryDate: string | null
}

const requestFnskuLabels = async (labels: FnskuLabel[]): Promise<void> => {
    return await axios.post('/api/labels/fnsku', { labels });
};

const requestBarcodeLabels = async (labels: barcodeLabelInterface[]): Promise<void> => {
    await axios.post('/api/labels/barcode', { labels });
};

export default {
    requestBarcodeLabels,
    requestFnskuLabels,
};
