/**
 * SparkForm helper constuctor. Used to set common properties on all forms.
 */

import { Ref, ref } from 'vue';
import { Method } from 'axios';
import set from 'lodash/set';
import omit from 'lodash/omit';
import { SparkFormErrors } from './errors';
import axios from '@/helpers/forms/axios';
import usePopupNotifications from '@/composables/usePopupNotifications';

interface SparkFormInterface<T> {
    initialData: T
    busy: Ref<boolean>
    successful: Ref<boolean>
    errors: SparkFormErrors
    startProcessing: () => void
    finishProcessing: () => void
    reset: () => void
    all: () => T
    resetStatus: () => void
    setErrors: (errors: { [key: string]: Array<string> }) => void
    setProperty: (prop: string, value: any) => void
    [key: string]: any
}

const _SparkForm = function <T>(this: SparkFormInterface<T>, props: T) {
    this.initialData = structuredClone(props);

    Object.assign(this, props);

    /**
     * Create the form error helper instance.
     */
    this.errors = new SparkFormErrors();

    this.busy = ref(false);
    this.successful = ref(false);

    /**
     * Start processing the form.
     */
    this.startProcessing = () => {
        this.errors.forget();
        this.busy.value = true;
        this.successful.value = false;
    };

    /**
     * Finish processing the form.
     */
    this.finishProcessing = () => {
        this.busy.value = false;
        this.successful.value = true;
    };

    /**
     * Reset the form to its original state.
     */
    this.reset = () => {
        Object.assign(this, this.initialData);

        this.resetStatus();
    };

    /**
     * Reset the errors and other state for the form.
     */
    this.resetStatus = () => {
        this.errors.forget();
        this.busy.value = false;
        this.successful.value = false;
    };

    /**
     * Set the errors on the form.
     */
    this.setErrors = (errors: { [key: string]: Array<string> }) => {
        this.busy.value = false;
        this.errors.set(errors);
    };

    this.setProperty = (property: string, value: any) => {
        set(this, property, value);
    };

    this.all = (): T => {
        const formData = omit(this, ['busy', 'errors', 'finishProcessing', 'initialData', 'reset', 'resetStatus', 'setErrors', 'setProperty', 'startProcessing', 'successful', 'all']);

        return formData as T;
    };
} as unknown;

export type SparkFormType<T> = SparkFormInterface<T> & T;
export const SparkForm = _SparkForm as new<T>(data: T) => SparkFormType<T>;

interface sendFormInterface {
    method: Method
    uri: string
    form: SparkFormType<any>
    successMessage?: string
    errorMessage?: string
}

interface fileInterface {
    name: string
    file: any
}

interface sendFileFormInterface extends sendFormInterface {
    files: Array<fileInterface>
    parameters?: Array<string>
}
/**
 * Send the form to the back-end server.
 *
 * This function will clear old errors, update "busy" status, etc.
 */

export function sendForm({ method, uri, form, successMessage = undefined, errorMessage = undefined }: sendFormInterface): Promise<any> {
    return new Promise((resolve, reject) => {
        const { notifyError, notifySuccess } = usePopupNotifications();
        form.startProcessing();

        const formData = form.all();

        const requestData = JSON.parse(JSON.stringify(removeEmpty(formData)));

        axios.request<any>({
            method,
            url: uri,
            data: requestData,
        }).then((response) => {
            if (!response.data.message) {
                // Success message displayed from axios handler if message is set
                const message = successMessage || 'Processed Successfully';

                notifySuccess(message);
            }

            form.finishProcessing();

            resolve(response.data);
        })
            .catch((errors) => {
                if (errorMessage) {
                    notifyError(errorMessage);
                } else if (!errors.response) {
                    console.error(JSON.stringify(errors));

                    reject(Error('Request failed with no error message'));
                } else if (errors.response.status === 403 && errors.response.data.message) {
                    // Axios will handle this message
                } else if (errors.response.status !== 500 && errors.response.data.message) {
                    if (!errors.response.data.errors || !Object.keys(errors.response.data.errors).length) {
                        notifyError(errors.response.data.message);
                    }
                }

                form.setErrors(errors.response.data.errors);

                if (errors.response.status !== 422) {
                    reject(errors.response.data);
                } else if (!errors.response.data.errors) {
                    reject(Error('No data'));
                } else {
                    reject(Error);
                }
            });
    });
}

export function sendFileForm({ method, uri, form, successMessage = undefined, errorMessage = undefined, files, parameters }: sendFileFormInterface): Promise<any> {
    return new Promise((resolve, reject) => {
        const { notifyError, notifySuccess } = usePopupNotifications();

        form.startProcessing();

        const data = new FormData();

        files.forEach((file) => {
            data.append(file.name, file.file);
        });

        parameters?.forEach((parameter) => {
            data.append(parameter, form[parameter]);
        });

        axios.request<any>({
            method,
            url: uri,
            data,
        }).then((response) => {
            if (!response.data.message) {
                // Success message displayed from axios handler if message is set
                const message = successMessage || 'Processed Successfully';

                notifySuccess(message);
            }

            form.finishProcessing();

            resolve(response.data);
        })
            .catch((errors) => {
                if (!errors.response) {
                    console.error(JSON.stringify(errors));

                    reject(Error('Request failed with no error message'));
                };

                if (errorMessage) {
                    notifyError(errorMessage);
                } else if (errors.response.data.message) {
                    if (!errors.response.data.errors || !Object.keys(errors.response.data.errors).length) {
                        notifyError(errors.response.data.message);
                    }
                }

                form.setErrors(errors.response.data.errors);

                if (errors.response.status !== 422) {
                    reject(errors.response.data);
                } else if (!errors.response.data.errors) {
                    reject(Error('No data'));
                } else {
                    reject(Error);
                }
            });
    });
}

function removeEmpty(obj: any) {
    const newObj = {} as any;

    Object.entries(obj).forEach(([k, v]) => {
        if (v === Object(v)) {
            newObj[k] = removeEmpty(v);
        } else if (v != null) {
            newObj[k] = obj[k];
        }
    });
    return newObj;
}
