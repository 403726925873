<template>
    <div
        v-if="shouldShow"
        class="flex items-center gap-1 text-xs text-primary-500"
    >
        <span>Last Updated: {{ operationsStore.operationChecks[operation.getId()].relativeLastCheck }}</span>
        <ArrowPathIcon
            class="h-3.5 w-3.5 mx-1 cursor-pointer hover:opacity-75" :class="{
                'animate-spin': operationsStore.operationChecks[operation.getId()].checking,
            }" @click="operationsStore.operationChecks[operation.getId()].check"
        />
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { ArrowPathIcon } from '@heroicons/vue/20/solid';
import { Operation, OperationStatus, useOperationsStore } from '@/stores/operations/operations';

const props = defineProps<{
    operation: Operation
}>();

const operationsStore = useOperationsStore();

const shouldShow = computed(() => {
    if (props.operation.getStatus() !== OperationStatus.InProgress) return false;
    if (!operationsStore.operationChecks[props.operation.getId()].relativeLastCheck) return false;
    if (operationsStore.operationChecks[props.operation.getId()].relativeLastCheck === 'just now') return false;

    return true;
});
</script>
