import { CompanyService } from '@conveyr/shared-types';
import axios from '@/helpers/forms/axios';

export const fetchCompanyServices = async (type?: string[]): Promise<CompanyService[]> => {
    let uri = '/api/services';
    if (type && type.length) {
        uri += `?type[]=${type.join('&type[]=')}`;
    }

    const response = await axios.get<{ services: CompanyService[] }>(uri);

    return response.data.services;
};

export const fetchCompanyService = async (companyServiceId: number): Promise<CompanyService> => {
    const response = await axios.get<{ service: CompanyService }>(`/api/services/${companyServiceId}`);

    return response.data.service;
};

export const deleteService = async (companyServiceId: number) => {
    return await axios.delete(`/api/services/${companyServiceId}`);
};

export const archiveService = async (companyServiceId: number) => {
    return await axios.post(`/api/services/${companyServiceId}/archive`);
};

export const fetchArchivedServices = async (): Promise<CompanyService[]> => {
    const response = await axios.get<{ services: CompanyService[] }>('/api/services/archived');
    return response.data.services;
};
