<template>
    <Listbox v-model="selectedOptions" :disabled="disabled" as="div" multiple by="value">
        <ListboxLabel v-if="label" class="block text-sm font-medium text-gray-700">
            {{ label }}
        </ListboxLabel>
        <div class="relative" :class="{ 'mt-1': label }">
            <ListboxButton :class="buttonClasses">
                <span class="block truncate">{{ name }}</span>
                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
            </ListboxButton>

            <ListboxOptions
                :class="`floatingUiElement-${selectId}`"
                class="z-10 fixed bg-white shadow-lg max-h-60 rounded-md py-1 ring-1 ring-black/5 overflow-auto focus:outline-hidden text-sm"
            >
                <ListboxOption
                    v-for="(option, index) in options"
                    :key="index"
                    v-slot="{ active, selected }"
                    :required="required"
                    :autofocus="autofocus"
                    :disabled="option?.disabled"
                    as="template"
                    :value="option"
                >
                    <li
                        class="cursor-default select-none relative py-2 pl-3 pr-9" :class="[active ? 'text-white bg-primary-600' : 'text-gray-900']"
                        :data-select-value="option.value"
                    >
                        <span
                            class="block truncate" :class="[selected ? 'font-semibold' : 'font-normal']"
                        >
                            {{
                                option.name
                            }}
                        </span>

                        <span
                            v-if="selected"
                            class="absolute inset-y-0 right-0 flex items-center pr-4" :class="[active ? 'text-white' : 'text-primary-600']"
                        >
                            <CheckIcon v-if="useChecks" class="h-5 w-5" aria-hidden="true" />
                        </span>
                    </li>
                </ListboxOption>
            </ListboxOptions>
        </div>
    </Listbox>
</template>

<script lang="ts" setup>
import { PropType, computed, watch } from 'vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/20/solid';
import { Placement } from '@floating-ui/core';
import { SelectOption } from '@/compiler/types';
import useFloatingUi from '@/composables/useFloatingUi';

const props = defineProps({
    label: {
        type: String,
        default: null,
    },
    name: {
        type: String,
        default: null,
    },
    options: {
        type: Array as PropType<Array<SelectOption>>,
        required: true,
    },
    optionsPlacement: {
        type: String as PropType<Placement>,
        default: 'bottom-start',
    },
    autofocus: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    useChecks: {
        type: Boolean,
        default: true,
    },
    resetOnSelect: {
        type: Boolean,
        default: false,
    },
    value: {
        type: Array,
        default: () => [],
    },
    fallbackOption: {
        type: Object as PropType<SelectOption>,
        default: () => ({
            name: 'Loading Options...',
            value: 0,
        }),
    },
});

const selectId = `baseSelect-${Math.random().toString(36).slice(-5)}`;

const buttonClasses = computed(() => {
    let classes = 'bg-white relative w-full border rounded-md shadow-xs pl-3 pr-10 py-2 text-left cursor-default focus:outline-hidden focus:ring-1 text-sm';

    classes += ' border-gray-300 focus:ring-primary-500 focus:border-primary-500';

    if (props.disabled) {
        classes += ' cursor-not-allowed';
    }

    classes += ` floatingUiTrigger-${selectId}`;

    return classes;
});

const selectedOptions = defineModel<SelectOption[]>();

const computePosition = () => {
    useFloatingUi(selectId, {
        placement: props.optionsPlacement,
    }, {
        atLeastSameSizeAsReference: true,
    });
};

watch(props, () => {
    computePosition();
}, { deep: true });

computePosition();
</script>
