import { defineStore } from 'pinia';
import { OperationProblem, WorkflowStatus, WorkflowType } from '@conveyr/shared-types';
import { DescribableProgressOperation, DisplayableInFooterOperation, OperationCallbacks, OperationStatus, OperationWebsocketEventData, PollableOperation, useOperationsStore } from './operations';
import API from '@/api';
import { Workflow } from '@/api/workflows';

interface State {
    operations: Workflow[]
    operationCallbacks: { [key: string]: OperationCallbacks }
    loadOperationsPromise: Promise<void> | null
    loadingOperationIds: string[]
}

export const useWorkflowOperationsStore = defineStore('workflowOperations', {
    state: (): State => ({
        operations: [],
        operationCallbacks: {},
        loadOperationsPromise: null,
        loadingOperationIds: [],
    }),
    getters: {
        getOperationById: state => (operationId: string): Workflow | null => {
            return state.operations?.filter(operation => operation.workflowId === operationId)[0] ?? null;
        },
    },
    actions: {
        async loadOperations(): Promise<void> {
            if (this.loadOperationsPromise) return this.loadOperationsPromise;

            this.loadOperationsPromise = this.refreshOperations().finally(() => {
                this.loadOperationsPromise = null;
            });

            return this.loadOperationsPromise;
        },
        async loadOperationsById(operationIds: string[]): Promise<void> {
            // get all operations that are not in the list or being loaded
            const missingOperations = operationIds
                .filter(id => !this.operations.some(operation => operation.workflowId === id))
                .filter(id => !this.loadingOperationIds.includes(id));

            if (!missingOperations.length) return;

            this.loadingOperationIds.push(...missingOperations);

            const response = await API.workflows.fetchWorkflows(this.loadingOperationIds);

            response.forEach(operation => this.set(operation));
        },
        async refreshOperations(): Promise<void> {
            const response = await API.workflows.fetchWorkflows();

            response.forEach(operation => this.set(operation));
        },
        async fetchOperation(operationId: string): Promise<Workflow> {
            const response = await API.workflows.fetchWorkflows([operationId]);

            this.set(response[0]);

            return response[0];
        },
        getOperationSource(): () => Promise<BaseWorkflowOperation[]> {
            return () => new Promise((resolve) => {
                this.loadOperations().then(() => {
                    resolve(this.operations.map(operation => this.getBaseOperation(operation)));
                });
            });
        },
        getBaseOperation(operation: Workflow): BaseWorkflowOperation {
            return new BaseWorkflowOperation(operation);
        },
        set(operation: Workflow, callbacks: OperationCallbacks | null = null): void {
            const operationIndex = this.operations.findIndex(findOperation => findOperation.workflowId === operation.workflowId);

            if (operationIndex >= 0) {
                this.operations[operationIndex] = operation;
            } else {
                this.operations.push(operation);
            }

            if (callbacks) {
                this.operationCallbacks[operation.workflowId] = callbacks;
            }

            useOperationsStore().set(this.getBaseOperation(operation));
        },
        triggerCallbacks(operationId: string): void {
            const callbacks = this.operationCallbacks[operationId];
            const workflow = this.operations.find(op => op.workflowId === operationId);
            if (!callbacks || !workflow) return;

            const operation = this.getBaseOperation(workflow);
            if (operation.getStatus() === OperationStatus.Failed && callbacks.onFailure) {
                callbacks.onFailure();
            }
            if (operation.getStatus() === OperationStatus.Succeeded && callbacks.onSuccess) {
                callbacks.onSuccess();
            }
        },
    },
});

export class BaseWorkflowOperation implements PollableOperation, DescribableProgressOperation, DisplayableInFooterOperation {
    constructor(
        private operation: Workflow,
    ) {
    }

    getId(): string {
        return this.operation.workflowId;
    }

    getCreatedAt(): string {
        return this.operation.startTime;
    }

    getStatus(): OperationStatus {
        switch (this.operation.status) {
            case WorkflowStatus.Completed:
                return OperationStatus.Succeeded;
            case WorkflowStatus.Canceled:
            case WorkflowStatus.Failed:
            case WorkflowStatus.Terminated:
            case WorkflowStatus.TimedOut:
                return OperationStatus.Failed;
            case WorkflowStatus.ContinuedAsNew:
            case WorkflowStatus.Running:
            case WorkflowStatus.Unspecified:
            default:
                return OperationStatus.InProgress;
        }
    }

    getProgress(): number {
        return this.operation.progress.current;
    }

    getProgressDescription(): string | null {
        return this.operation.progress.description;
    }

    getDescription(): string {
        switch (this.operation.type) {
            case WorkflowType.FbaTransport.CopyBoxGroupsFromPlanToPlan:
                return 'Copying box groups from plan to plan';
            case WorkflowType.FbaTransport.Autoplanner:
                return 'FBA Autoplanner';
            case WorkflowType.FbaTransport.SplitPackingGroupsIntoPlans:
                return 'Split packing groups into plans';
            default:
                return 'Generic workflow';
        }
    }

    getWarnings(): OperationProblem[] {
        return this.operation.progress.problems.filter(problem => problem.severity === 'WARNING');
    }

    getErrors(): OperationProblem[] {
        return this.operation.progress.problems.filter(problem => problem.severity === 'ERROR');
    }

    update(data: OperationWebsocketEventData): void {
        // If the operation is already completed, don't update the progress
        if (this.operation.status === WorkflowStatus.Completed) return;

        if (data.progressDescription !== undefined) {
            this.operation.progress.description = data.progressDescription;
        }

        if (data.problems) {
            this.operation.progress.problems = data.problems;
        }

        if (data.progress < 0) {
            this.operation.progress.current = 0;
        }
        if (data.progress > 100) {
            this.operation.progress.current = 100;
        }

        // Only update the progress if it is higher than the current progress.
        // This prevents the progress bar from jumping when websockets arrive out of order
        if (data.progress > this.operation.progress.current) {
            this.operation.progress.current = data.progress;
        };

        // If the progress is 100%, ensure the status is set to Succeeded
        if (data.progress === 100) {
            data.status = OperationStatus.Succeeded;
        }

        if (data.status === OperationStatus.Succeeded) {
            this.operation.progress.current = 100;
            this.operation.status = WorkflowStatus.Completed;
        }
        if (data.status === OperationStatus.Failed) {
            this.operation.status = WorkflowStatus.Failed;
        }
        if (data.status === OperationStatus.InProgress) {
            this.operation.status = WorkflowStatus.Running;
        }

        useWorkflowOperationsStore().triggerCallbacks(this.operation.workflowId);
    }

    updateIntervalSeconds(): number {
        return 10;
    }

    async fetchUpdate(): Promise<void> {
        return useWorkflowOperationsStore().fetchOperation(this.operation.workflowId).then((response) => {
            this.operation = response;

            useWorkflowOperationsStore().triggerCallbacks(this.operation.workflowId);
        });
    }

    displayInFooter(): boolean {
        return true;
    }
}
