<template>
    <span
        class="inline-flex items-center py-0.5 rounded-full whitespace-nowrap font-medium"
        :class="spanClasses"
    >
        <slot />
        <button
            v-if="removeFunc"
            v-tooltip="'Delete'"
            type="button"
            class="badgeRemoveButton"
            :class="iconClasses"
            aria-label="Remove"
            @click="remove"
        >
            <XMarkIcon
                v-if="!icon"
                class="h3 w-3"
            />
            <component
                :is="icon as any"
                v-if="icon"
                class="h3 w-3"
            />
        </button>
        <button
            v-if="editFunc"
            v-tooltip="'Edit'"
            type="button"
            class="badgeEditButton"
            :class="iconClasses"
            aria-label="Edit"
            @click="edit"
        >
            <PencilIcon
                v-if="!icon"
                class="h3 w-3"
            />
            <component
                :is="icon as any"
                v-if="icon"
                class="h3 w-3"
            />
        </button>
        <button
            v-if="viewFunc"
            v-tooltip="'View'"
            type="button"
            class="badgeViewButton"
            :class="iconClasses"
            aria-label="View"
            @click="view"
        >
            <EyeIcon
                v-if="!icon"
                class="h3 w-3"
            />
            <component
                :is="icon as any"
                v-if="icon"
                class="h3 w-3"
            />
        </button>

        <component
            :is="icon as any"
            v-if="!viewFunc && !editFunc && !removeFunc && icon"
            class="h3 w-3"
            :class="iconClasses"
        />
    </span>
</template>

<script lang="ts" setup>
import { PropType, computed } from 'vue';
import { EyeIcon, PencilIcon, XMarkIcon } from '@heroicons/vue/20/solid';
import { BaseColour } from '@conveyr/shared-types';

const props = defineProps({
    size: {
        type: String,
        validator: (prop: string) => ['lg', 'sm'].includes(prop),
        default: 'sm',
    },
    colour: {
        type: String as PropType<BaseColour>,
        default: BaseColour.Primary,
    },
    removeFunc: {
        type: Function,
        default: null,
    },
    editFunc: {
        type: Function,
        default: null,
    },
    viewFunc: {
        type: Function,
        default: null,
    },
    icon: {
        type: Function,
        default: null,
    },
});

const spanClasses = computed(() => {
    const sizeClasses: { [key: string]: string } = {
        lg: 'px-3 text-sm leading-5',
        sm: 'px-2.5 text-xs leading-4',
    };

    return `${sizeClasses[props.size]} bg-${props.colour}-100 text-${props.colour}-800`;
});

const iconClasses = computed(() => {
    let classes = 'shrink-0 ml-1.5 inline-flex focus:outline-hidden' + ` text-${props.colour}-500  focus:text-${props.colour}-700`;

    if (props.size === 'lg') {
        classes += ' -mr-0.5';
    }

    return classes;
});

const remove = () => {
    props.removeFunc();
};
const edit = () => {
    props.editFunc();
};
const view = () => {
    props.viewFunc();
};

/* Specify classes to prevent purgeCSS from stripping them
    bg-indigo-100 bg-teal-100 bg-red-100 bg-amber-100 bg-amber-100 bg-green-100 bg-primary-100 bg-blue-100
    text-indigo-800 text-teal-800 text-red-800 text-amber-800 text-amber-800 text-green-800 text-primary-800 text-blue-800
*/
</script>
