import { Item, Move, MoveLine, Stock, Warehouse, WarehouseLocation } from '@conveyr/shared-types';
import axios, { fetchPaginatedDataFunction } from '@/helpers/forms/axios';
import { DataFetcherFunction } from '@/compiler/types';

const fetchLocations = (warehouseId: number): DataFetcherFunction<WarehouseLocation<undefined, undefined, Stock<undefined, Item>>> => {
    return fetchPaginatedDataFunction<WarehouseLocation<undefined, undefined, Stock<undefined, Item>>>(`/api/warehouses/${warehouseId}/locations`);
};

type FetchLocationReturnType = WarehouseLocation<
    Warehouse,
    MoveLine<Move<undefined, Item>>,
    Stock<undefined, Item>
>;

const fetchLocation = (warehouseId: number, locationId: number) => axios
    .get<FetchLocationReturnType>(`/api/warehouses/${warehouseId}/locations/${locationId}`)
    .then(response => response.data);

const exportLocations = async (warehouseId: number): Promise<string> => {
    const response = await axios
        .get<{ downloadUrl: string }>(`/api/warehouses/${warehouseId}/locations/export`);

    return response.data.downloadUrl;
};

const deleteLocation = (warehouseId: number, locationId: number, note: string | null = null) => axios
    .delete<void>(`/api/warehouses/${warehouseId}/locations/${locationId}`, { data: { note } });

export default
{
    fetchLocations,
    fetchLocation,
    exportLocations,
    deleteLocation,
};
