<template>
    <Menu
        as="div"
        class="relative inline-block text-left"
    >
        <div>
            <MenuButton
                :class="`floatingUiTrigger-${dropdownId}`"
                class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-xs px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-hidden focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500"
            >
                <slot name="left" />
                <slot>{{ title }}</slot>
                <slot name="right">
                    <ChevronDownIcon
                        class="-mr-1 ml-2 h-5 w-5"
                        aria-hidden="true"
                    />
                </slot>
            </MenuButton>
        </div>

        <teleport to="body">
            <MenuItems
                :class="`floatingUiElement-${dropdownId} ${fixedHeight ? 'max-h-[400px] overflow-y-auto' : ''}`"
                class="absolute z-30 rounded-md shadow-lg bg-white ring-1 ring-black/5 focus:outline-hidden"
            >
                <div class="py-1">
                    <MenuItem
                        v-for="item in items"
                        v-slot="{ active }"
                        :key="item.text"
                    >
                        <a
                            :href="item.href"
                            class="cursor-pointer px-4 py-2 text-sm whitespace-nowrap capitalize"
                            :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', item.icon ? 'group flex items-center' : 'block']"
                            @click="click(item)"
                        >
                            <component
                                :is="item.icon as any"
                                v-if="item.icon"
                                class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                            />
                            {{ item.text }}
                        </a>
                    </MenuItem>
                </div>
            </MenuItems>
        </teleport>
    </Menu>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';
import { Placement } from '@floating-ui/core';
import { offset } from '@floating-ui/dom';
import useFloatingUi from '@/composables/useFloatingUi';
import { dropdownItemInterface } from '@/compiler/types';

const props = defineProps({
    title: {
        type: String,
        default: null,
    },
    items: {
        type: Array as PropType<dropdownItemInterface[]>,
        default: null,
    },
    itemsPlacement: {
        type: String as PropType<Placement>,
        default: 'bottom-end',
    },
    fixedHeight: {
        type: Boolean,
        default: false,
    },
});

const dropdownId = `baseDropdown-${Math.random().toString(36).slice(-5)}`;

const click = (item: dropdownItemInterface) => {
    if (item.clickFunc) {
        item.clickFunc();
    }
};

useFloatingUi(dropdownId, {
    placement: props.itemsPlacement,
    middleware: [offset(5)],
});
</script>
