<template>
    <Combobox :id="id" v-slot="{ open }" v-model="selectedOption" as="div" @update:model-value="handleSelect">
        <ComboboxLabel
            v-if="label"
            class="block text-sm font-medium text-gray-700"
            :class="labelClasses"
        >
            {{ label }}
        </ComboboxLabel>
        <div class="mt-1 relative">
            <ComboboxInput
                data-lpignore="true"
                class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-xs focus:border-indigo-500 focus:outline-hidden focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                :placeholder="placeholder"
                :display-value="(option) => (option as comboboxOptionInterface)?.name"
                :class="`floatingUiTrigger-${comboboxId}`"
                @change="query = $event.target.value"
                @keyup="triggerFloatingUiUpdate"
                @focus="handleInputFocus"
                @click="handleInputClick"
            />
            <ComboboxButton
                :id="`comboboxButton-${id}`"
                ref="comboboxButtonRef"
                :class="`floatingUiTrigger-${comboboxId}`"
                class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-hidden"
            >
                <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </ComboboxButton>

            <teleport to="body">
                <div v-show="open && filteredOptions.length > 0 && floatingIsReady">
                    <ComboboxOptions
                        class="w-100 absolute z-10 mt-1 max-h-56 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-hidden sm:text-sm"
                        :class="`floatingUiElement-${comboboxId}`"
                        :title="title"
                        :unmount="false"
                        static
                    >
                        <ComboboxOption
                            v-for="option in filteredOptions"
                            :id="`comboboxOption-${id}-${option.value}`"
                            :key="option.value"
                            v-slot="{ active, selected, disabled }"
                            :value="option"
                            :disabled="option.disabled !== undefined ? option.disabled : false"
                            as="template"
                        >
                            <li
                                class="relative select-none py-2 pl-3 pr-9" :class="[{
                                    'bg-indigo-600 text-white': active,
                                    'bg-gray-50 text-gray-300 cursor-not-allowed': disabled,
                                    'text-gray-800': !disabled && !active,
                                    'cursor-default': !disabled,
                                }]"
                                :data-select-value="option.value"
                            >
                                <div class="flex items-center">
                                    <img
                                        v-if="option.imageUrl"
                                        :src="option.imageUrl"
                                        :alt="option.name"
                                        class="h-6 w-6 shrink-0 rounded-full"
                                    >
                                    <span
                                        class="ml-3 truncate"
                                        :class="[selected && 'font-semibold']"
                                    >{{ option.name }}</span>
                                </div>
                                <span
                                    v-if="selected"
                                    class="absolute inset-y-0 right-0 flex items-center pr-4" :class="[active ? 'text-white' : 'text-indigo-600']"
                                >
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                            </li>
                        </ComboboxOption>
                    </ComboboxOptions>
                </div>
            </teleport>
        </div>
    </Combobox>
</template>

<script lang="ts" setup>
import { PropType, computed, nextTick, ref, watch } from 'vue';
import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/20/solid';
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
} from '@headlessui/vue';
import { autoPlacement, offset, shift } from '@floating-ui/dom';
import useFloatingUi from '@/composables/useFloatingUi';

export interface comboboxOptionInterface {
    value: string | number
    name: string
    imageUrl?: string
    disabled?: boolean
}

const props = defineProps({
    options: {
        type: Array as PropType<comboboxOptionInterface[]>,
        required: true,
    },
    selectedValue: {
        type: [String, Number],
    },
    label: String,
    labelClasses: String,
    id: {
        type: String,
        default: () => {
            return `Combobox-${Math.floor(Math.random() * 100000)}${1}`;
        },
    },
    placeholder: {
        type: String,
        default: null,
    },
    title: {
        type: String,
        default: null,
    },
});

const emit = defineEmits<{
    (e: 'option-selected', option: comboboxOptionInterface): void
}>();

const comboboxButtonRef = ref<InstanceType<typeof ComboboxButton>>();
const query = ref<string>('');
const selectedOption = ref<comboboxOptionInterface | undefined>(props.selectedValue ? props.options.find(option => option.value === props.selectedValue) : undefined);
const filteredOptions = computed(() =>
    query.value === ''
        ? props.options
        : props.options.filter((option) => {
            return option.name.toLowerCase().includes(query.value.toLowerCase());
        }),
);

const comboboxId = `baseCombobox-${Math.random().toString(36).slice(-5)}`;

const handleSelect = (option: comboboxOptionInterface) => {
    emit('option-selected', option);

    nextTick(() => {
        nextTick(() => {
            (document.activeElement as HTMLInputElement).blur(); // Defocus input
        });
    });
};

const handleInputFocus = (event: Event) => {
    floatingIsReady.value = false;
    const target = event.target as HTMLInputElement;

    target.setSelectionRange(0, target.value.length);

    updateFloatingUiElement();
    floatingIsReady.value = true;
};

const floatingIsReady = ref(false);

const handleInputClick = () => {
    floatingIsReady.value = false;
    const comboboxButton = document.querySelector(`#comboboxButton-${props.id}`);

    if (comboboxButton) {
        (comboboxButton as HTMLButtonElement).click(); // Open the options list
    }

    updateFloatingUiElement();
    floatingIsReady.value = true;
};

const triggerFloatingUiUpdate = () => {
    floatingIsReady.value = false;
    updateFloatingUiElement();
    floatingIsReady.value = true;
};

watch(() => props.selectedValue, (newValue) => {
    selectedOption.value = props.options.find(option => option.value === props.selectedValue);
});

const { updateFloatingUiElement } = useFloatingUi(comboboxId, {
    placement: 'bottom-end',
    middleware: [
        shift(),
        offset(5),
        autoPlacement({
            allowedPlacements: ['top', 'bottom'],
        }),
    ],
}, {
    atLeastSameSizeAsReference: true,
});
</script>
