<template>
    <base-alert v-if="!currentStation" :colour="BaseColour.Red" class="my-2">
        You currently do not have a station assigned. The print queue is for managing prints for your station, so you will not see any new jobs here until you have a station assigned.
    </base-alert>
    <CompactTable :table="table">
        <template #headers>
            <CompactTableHeader>
                <div class="px-2">
                    Document
                </div>
            </CompactTableHeader>
            <CompactTableHeader>Printer</CompactTableHeader>
            <CompactTableHeader>Status</CompactTableHeader>

            <CompactTableHeader />
        </template>
        <template #cells="{ row }">
            <CompactTableCell>
                <div class="pl-1">
                    <p v-tooltip="row.name">
                        {{ truncate(row.name, 30) }}
                    </p>
                    <p v-tooltip="row.source" class="text-xs text-gray-400">
                        {{ truncate(row.source, 30) }}
                    </p>
                </div>
            </CompactTableCell>
            <CompactTableCell>
                <div>
                    <p v-tooltip="row.printer.identifier">
                        {{ truncate(row.printer.identifier, 20) }}
                    </p>
                    <p class="text-xs text-gray-400">
                        Density: {{ row.printer.density === 80 ? '203/200 DPI' : '300 DPI' }}
                    </p>
                </div>
            </CompactTableCell>
            <CompactTableCell>
                <p class="leading-tight">
                    {{ snakeToTitleCase(row.status) }}
                </p>
                <a
                    v-if="row.events.length > 0"
                    href="#history"
                    class="text-xs text-primary-400 hover:text-primary-500"
                    @click="showHistoryForJob(row)"
                >{{ showingHistoryForJob === row ? 'Hide' : 'Show' }} History ({{ row.events.length }})</a>
            </CompactTableCell>

            <CompactTableCell>
                <div class="flex flex-row justify-end">
                    <BaseButton
                        v-if="row.status === 'waiting'"
                        :icon="PrinterIcon"
                        :busy="printing"
                        :click-func="() => attemptPrint(row)"
                    />
                </div>
            </CompactTableCell>
        </template>
    </CompactTable>

    <div v-if="showingHistoryForJob">
        <h3 class="text-lg font-medium">
            Print Job History - {{ showingHistoryForJob.name }}
        </h3>

        <div class="flow-root mt-2">
            <ul class="-mb-8">
                <template v-for="(jobEvent, index) in showingHistoryForJob.events" :key="jobEvent.id">
                    <FeedItem
                        v-if="jobEvent.event === 'print_job_attempted'"
                        :text="`${snakeToTitleCase(relativeWithTime(jobEvent.occurredAt))}: Attempted to print.`"
                        :status="false"
                        :last="index === showingHistoryForJob.events.length - 1"
                    />
                    <FeedItem
                        v-if="jobEvent.event === 'print_job_completed'"
                        :text="`${snakeToTitleCase(relativeWithTime(jobEvent.occurredAt))}: Print job completed successfully.`"
                        :status="true"
                        :last="index === showingHistoryForJob.events.length - 1"
                    />
                    <FeedItem
                        v-if="jobEvent.event === 'print_job_failed'"
                        :text="`${snakeToTitleCase(relativeWithTime(jobEvent.occurredAt))}: Print job failed. Reason: ${jobEvent.data.failureReason}`"
                        status="failed"
                        :last="index === showingHistoryForJob.events.length - 1"
                    />
                    <FeedItem
                        v-if="jobEvent.event === 'print_job_cancelled'"
                        :text="`${snakeToTitleCase(relativeWithTime(jobEvent.occurredAt))}: Print job cancelled. Reason: ${jobEvent.data.cancellationReason}`"
                        status="failed"
                        :last="index === showingHistoryForJob.events.length - 1"
                    />
                </template>
            </ul>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { inject, ref, watch } from 'vue';
import { PrinterIcon } from '@heroicons/vue/20/solid';
import { BaseColour } from '@conveyr/shared-types';
import { RemotePingPongTable } from '@/helpers/tables/remotePingPongTable';
import API from '@/api';
import CompactTable from '@/components/elements/table/CompactTable.vue';
import CompactTableHeader from '@/components/elements/table/CompactTableHeader.vue';
import CompactTableCell from '@/components/elements/table/CompactTableCell.vue';
import useFormatters from '@/composables/useFormatters';
import BaseButton from '@/components/elements/BaseButton.vue';
import { PrintJob } from '@/api/printing';
import useStations from '@/composables/useStations';
import FeedItem from '@/components/elements/FeedItem.vue';
import { providerKeys } from '@/composables/useProviders';

const props = defineProps<{
    open: boolean
}>();

const { truncate, snakeToTitleCase, relativeWithTime } = useFormatters();
const { handlePrintJob, currentStation } = useStations();

const table = RemotePingPongTable.newReactive(API.printing.fetchPrintJobs, job => job.id);

const printing = ref(false);
const attemptPrint = (job: PrintJob) => {
    printing.value = true;

    API.printing.attemptPrint(job.id).then((detailedJob) => {
        handlePrintJob(detailedJob).catch(() => {

        }).finally(() => {
            printing.value = false;

            table.requestData(table.page, table.pageSize);

            setTimeout(() => {
                showingHistoryForJob.value = null;
                showHistoryForJob(job);
            }, 300);
        });
    }).catch(() => {
        printing.value = false;
    });
};

const showingHistoryForJob = ref<PrintJob | null>(null);
const showHistoryForJob = (job: PrintJob) => {
    if (showingHistoryForJob.value?.id === job.id) {
        showingHistoryForJob.value = null;
        return;
    }

    showingHistoryForJob.value = table.data.find(j => j.id === job.id) ?? null;
};

watch(() => props.open, () => {
    if (props.open === true) {
        table.requestData(1, 5);
    }
});

watch(() => currentStation.value, () => {
    table.requestData(table.page, table.pageSize);
});

const userWebsocketListener = inject(providerKeys.userWebsocketListener);
userWebsocketListener?.addSubscriber({
    id: 'print_queue_tab',
    callback: (event) => {
        if (event.event.includes('PrintJobCreated')) {
            table.requestData(table.page, table.pageSize);
        }
    },
});
</script>
