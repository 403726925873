import API from '@/api';
import { useLargeImageStore } from '@/stores/overlays/largeImage';

export default function useImages() {
    const largeImageStore = useLargeImageStore();

    const openLargeImage = (url: string): void => {
        largeImageStore.setLargeImageSrc(url);
    };

    const deleteImage = (imageId: number) => {
        return API.deleteImage(imageId);
    };

    return {
        openLargeImage,
        deleteImage,
    };
}
