<template>
    <fieldset
        class="flex flex-col border-primary-500 border rounded-sm min-w-[10em] max-w-xl bg-white"
        :class="{ 'pb-2': operation.getStatus() !== OperationStatus.InProgress }"
    >
        <div class="flex flex-row px-3 pt-2">
            <div class="flex flex-col flex-1 min-w-0">
                <div class="flex items-center space-x-2 min-w-0">
                    <h2 class="text-sm font-semibold leading-6 text-gray-400 truncate">
                        {{ operation.getDescription() }}
                    </h2>
                </div>
                <div class="flex items-center gap-x-2.5 text-xs leading-5 text-gray-300 min-w-0">
                    <p class="truncate">
                        <b>Operation ID: </b><CopyToClipboard :text="isActionableOperation(operation) ? reverseTruncate(operation.getId(), 12) : operation.getId()" :value="operation.getId()" />
                    </p>
                </div>
            </div>
            <div class="flex flex-col space-y-1 items-end ml-4">
                <div>
                    <OperationStatusBadge :operation="operation" />
                </div>
                <OperationAction v-if="isActionableOperation(operation) && operation.getStatus() === OperationStatus.Succeeded" :operation="operation" />
            </div>
        </div>

        <OperationProblemList :operation="operation" />

        <OperationProgressBar v-show="operation.getStatus() === OperationStatus.InProgress" :operation="operation" />
    </fieldset>
</template>

<script setup lang="ts">
import OperationStatusBadge from './OperationStatusBadge.vue';
import OperationAction from './OperationAction.vue';
import OperationProgressBar from './OperationProgressBar.vue';
import OperationProblemList from './OperationProblemList.vue';
import CopyToClipboard from '@/components/elements/CopyToClipboard.vue';
import { Operation, OperationStatus, isActionableOperation } from '@/stores/operations/operations';
import useFormatters from '@/composables/useFormatters';

defineProps<{
    operation: Operation
}>();

const { reverseTruncate } = useFormatters();
</script>
