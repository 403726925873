import { Item } from '@conveyr/shared-types';
import axios from '@/helpers/forms/axios';

const fetchInventoryItem = async (itemId: number): Promise<Item> => {
    const response = await axios.get<{ item: Item }>(`/api/inventory/${itemId}`);

    return response.data.item;
};

const removeTagFromInventoryItem = async (itemId: number, tagId: number): Promise<void> => {
    return await axios.delete(`/api/inventory/${itemId}/tags/delete-tag/${tagId}`);
};

const addTagToInventoryItem = async (itemId: number, tagId: number): Promise<void> => {
    return await axios.post(`/api/inventory/${itemId}/tags/add-tag/${tagId}`);
};

export default {
    fetchInventoryItem,
    removeTagFromInventoryItem,
    addTagToInventoryItem,
};
