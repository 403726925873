import { Attachment, CompanyService, Image, Item, ItemConditionEnum, Move, MoveLine, Shipment, ShipmentItemGroupConfiguration, Tag, Warehouse, WarehouseLocation } from '@conveyr/shared-types';
import { trackingNumberInterface } from '../repositories/trackingNumbers';
import { UpdateShipmentFn, UpdateShipmentRequestData } from './outbound';
import { PaginatedRequestFunction, PaginatedResults, requestFilterInterface } from '@/compiler/types';
import axios from '@/helpers/forms/axios';

export interface InboundShipment extends Shipment {
    id: number
    createdAt: string
    updatedAt: string
    name: string
    teamId: number
    notes: string
    internalNotes: string
    serviceLines: Array<InboundShipmentServiceLine<undefined, CompanyService>>
    attachments?: Attachment[]
    warehouseId: number
    warehouse?: Warehouse
    status: InboundShipmentStatus
    receivedAt: string
    referenceId: string
    trackingNumbers: trackingNumberInterface[]
    tags: Tag[]
    searchableTags: string[]
    shippedItemsCount: number
    actualQuantity: number
    expectedQuantity: number
    unsellableQuantity: number
}

export type InboundShipmentStatus = 'open' | 'shipped' | 'received';

export interface InboundShipmentServiceLine<
    SH extends InboundShipment | undefined = any,
    S extends CompanyService | undefined = any,
> {
    id: number
    createdAt: string
    updatedAt: string
    companyServiceId: number
    inboundShipmentId: number
    quantity: number

    shipment: SH
    service: S
}

export interface InboundShipmentItem {
    id: number
    item: Item
    itemId: number
    shipmentId: number
    expected: {
        quantity: number
        itemGroupConfigurations: ShipmentItemGroupConfiguration[]
    }
    actual: {
        quantity: number
        itemGroupConfigurations: ShipmentItemGroupConfiguration[]
        moves: Array<Move<MoveLine<undefined, WarehouseLocation>>>
    }
    unsellable: {
        quantity: number
    }
}

export interface InboundShipmentLiveTrackingInfo {
    status: 'pending' | 'transit' | 'delivered' | 'returned' | 'failure' | 'unknown'
    number: number | null
    eta: string | null
}

export interface UnsellableItem {
    id: number
    itemId: number
    images: Image[]
    notes: string
    receivedCondition?: ItemConditionEnum
    quantity: number
    item: Item
    shipmentId: number
    shipment: InboundShipment
}

const fetchInboundShipments: PaginatedRequestFunction<InboundShipment> = async (page: number, filters?: requestFilterInterface[]) => {
    const url = new URL('/api/shipments/inbound', location.origin);

    url.searchParams.append('page', page.toString());
    if (filters) {
        filters.forEach((filter) => {
            url.searchParams.append(`filters[${filter.name}]`, filter.value);
        });
    }

    const response = await axios.get<PaginatedResults<InboundShipment>>(url.toString());

    return response.data;
};

const fetchInboundShipment = async (shipmentId: number): Promise<InboundShipment> => {
    const response = await axios.get<{ shipment: InboundShipment }>(`/api/shipments/inbound/${shipmentId}`);

    return response.data.shipment;
};

const createInboundShipment = async (name: string, warehouseId: number): Promise<{ shipmentId: number }> => {
    const response = await axios.post<{ shipmentId: number }>('/api/shipments/inbound', {
        name,
        warehouseId,
    });

    return response.data;
};

const updateInboundShipment: UpdateShipmentFn = async (shipmentId: number, data: UpdateShipmentRequestData): Promise<InboundShipment> => {
    const response = await axios.put<{ shipment: InboundShipment }>(`/api/shipments/inbound/${shipmentId}`, data);

    return response.data.shipment;
};

const fetchInboundShipmentLiveTracking = async (shipmentId: number): Promise<InboundShipmentLiveTrackingInfo[]> => {
    const response = await axios.get<{ tracking: InboundShipmentLiveTrackingInfo[] }>(`/api/shipments/inbound/${shipmentId}/track`);

    return response.data.tracking;
};

const removeTagFromInboundShipment = async (shipmentId: number, tagId: number): Promise<void> => {
    return await axios.delete(`/api/shipments/inbound/${shipmentId}/tags/delete-tag/${tagId}`);
};

const addTagToInboundShipment = async (shipmentId: number, tagId: number): Promise<void> => {
    return await axios.post(`/api/shipments/inbound/${shipmentId}/tags/add-tag/${tagId}`);
};

const loadArchivedShipments: PaginatedRequestFunction<InboundShipment> = async (page: number) => {
    const response = await axios.get<PaginatedResults<InboundShipment>>(`/api/shipments/inbound/archived?page=${page}`);

    return response.data;
};

const batchArchive = async (shipmentIds: number[]): Promise<void> => {
    return await axios.post('/api/shipments/inbound/batch/archive', {
        shipmentIds,
    });
};

const batchUnarchive = async (shipmentIds: number[]): Promise<void> => {
    return await axios.post('/api/shipments/inbound/batch/unarchive', {
        shipmentIds,
    });
};

// Items
const fetchInboundShipmentItems = async (shipmentId: number): Promise<InboundShipmentItem[]> => {
    const response = await axios.get<{ items: InboundShipmentItem[] }>(`/api/shipments/inbound/${shipmentId}/items`);

    return response.data.items;
};

const fetchUnsellableItems = async (shipmentId: number, itemId: number | null): Promise<UnsellableItem[]> => {
    const url = new URL(`/api/shipments/inbound/${shipmentId}/inbound-shipment-unsellable-item`, location.origin);

    if (itemId) {
        url.searchParams.append('item_id', itemId.toString());
    }

    const response = await axios.get<UnsellableItem[]>(url.toString());

    return response.data;
};

const addInboundShipmentItem = async (shipmentId: number, itemId: number, quantity: number): Promise<void> => {
    return await axios.post(`/api/shipments/inbound/${shipmentId}/add-item`, { itemId, quantity });
};

const updateInboundShipmentItem = async (item: InboundShipmentItem): Promise<void> => {
    return await axios.post(`/api/shipments/inbound/${item.shipmentId}/update-item`, item);
};

const deleteInboundShipmentItem = async (shipmentId: number, itemId: number): Promise<void> => {
    return await axios.post(`/api/shipments/inbound/${shipmentId}/remove-item`, { itemId });
};

const addTrackingNumbers = async (shipmentId: number, carrier: string, trackingNumbers: string[]): Promise<void> => {
    return await axios.post(`/api/shipments/inbound/${shipmentId}/add-tracking`, { carrier, trackingNumbers });
};

export interface CreateUnsellableItemRequestData {
    itemId: number
    quantity: number
    notes: string
    receivedCondition?: ItemConditionEnum
    photos: File[]
}

const createUnsellableItem = async (shipmentId: number, data: CreateUnsellableItemRequestData): Promise<void> => {
    const form = new FormData();
    form.append('item_id', data.itemId.toString());
    form.append('quantity', data.quantity.toString());
    form.append('notes', data.notes);
    if (data.receivedCondition) {
        form.append('received_condition', data.receivedCondition);
    }

    if (data.photos.length) {
        for (const photo of data.photos) {
            form.append('photos[]', photo);
        }
    }

    return await axios.post(`/api/shipments/inbound/${shipmentId}/inbound-shipment-unsellable-item`, form);
};

export interface UpdateUnsellableItemRequestData {
    quantity: number
    notes: string
    receivedCondition?: ItemConditionEnum
    photos: File[]
}

const updateUnsellableItem = async (unsellableItemId: number, data: UpdateUnsellableItemRequestData): Promise<void> => {
    const form = new FormData();
    form.append('quantity', data.quantity.toString());
    form.append('notes', data.notes);
    if (data.receivedCondition) {
        form.append('received_condition', data.receivedCondition);
    }

    if (data.photos.length) {
        for (const photo of data.photos) {
            form.append('photos[]', photo);
        }
    }

    await axios.post(`/api/shipments/inbound/inbound-shipment-unsellable-item/${unsellableItemId}`, form);
};

const deleteUnsellableItem = async (unsellableItemId: number): Promise<void> => {
    return axios.delete(`/api/shipments/inbound/inbound-shipment-unsellable-item/${unsellableItemId}`);
};

const receiveInboundShipment = async (shipmentId: number): Promise<void> => {
    return await axios.post(`/api/shipments/inbound/${shipmentId}/receive`);
};

export default {
    addTrackingNumbers,
    batchArchive,
    batchUnarchive,
    loadArchivedShipments,
    fetchInboundShipments,
    fetchInboundShipment,
    createInboundShipment,
    updateInboundShipment,
    fetchInboundShipmentLiveTracking,
    removeTagFromInboundShipment,
    addTagToInboundShipment,

    fetchInboundShipmentItems,
    addInboundShipmentItem,
    updateInboundShipmentItem,
    deleteInboundShipmentItem,
    fetchUnsellableItems,
    createUnsellableItem,
    updateUnsellableItem,
    deleteUnsellableItem,

    receiveInboundShipment,
};
