import { Warehouse } from '@conveyr/shared-types';
import locations from './locations';
import axios from '@/helpers/forms/axios';
import { sendForm } from '@/helpers/forms/form';

const fetchWarehouses = async (): Promise<Warehouse[]> => {
    const response = await axios.get<{ data: Warehouse[] }>('/api/warehouses');

    return response.data.data;
};

const fetchWarehouse = async (warehouseId: number): Promise<Warehouse> => {
    const response = await axios.get<{ data: Warehouse }>(`/api/warehouses/${warehouseId}`);

    return response.data.data;
};

export interface CreateOrUpdateWarehouseForm {
    name: string
    addressLine1: string
    addressLine2: string
    addressLine3: string
    city: string
    stateProvince: string
    postalCode: string
    countryCode: string
    isResidential: boolean
    phoneNumber: string
}

const updateWarehouse = async (warehouseId: number, form: CreateOrUpdateWarehouseForm): Promise<void> => {
    return sendForm({
        method: 'PUT',
        uri: `/api/warehouses/${warehouseId}`,
        form,
    });
};

const createWarehouse = async (form: CreateOrUpdateWarehouseForm): Promise<void> => {
    return sendForm({
        method: 'POST',
        uri: `/api/warehouses`,
        form,
    });
};

const deleteWarehouseAddress = async (warehouseId: number, warehouseAddressId: number) => {
    return await axios.delete(`/api/warehouses/${warehouseId}/addresses/${warehouseAddressId}`);
};

export default {
    fetchWarehouses,
    fetchWarehouse,
    updateWarehouse,
    createWarehouse,
    deleteWarehouseAddress,
    locations,
};
