import { Stock, WarehouseLocation } from '@conveyr/shared-types';
import axios from '@/helpers/forms/axios';

const fetchItemStocks = async (itemId: number) => axios
    .get<Array<Stock<WarehouseLocation, undefined>>>(`/api/inventory/${itemId}/stocks`)
    .then(response => response.data);

export default {
    fetchItemStocks,
};
