<template>
    <BaseSlideover
        :open="open"
        title="Operations"
        size="xl"
        :close-func="close"
    >
        <BaseAlert class="my-2">
            Operations are background tasks that get queued and processed, so you can do other things while they are running.

            <div v-if="loading" class="flex flex-row items-center mt-2">
                <BaseSpinner class="h-4 mr-1" />
                <p class="text-loading">
                    Loading Operations
                </p>
            </div>
        </BaseAlert>

        <div class="flex flex-col space-y-4">
            <template v-for="operation in operationsStore.operations" :key="operation.getId()">
                <div class="relative">
                    <!-- Animated ring container -->
                    <div
                        class="absolute inset-0 transition-all duration-300 rounded-sm pointer-events-none"
                        :class="{
                            'ring-2 ring-primary-800/100 animate-pulse': operation.getId() === highlightedOperationId,
                            'ring-2 ring-primary-500/0': operation.getId() !== highlightedOperationId,
                        }"
                    />

                    <!-- Content -->
                    <OperationItem :operation="operation" />
                </div>
            </template>
        </div>
    </BaseSlideover>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import OperationItem from './OperationItem.vue';
import BaseSlideover from '@/components/elements/BaseSlideover.vue';
import { useOperationsStore } from '@/stores/operations/operations';
import BaseAlert from '@/components/elements/BaseAlert.vue';
import BaseSpinner from '@/components/elements/BaseSpinner.vue';

const props = defineProps<{
    open: boolean
    highlightOperationId: string | null
}>();

const emit = defineEmits<{
    (e: 'close'): void
}>();

const close = () => {
    emit('close');
};

const operationsStore = useOperationsStore();

const loading = ref(false);
watch(() => props.open, (newValue) => {
    if (newValue && operationsStore.operations.length < 5) {
        loading.value = true;
        operationsStore.loadOperationsFromSources().finally(() => {
            loading.value = false;
        });
    }
    if (!newValue) {
        clearTimeout(resetHighlightedOperationTimer.value);
        highlightedOperationId.value = null;
    }
});

const highlightedOperationId = ref<string | null>(null);
const resetHighlightedOperationTimer = ref();
watch(() => props.highlightOperationId, (newValue) => {
    if (newValue) {
        clearTimeout(resetHighlightedOperationTimer.value);

        highlightedOperationId.value = newValue;

        resetHighlightedOperationTimer.value = setTimeout(() => {
            highlightedOperationId.value = null;
        }, 10000);
    }
});
</script>
