<template>
    <Menu
        v-slot="{ close }"
        as="div"
        class="ml-3 relative"
    >
        <div>
            <MenuButton
                class="max-w-xs bg-gray-800 flex items-center text-sm rounded-full focus:outline-hidden focus:ring-2 focus:ring-offset-gray-800 focus:ring-offset-2 focus:ring-primary-500"
                dusk="profile-nav-button"
            >
                <span class="sr-only">Open user menu</span>
                <img
                    class="h-8 w-8 rounded-full"
                    :src="currentUser.photoUrl"
                    alt="User Photo"
                >
            </MenuButton>
        </div>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="opacity-0 scale-95"
            enter-to-class="opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="opacity-100 scale-100"
            leave-to-class="opacity-0 scale-95"
        >
            <MenuItems
                class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black/5 divide-y divide-gray-100 focus:outline-hidden"
            >
                <div
                    v-if="impersonatingUserId"
                    class="py-1"
                >
                    <h3 class="mt-3 mb-1 px-3 text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider">
                        Developer
                    </h3>
                    <MenuItem
                        v-for="item in developerNavigation"
                        :key="item.name"
                        v-slot="{ active }"
                    >
                        <BaseLink
                            :href="item.href"
                            class="group flex items-center px-4 py-2 text-sm" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700']"
                            @clicked="close"
                        >
                            <component
                                :is="item.icon"
                                class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                            />
                            {{ item.name }}
                        </BaseLink>
                    </MenuItem>
                </div>
                <div class="py-1">
                    <h3 class="mt-3 mb-1 px-3 text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider">
                        Settings
                    </h3>
                    <MenuItem
                        v-for="item in settingsNavigation"
                        :key="item.name"
                        v-slot="{ active }"
                    >
                        <BaseLink
                            :href="item.href"
                            class="group flex items-center px-4 py-2 text-sm" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700']"
                            @clicked="close"
                        >
                            <component
                                :is="item.icon"
                                class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                            />
                            {{ item.name }}
                        </BaseLink>
                    </MenuItem>
                </div>
                <div class="py-1">
                    <h3 class="mt-3 mb-1 px-3 text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider">
                        Accounts
                    </h3>
                    <MenuItem v-slot="{ active }">
                        <a
                            href="#select-account"
                            class="group flex items-center px-4 py-2 text-sm" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700']"
                            @click="showAccountSwitcher = true;"
                        >
                            <ArrowPathIcon
                                class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                            />Switch Account
                        </a>
                    </MenuItem>
                </div>
                <div class="py-1">
                    <MenuItem
                        v-for="item in otherNavigation"
                        :key="item.name"
                        v-slot="{ active }"
                    >
                        <a
                            :href="item.href"
                            class="group flex items-center px-4 py-2 text-sm" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700']"
                            @click="close"
                        >
                            <component
                                :is="item.icon"
                                class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                            />
                            {{ item.name }}
                        </a>
                    </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>

    <teleport to="body">
        <AccountSwitcher
            :open="showAccountSwitcher"
            @close="showAccountSwitcher = false"
        />
    </teleport>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ArrowPathIcon, ArrowRightOnRectangleIcon, BanknotesIcon, BellIcon, BriefcaseIcon, BuildingStorefrontIcon, CogIcon, UserCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import AccountSwitcher from '../overlays/AccountSwitcher.vue';
import { useNarrative } from '@/composables/useNarrative';
import BaseLink from '@/components/elements/BaseLink.vue';

const settingsNavigation = [
    { name: 'Your Profile', href: '/settings', icon: UserCircleIcon },
];

const otherNavigation = [
    { name: 'Logout', href: '/logout', icon: ArrowRightOnRectangleIcon },
];

const { currentUser, impersonatingUserId, userHasRoleOnCurrentServiceProvider, userIsOwnerOnServiceProvider } = useNarrative();

const showAccountSwitcher = ref(false);

if (userHasRoleOnCurrentServiceProvider.value) {
    if (userIsOwnerOnServiceProvider.value) {
        settingsNavigation.push({ name: 'Notifications', href: '/staff/notification-settings', icon: BellIcon });
        settingsNavigation.push({ name: 'Services', href: '/staff/services', icon: BriefcaseIcon });
    }

    settingsNavigation.push({ name: 'Merchants', href: '/staff/merchants', icon: BuildingStorefrontIcon });

    if (userIsOwnerOnServiceProvider.value) {
        settingsNavigation.push({ name: 'Account Settings', href: '/staff/settings', icon: CogIcon });
        settingsNavigation.push({ name: 'Subscription & Usage', href: '/staff/service-provider-billing', icon: BanknotesIcon });
    }
}

const developerNavigation = computed(() => {
    const items = [];
    if (impersonatingUserId.value) {
        items.push({ name: 'Back To My Account', href: '/admin/users/stop-impersonating', icon: XCircleIcon });
    }

    return items;
});
</script>
