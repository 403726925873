import { OperationProblem, WorkflowParamsMap, WorkflowStatus } from '@conveyr/shared-types';
import axios from '@/helpers/forms/axios';

export interface WorkflowProgress {
    current: number
    description: string | null
    problems: OperationProblem[]
}

export interface Workflow {
    workflowId: string
    runId: string
    startTime: string
    progress: WorkflowProgress
    type: keyof WorkflowParamsMap
    status: WorkflowStatus
}

async function dispatchWorkflow<T extends keyof WorkflowParamsMap>(type: T, input: WorkflowParamsMap[T]): Promise<Workflow> {
    const response = await axios.post<{ data: Workflow }>('/api/workflows/dispatch', { type, input });

    return response.data.data;
};

const fetchWorkflows = async (workflowIds: string[] | null = null): Promise<Workflow[]> => {
    const response = await axios.get<{ data: Workflow[] }>('/api/workflows', { params: { workflow_ids: workflowIds } });

    return response.data.data;
};

export default {
    dispatchWorkflow,
    fetchWorkflows,
};
