import { defineStore } from 'pinia';
import { channelInterface } from '@/api/channels';
import API from '@/api';

interface State {
    channels: channelInterface[]
    loadChannelsPromise: Promise<any> | null
}

export const useChannelsStore = defineStore('channels', {
    state: (): State => ({
        channels: [],
        loadChannelsPromise: null,
    }),
    getters: {
        getChannels: state => state.channels,
        hasActiveChannels: state => Boolean(state.channels.filter(channel => channel.connectionStatus === 'active').length),
    },
    actions: {
        async loadChannels(): Promise<void> {
            if (this.loadChannelsPromise) return;
            if (this.channels.length) return;

            this.loadChannelsPromise = this.refreshChannels();

            return this.loadChannelsPromise;
        },
        async refreshChannels(): Promise<void> {
            return API.channels.fetchChannels().then((channels) => {
                this.channels = channels;
            }).finally(() => {
                this.loadChannelsPromise = null;
            });
        },
        getChannelsForMerchant(merchantId: number): channelInterface[] {
            return this.channels.filter(channel => channel.teamId === merchantId);
        },
        getChannelById(channelId: number): channelInterface | undefined {
            return this.channels.find(channel => channel.id === channelId);
        },
    },
});
