<template>
    <div
        v-if="show"
        class="bg-white shadow-lg w-24 h-5"
        @click="openOperationOverlay(operation)"
    >
        <div class="flex items-center h-full px-2 cursor-pointer hover:bg-gray-50">
            <div class="flex items-center gap-1 w-full">
                <component
                    :is="getStatusIcon(operation).icon"
                    :class="getStatusIcon(operation).iconClass"
                    class="size-4"
                />
                <div class="flex-1 h-1 bg-gray-100 rounded-full overflow-hidden min-w-8">
                    <div
                        class="h-full rounded-full transition-all duration-500"
                        :class="getStatusIcon(operation).progressClass"
                        :style="{ width: `${operation.getProgress()}%` }"
                    />
                </div>
                <EyeIcon class="text-gray-400 size-3" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ArrowPathIcon, CheckCircleIcon, ExclamationCircleIcon, EyeIcon, XCircleIcon } from '@heroicons/vue/20/solid';
import { onMounted, ref } from 'vue';
import dayjs, { Dayjs } from 'dayjs';
import { Operation, OperationStatus, useOperationsStore } from '@/stores/operations/operations';

const props = defineProps<{
    operation: Operation
}>();

const show = ref(true);

const operationsStore = useOperationsStore();

const openOperationOverlay = (operation: Operation) => {
    operationsStore.setHighlightOperationId(operation.getId());
};

const getStatusIcon = (operation: Operation) => {
    switch (operation.getStatus()) {
        case OperationStatus.InProgress:
            return {
                icon: ArrowPathIcon,
                progressClass: 'bg-primary-500',
                iconClass: 'text-primary-500 animate-spin',
            };
        case OperationStatus.Succeeded:
            if (operation.getErrors().length > 0) {
                return {
                    icon: XCircleIcon,
                    progressClass: 'bg-red-500',
                    iconClass: 'text-red-500',
                };
            }
            if (operation.getWarnings().length > 0) {
                return {
                    icon: ExclamationCircleIcon,
                    progressClass: 'bg-yellow-500',
                    iconClass: 'text-yellow-500',
                };
            }

            return {
                icon: CheckCircleIcon,
                progressClass: 'bg-green-500',
                iconClass: 'text-green-500',
            };
        case OperationStatus.Failed:
            return {
                icon: XCircleIcon,
                progressClass: 'bg-red-500',
                iconClass: 'text-red-500',
            };
    }
};

onMounted(() => {
    if (props.operation.getStatus() !== OperationStatus.InProgress) {
        show.value = false;
    }
});

const completedAt = ref<Dayjs | null>(null);
const checkProgress = setInterval(() => {
    if (completedAt.value) {
        if (dayjs().diff(completedAt.value, 'seconds') > 30) {
            show.value = false;
            clearInterval(checkProgress);
        }

        return;
    }

    if (props.operation.getStatus() !== OperationStatus.InProgress && completedAt.value === null) {
        completedAt.value = dayjs();
    }
}, 1000);
</script>
