import { Image, Item } from '@conveyr/shared-types';
import axios from '@/helpers/forms/axios';

export interface CreateItemImageRequest {
    thumbnailUrl: string
    largeUrl: string
}

const createItemImage = async (item: Item, payload: CreateItemImageRequest): Promise<Image> => {
    const response = await axios.post<{ data: Image }>(`/api/inventory/${item.id}/images`, payload);

    return response.data.data;
};

export default {
    createItemImage,
};
