import { Currency } from 'dinero.js';
import { computed } from 'vue';
import { useNarrative } from './useNarrative';

export default function useLocalization() {
    const { currentServiceProviderOptional } = useNarrative();

    const baseCurrency = computed((): Currency => {
        if (!currentServiceProviderOptional.value) throw new Error('Service Provider is required');

        return currentServiceProviderOptional.value.currency;
    });

    const displayCurrency = computed((): Currency => {
        if (!currentServiceProviderOptional.value) throw new Error('Service Provider is required');

        return currentServiceProviderOptional.value.currency;
    });

    const currencySymbol = (currency: Currency) => {
        const symbol = new Intl.NumberFormat('en', { style: 'currency', currency })
            .formatToParts(0)
            .find(x => x.type === 'currency');

        return symbol && symbol.value;
    };

    const numberOfExponentsInCurrency = (currency: Currency): number => {
        switch (currency) {
            case 'BHD':
                // Bahrain, as an example
                return 3;
            case 'USD':
            case 'CAD':
            case 'EUR':
            case 'GBP':
            case 'BRL':
            default:
                return 2;
        }
    };

    const usingMetric = computed((): boolean => {
        return currentServiceProviderOptional.value?.viewSettings?.metric?.enabled ?? false;
    });

    const weightLocale = computed(() => {
        if (!usingMetric.value) {
            return 'lb';
        }

        return 'kg';
    });

    const dimensionsLocale = computed(() => {
        if (!usingMetric.value) {
            return 'in';
        }

        return 'cm';
    });

    const volumeLocale = computed(() => {
        if (!usingMetric.value) {
            return 'ft3';
        }

        return 'm3';
    });

    const browserLanguageLocale = computed(() => {
        const browserLocales
            = navigator.languages === undefined
                ? [navigator.language]
                : navigator.languages;

        return browserLocales.length ? browserLocales[0] : 'en-US';
    });

    return {
        baseCurrency,
        displayCurrency,
        currencySymbol,
        numberOfExponentsInCurrency,
        usingMetric,
        weightLocale,
        dimensionsLocale,
        volumeLocale,
        browserLanguageLocale,
    };
}
