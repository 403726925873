import { PusherPrivateChannel } from 'laravel-echo/dist/channel';
import { PrintJobWebsocketEventType, ServiceProviderWebsocketEventType, UserWebsocketEventType, WebsocketEvent, WebsocketEventData, WebsocketEventType } from '@conveyr/shared-types';
import useFormatters from '@/composables/useFormatters';

export interface WebsocketSubscriberCallback {
    (event: WebsocketEvent): void
};

export interface WebsocketSubscriber {
    id: string
    callback: WebsocketSubscriberCallback
    onOneTab?: boolean
};

const isWebsocketEventType = (event: string): event is WebsocketEventType => {
    if (Object.values(UserWebsocketEventType).includes(event as UserWebsocketEventType)) {
        return true;
    }

    if (Object.values(PrintJobWebsocketEventType).includes(event as PrintJobWebsocketEventType)) {
        return true;
    }

    if (Object.values(ServiceProviderWebsocketEventType).includes(event as ServiceProviderWebsocketEventType)) {
        return true;
    }

    return false;
};

export class WebsocketListener {
    constructor(
        websocketChannel: PusherPrivateChannel,
        defaultSubscriber?: WebsocketSubscriber,
    ) {
        if (defaultSubscriber) {
            this.subscribers.push(defaultSubscriber);
        }
        this.websocketChannel = websocketChannel;
        this.websocketChannel.listenToAll(this.handleEvent);

        this.websocketChannel.listenForWhisper('setAlreadyHandledActions', (handledActions: number[]) => {
            localStorage.setItem('alreadyHandledActions', JSON.stringify(handledActions));
        });
    }

    subscribers: WebsocketSubscriber[] = [];
    websocketChannel: PusherPrivateChannel;

    addSubscriber = (subscriber: WebsocketSubscriber) => {
        this.subscribers.push(subscriber);
    };

    removeSubscriber = (id: string) => {
        this.subscribers = this.subscribers.filter(subscriber => subscriber.id !== id);
    };

    handleEvent = (event: string, data: WebsocketEventData) => {
        const eventType = event.replace(/^\./, ''); // Remove the leading dot
        if (!isWebsocketEventType(eventType)) return;

        const camelCaseData = useFormatters().keysToCamelCase(data);

        for (const subscriber of this.subscribers) {
            if (subscriber.onOneTab) {
                this.handleSingleTabSubscriber(subscriber, { event: eventType, data: camelCaseData });

                continue;
            }

            subscriber.callback({
                event: eventType,
                data: camelCaseData,
            });
        }
    };

    handleSingleTabSubscriber = (
        subscriber: WebsocketSubscriber,
        event: WebsocketEvent,
        skipHidden: boolean = true,
    ) => {
        setTimeout(() => {
            if (this.checkNotificationAlreadyHandled(event)) {
                return;
            }
            if (skipHidden && document.visibilityState === 'hidden') {
                // If this is not the currently visible tab, wait for a bit and then retry handling
                // If the notification was already handled by a visible tab, if will stop
                // If the notification was not already handled, it will not check the visibility state and handle the action
                setTimeout(() => {
                    this.handleSingleTabSubscriber(subscriber, event, false);
                }, 500);

                return;
            }

            subscriber.callback(event);
        }, Math.floor(Math.random() * (50 - 10) + 10)); // Add a random pause to avoid race conditions
    };

    checkNotificationAlreadyHandled = (event: WebsocketEvent): boolean => {
        const alreadyHandledActions = JSON.parse(localStorage.getItem('alreadyHandledActions') || '[]') as number[];

        const eventHash = useFormatters().cyrb53Hash(`${event.event}-${JSON.stringify(event.data)}`);

        if (alreadyHandledActions.includes(eventHash)) {
            return true;
        }

        if (alreadyHandledActions.length >= 5) {
            alreadyHandledActions.length = 6;
            alreadyHandledActions.shift();
        }
        alreadyHandledActions.push(eventHash);

        localStorage.setItem('alreadyHandledActions', JSON.stringify(alreadyHandledActions));

        this.websocketChannel.whisper('setAlreadyHandledActions', alreadyHandledActions);

        return false;
    };
}
