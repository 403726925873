<template>
    <BaseBadge
        :colour="statusColour"
        size="sm"
    >
        <div class="flex-none rounded-full mr-1" :class="circleClass">
            <div class="h-2 w-2 rounded-full bg-current" />
        </div>
        <span>{{ statusText }}</span>
        <span v-if="operation.getStatus() === OperationStatus.InProgress" class="ml-1" />
    </BaseBadge>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { BaseColour } from '@conveyr/shared-types';
import BaseBadge from '@/components/elements/BaseBadge.vue';
import { Operation, OperationStatus } from '@/stores/operations/operations';

const props = defineProps<{
    operation: Operation
}>();

const statusColour = computed((): BaseColour => {
    switch (props.operation.getStatus()) {
        case OperationStatus.InProgress:
            return BaseColour.Yellow;
        case OperationStatus.Succeeded:
            if (props.operation.getWarnings().length) {
                return BaseColour.Yellow;
            }
            if (props.operation.getErrors().length) {
                return BaseColour.Red;
            }
            return BaseColour.Green;
        case OperationStatus.Failed:
            return BaseColour.Red;
        default:
            return BaseColour.Yellow;
    }
});

const circleClass = computed((): string => {
    switch (props.operation.getStatus()) {
        case OperationStatus.InProgress:
            return 'text-amber-500 bg-amber-100/10 animate-pulse';
        case OperationStatus.Succeeded:
            if (props.operation.getWarnings().length) {
                return 'text-amber-500 bg-amber-100/10';
            }
            if (props.operation.getErrors().length) {
                return 'text-red-400 bg-red-400/10';
            }

            return 'text-green-400 bg-green-400/10';
        case OperationStatus.Failed:
            return 'text-red-400 bg-red-400/10';
        default:
            return 'text-gray-500 bg-gray-100/10';
    }
});

const statusText = computed(() => {
    switch (props.operation.getStatus()) {
        case OperationStatus.InProgress:
            return 'In Progress';
        case OperationStatus.Succeeded:
            if (props.operation.getWarnings().length) {
                return 'Succeeded with Warnings';
            }
            if (props.operation.getErrors().length) {
                return 'Finished with Errors';
            }
            return 'Succeeded';
        case OperationStatus.Failed:
            return 'Failed';
        default:
            return 'Unknown';
    }
});
</script>
