import { Listing, ListingFlagType, ListingIdentifier, ListingImage, ListingProductLink } from '@conveyr/shared-types';
import axios, { fetchPaginatedDataFunction } from '@/helpers/forms/axios';
import { DataFetcherFunction } from '@/compiler/types';

export interface CreateListingRequest {
    channelIdentifier: string
    itemId: number
    asin?: string
}

const fetchListings = fetchPaginatedDataFunction<Listing<ListingIdentifier[], ListingImage[], ListingFlagType[], ListingProductLink>>(`/api/channels/listings`);

const fetchListingsForChannel = (channelId: number): DataFetcherFunction<Listing<ListingIdentifier[], ListingImage[], ListingFlagType[], ListingProductLink>> => {
    return fetchPaginatedDataFunction<Listing<ListingIdentifier[], ListingImage[], ListingFlagType[], ListingProductLink>>(`/api/channels/${channelId}/listings`);
};

const showListing = async (listingId: number): Promise<Listing> => {
    const response = await axios.get<{ data: Listing }>(`/api/listings/${listingId}`);

    return response.data.data;
};

const createListing = async (channelId: number, payload: CreateListingRequest): Promise<any> => {
    return await axios.post<{ listings: Listing[] }>(`/api/channels/${channelId}/listings`, payload);
};

const linkListingToProduct = async (listingId: number, itemId: number): Promise<any> => {
    return await axios.post(`/api/channels/listings/${listingId}/link`, { itemId });
};

export default {
    fetchListings,
    fetchListingsForChannel,
    showListing,
    createListing,
    linkListingToProduct,
};
