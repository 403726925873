<template>
    <div class="bg-white overflow-hidden shadow rounded-lg">
        <div
            v-if="$slots.header && hasHeaderOnly"
            class="border-b border-gray-200 px-4 py-5 sm:p-6"
        >
            <h3
                class="text-lg leading-6 font-medium text-gray-900 w-full flex justify-between items-center"
            >
                <slot name="header" />
            </h3>
        </div>
        <div
            v-if="$slots.header && !hasHeaderOnly"
            class="border-b border-gray-200 px-2 flex justify-between items-center"
            :class="headerPadding"
        >
            <slot name="header" />
        </div>
        <div
            v-if="$slots.default"
            :class="`${bodyPadding} ${!$slots.header && !$slots.footer ? 'h-full' : ''}`"
        >
            <slot />
        </div>
        <div v-if="$slots.footer" class="bg-gray-50 px-4 py-4 sm:px-6 flex justify-end">
            <slot name="footer" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, useSlots } from 'vue';

const props = defineProps({
    padding: {
        type: String,
        validator: (prop: string) => ['wide', 'narrow', 'none'].includes(prop),
        default: 'wide',
    },
    scrollable: {
        type: Boolean,
        default: false,
    },
});

const slots = useSlots();

const headerPadding = computed(() => {
    const paddingClasses: { [key: string]: string } = {
        wide: 'py-2 sm:p-6',
        narrow: '',
        none: '',
    };

    return paddingClasses[props.padding];
});

const bodyPadding = computed(() => {
    const paddingClasses: { [key: string]: string } = {
        wide: 'px-4 py-5 sm:p-6',
        narrow: 'p-2 sm:p-4',
        none: '',
    };

    return paddingClasses[props.padding];
});

const hasHeaderOnly = computed((): boolean => {
    if (typeof slots === 'undefined' || !slots.header || slots.default || slots.footer) {
        return false;
    }

    return Boolean(slots.header);
});
</script>
