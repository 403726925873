/* eslint-disable no-console */
import * as qz from 'qz-tray';
import { ref } from 'vue';
import axios from '@/helpers/forms/axios';
import { useAzlabelsTrayPromiseStore } from '@/stores/azlabelsTrayPromise';

interface TrayPrinterInfo {
    identifier: string
    brand?: string
    density?: number
    orientation?: 'auto' | 'landscape' | 'portrait'
}

interface TrayPrintData {
    document: {
        url: string
        type: 'pdf' | 'zpl' | 'epl'
        widthMm?: number
        heightMm?: number
    }
    printer: TrayPrinterInfo
}

export interface scaleData {
    weight: string
    uom: 'g' | 'kg' | 'oz' | 'lb'
    status: string
}

export default function useAzlabelsTray() {
    async function connectTray(): Promise<void> {
        const azlabelsTrayPromiseStore = useAzlabelsTrayPromiseStore();

        if (azlabelsTrayPromiseStore.trayInUsePromise) {
            await azlabelsTrayPromiseStore.trayInUsePromise;
        }

        qz.websocket.setClosedCallbacks([
            (event: Event) => {
                console.log(event);

                const reason = 'reason' in event ? `: ${event.reason}` : 'Unknown';

                console.log(`AZLabels connection closed, reason: ${reason}`);

                azlabelsTrayPromiseStore.setTrayInUsePromise(null);
            },
        ]);

        const connectPromise = new Promise<void>((resolve, reject) => {
            qz.security.setCertificatePromise((resolve: any, reject: any) => {
                axios.get('/keys/digital-certificate.txt', {
                    headers: {
                        'Content-Type': 'text/plain',
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache',
                        'Expires': '0',
                    },
                }).then((response) => {
                    response.status === 200 ? resolve(response.data) : reject(response.data);
                });
            });

            qz.security.setSignatureAlgorithm('SHA512');
            qz.security.setSignaturePromise((toSign: string) => {
                return (resolve, reject) => {
                    axios.get<string>(`/api/staff/stations/sign?request=${toSign}`, {
                        headers: {
                            'Content-Type': 'text/plain',
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache',
                            'Expires': '0',
                        },
                    }).then((response) => {
                        response.status === 200 ? resolve(response.data) : reject(response.data);
                    });
                };
            });

            if (qz.websocket.isActive()) {
                resolve();
            } else {
                qz.websocket.connect({
                    host: ['localhost'],
                    retries: 1,
                    delay: 0,
                    port: { secure: [8181], insecure: [8182] },
                })
                    .then(() => resolve())
                    .catch((error) => {
                        reject(error);
                    });
            }
        })
            .catch((error) => {
                if (error.message.includes('Unable to establish connection with QZ')) {
                    console.log('Unable to establish connection with QZ');
                    return;
                }
                if (error.message.includes('Cannot read properties of null')) {
                    console.log(`Unable to establish connection with QZ: ${error.message}`);
                    return;
                }

                throw error;
            })
            .finally(() => {
                azlabelsTrayPromiseStore.setTrayInUsePromise(null);
            });

        azlabelsTrayPromiseStore.setTrayInUsePromise(connectPromise);

        return connectPromise;
    }

    const getPrintOptions = (data: TrayPrintData): qz.ConfigOptions => {
        const options: qz.ConfigOptions = {
            units: 'cm',
            interpolation: 'nearest-neighbor',
            rasterize: false,
        };

        if (data.printer.orientation && data.printer.orientation !== 'auto') {
            options.orientation = data.printer.orientation;
        }

        if (data.document.heightMm && data.document.widthMm) {
            options.size = {
                width: data.document.widthMm / 10,
                height: data.document.heightMm / 10,
            };

            if (data.printer.brand === 'rollo') {
                options.units = 'in';
                options.size = {
                    width: +(data.document.widthMm / 25.4).toFixed(1),
                    height: +(data.document.heightMm / 25.4).toFixed(1),
                };
            }
        }

        if (data.printer.brand === 'dymo') {
            options.colorType = 'grayscale';
        } else {
            options.density = data.printer.density;
            if (options.density && options.units === 'in') {
                options.density = options.density * 2.54;
            }
            options.colorType = 'blackwhite';
            options.scaleContent = true;
            options.duplex = 'one-sided';
        }

        return options;
    };

    const getQzPrintData = (printData: TrayPrintData): qz.PrintData[] => {
        return [{
            type: printData.document.type === 'pdf' ? 'pixel' : 'raw',
            format: printData.document.type === 'pdf' ? 'pdf' : 'command',
            flavor: 'file',
            data: printData.document.url,
        }];
    };

    const printToTray = async (printData: TrayPrintData): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            connectTray().then(() => {
                qz.printers.find(printData.printer.identifier).then((printer: string) => {
                    const config = qz.configs.create(printer, getPrintOptions(printData));

                    qz.print(config, getQzPrintData(printData)).then(() => {
                        resolve();

                        console.log('Sent data to printer');
                    }).catch((error: any) => {
                        reject(error);
                    });
                }).catch((error: any) => {
                    reject(error);
                });
            }).catch((error) => {
                reject(error);
            });
        });
    };

    function timeoutPromise(ms: number, promise: Promise<any>) {
        // https://italonascimento.github.io/applying-a-timeout-to-your-promises/
        // Create a promise that rejects in <ms> milliseconds
        const timeout = new Promise((resolve, reject) => {
            const id = setTimeout(() => {
                clearTimeout(id);
                reject(Error(`Timed out in ${ms}ms.`));
            }, ms);
        });

        // Returns a race between our timeout and the passed in promise
        return Promise.race([
            promise,
            timeout,
        ]);
    }

    const checkDeviceIsAvailable = async (slug: string, type: 'printer' | 'scale' = 'printer'): Promise<boolean> => {
        console.log(`Checking device available: ${slug}`);

        await timeoutPromise(5000, connectTray());

        if (type === 'printer') {
            qz.printers.find(slug).then(() => {
                return true;
            });
        }

        if (type === 'scale') {
            const deviceInfo = usbDeviceFromSlug(slug);

            const devices = await qz.hid.listDevices();

            if (deviceInfo && devices.some((device) => {
                return device.productId.includes(deviceInfo.productId) && device.vendorId.includes(deviceInfo.vendorId);
            })) {
                await claimDevice(deviceInfo);

                return true;
            } else {
                console.log(`Device not listed: ${slug}`);

                return false;
            }
        }

        console.log(`Device not found: ${slug}`);

        return false;
    };

    async function claimDevice(deviceInfo: qz.HidDevice): Promise<void> {
        await timeoutPromise(5000, connectTray());
        const claimed = await qz.hid.isClaimed(deviceInfo);
        if (!claimed) {
            await qz.hid.claimDevice(deviceInfo);
        }
    }

    async function findPrinters(): Promise<string[]> {
        try {
            await timeoutPromise(5000, connectTray());

            const printers = await qz.printers.find();

            return printers;
        } catch (error: any) {
            if (error.message === '_qz.websocket.connection is null') {
                return [];
            }
            if (error.message === 'Timed out in 5000ms.') {
                return [];
            }
            if (error.message === 'Unable to establish connection with QZ') {
                return [];
            }

            throw error;
        }
    }

    async function findUsbDevices(): Promise<qz.HidDevice[]> {
        try {
            await timeoutPromise(5000, connectTray());

            const devices = await qz.hid.listDevices();

            return devices;
        } catch (error: any) {
            if (error.message === '_qz.websocket.connection is null') {
                return [];
            }
            if (error.message === 'Timed out in 5000ms.') {
                return [];
            }

            throw error;
        }
    }

    function usbDeviceFromSlug(slug: string): qz.HidDevice | false {
        if (slug === 'dymo-scale-m25') {
            return <qz.HidDevice>{
                vendorId: '0922',
                productId: '8003',
                interface: '0x00',
                usagePage: '0x0001',
                serial: '00000000001',
            };
        }

        if (slug === 'fairbanks-scale-scb-r9000') {
            return <qz.HidDevice>{
                vendorId: '0b67',
                productId: '555e',
                interface: '0x00',
                usagePage: '0x0001',
                serial: '00000000001',
            };
        }

        return false;
    }

    const gettingScaleData = ref(false);
    async function readScaleFromTray(scaleSlug: string): Promise<scaleData | false> {
        gettingScaleData.value = true;

        const deviceInfo = usbDeviceFromSlug(scaleSlug);

        if (!deviceInfo) {
            gettingScaleData.value = false;
            return false;
        }

        await connectTray();
        await claimDevice(deviceInfo);
        const data = await qz.hid.readData(deviceInfo);
        const parsedData = parseScaleData(data);
        await qz.hid.releaseDevice(deviceInfo);

        gettingScaleData.value = false;

        return parsedData;
    }

    function parseScaleData(data: string[]): scaleData | false {
        // Filter erroneous data
        if (data.length < 4 || data.slice(2, 8).join('') === '000000000000') {
            return false;
        }

        // Get status
        const statusInt = Number.parseInt(data[1], 16);
        let statusText = 'Stable';

        switch (statusInt) {
            case 1: // fault
            case 5: // underweight
            case 6: // overweight
            case 7: // calibrate
            case 8: // re-zero
                statusText = 'Error';
                break;
            case 3: // busy
                statusText = 'Busy';
                break;
            case 2: // stable at zero
            case 4: // stable non-zero
            default:
                statusText = 'Stable';
        }

        // Get precision
        let precision = Number.parseInt(data[3], 16);
        precision = precision ^ -256; // unsigned to signed

        // xor on 0 causes issues
        if (precision === -256) { precision = 0; }

        // Get units
        const unitsInt = Number.parseInt(data[2], 16);
        let unitsText: 'g' | 'kg' | 'oz' | 'lb' = 'lb';

        switch (unitsInt) {
            case 2:
                unitsText = 'g';
                break;
            case 3:
                unitsText = 'kg';
                break;
            case 11:
                unitsText = 'oz';
                break;
            case 12:
            default:
                unitsText = 'lb';
        }

        // Get weight
        data.splice(0, 4);
        data.reverse();
        let weight = Number.parseInt(data.join(''), 16);
        weight *= 10 ** precision;

        const weightText = weight.toFixed(Math.abs(precision));

        return {
            weight: weightText,
            uom: unitsText,
            status: statusText,
        };
    }

    return {
        printToTray,
        checkDeviceIsAvailable,
        findPrinters,
        findUsbDevices,
        readScaleFromTray,
        usbDeviceFromSlug,
    };
}
