import { serviceProviderUserMembershipInvitationInterface } from '../repositories/users';
import { serviceProviderInterface } from '../repositories/serviceProviders';
import { sendForm } from '@/helpers/forms/form';
import axios from '@/helpers/forms/axios';

export interface registerServiceProviderFormInterface {
    name: string
    subdomainName: string
    currency: string
}

const registerServiceProvider = async (form: registerServiceProviderFormInterface): Promise<serviceProviderInterface> => {
    const response = await sendForm({ method: 'post', uri: '/api/register-company', form });

    return response.serviceProvider;
};

const fetchServiceProviderPendingInvitations = async (): Promise<serviceProviderUserMembershipInvitationInterface[]> => {
    const response = await axios.get<{ data: serviceProviderUserMembershipInvitationInterface[] }>('/api/serviceProviders/invitations');

    return response.data.data;
};

const resendServiceProviderInvitation = async (token: string): Promise<void> => {
    await axios.get(`/api/users/invitations/serviceProvider/${token}/resend`);
};

export default {
    registerServiceProvider,
    fetchServiceProviderPendingInvitations,
    resendServiceProviderInvitation,
};
