import { CompanyService, Item, Listing, Move, MoveLine, WarehouseLocation } from '@conveyr/shared-types';
import { orderInterface, pickDataInterface } from './orders';
import axios from '@/helpers/forms/axios';

export interface orderItemInterface {
    id: number
    createdAt: string
    updatedAt: string
    orderId: number
    order: orderInterface
    itemId: number
    item: Item
    listingId: number
    listing: Listing
    quantity: number
    pickedAt?: string
    pickData?: pickDataInterface
    serviceLines: Array<OrderItemServiceLine<CompanyService>>
    moves: Array<Move<MoveLine<undefined, WarehouseLocation>>>
};

export interface OrderItemServiceLine<
    S extends CompanyService | undefined = any,
> {
    orderItemId: number
    serviceId: number
    service: S
}

const updateOrderItemMoves = async (orderId: number, orderItemId: number, locationId: number, quantity: number): Promise<orderItemInterface> => {
    const response = await axios.post<{ orderItem: orderItemInterface }>(`/api/orders/${orderId}/updateItems/${orderItemId}/moves`, { locationId, quantity });

    return response.data.orderItem;
};

export default {
    updateOrderItemMoves,
};
