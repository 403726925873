import { App, InjectionKey, Ref, provide } from 'vue';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import useUserWebsocketListener from './websockets/useUserWebsocket';
import { useNarrative } from './useNarrative';
import useWebsockets from './websockets/useWebsockets';
import useAnalytics, { Analytics } from './useAnalytics';
import useServiceProviderWebsocketListener from './websockets/useServiceProviderWebsocket';
import { BarcodeListener } from '@/helpers/barcodeListener';
import { GeographyRepository } from '@/helpers/geographyRepository';
import { useBarcodeScannerStore } from '@/stores/barcodeScanner';
import { Spark } from '@/compiler/types';
import { WebsocketListener } from '@/composables/websockets/websocketListener';

export const providerKeys = {
    echo: Symbol('echo') as InjectionKey<Echo<'pusher'>>,
    barcodeListener: Symbol('barcodeListener') as InjectionKey<BarcodeListener>,
    userWebsocketListener: Symbol('userWebsocketListener') as InjectionKey<WebsocketListener>,
    serviceProviderWebsocketListener: Symbol('serviceProviderWebsocketListener') as InjectionKey<WebsocketListener>,
    geographyRepository: Symbol('geographyRepository') as InjectionKey<GeographyRepository>,
    analytics: Symbol('analytics') as InjectionKey<Analytics>,
    axiosShouldNotify: Symbol('axiosShouldNotify') as InjectionKey<Ref<boolean>>,
};

export default function useProviders() {
    const provideGeographyRepository = (app: App): void => {
        const providedGeographyRepository = new GeographyRepository();

        app.provide(providerKeys.geographyRepository, providedGeographyRepository);
    };

    const provideEcho = (app: App): void => {
        const spark = (window as any).Spark as Spark;

        if (spark.pusherAppKey) {
            (window as any).Pusher = Pusher;

            const echo = new Echo({
                broadcaster: 'pusher',
                key: spark.pusherAppKey,
                wsHost: spark.pusherHost,
                encrypted: true,
                disableStats: true,
                enabledTransports: ['ws', 'wss'],
                forceTLS: true,
            });

            app.provide(providerKeys.echo, echo);
        };
    };

    const provideBarcodeListener = (): void => {
        const barcodeScannerStore = useBarcodeScannerStore();

        const providedBarcodeListener = new BarcodeListener((barcode: string) => {
            barcodeScannerStore.setHardwareScan(barcode);
        });

        providedBarcodeListener.initScanner();

        provide(providerKeys.barcodeListener, providedBarcodeListener);
    };

    const provideUserWebsockerListener = (): void => {
        const { currentUserOptional } = useNarrative();

        if (!currentUserOptional.value) return;

        const { subscribeToPrivateChannel } = useWebsockets();
        const userWebsocketChannel = subscribeToPrivateChannel(`User.${currentUserOptional.value.id}`);

        if (!userWebsocketChannel) return;

        const providedUserWebsockerListener = new WebsocketListener(
            userWebsocketChannel,
            useUserWebsocketListener().defaultWebsocketSubscriber,
        );

        provide(providerKeys.userWebsocketListener, providedUserWebsockerListener);
    };

    const provideServiceProviderWebsockerListener = (): void => {
        const { userHasRoleOnCurrentServiceProvider, currentServiceProviderOptional } = useNarrative();

        if (!currentServiceProviderOptional.value) return;
        if (!userHasRoleOnCurrentServiceProvider.value) return;

        const { subscribeToPrivateChannel } = useWebsockets();
        const serviceProviderWebsocketChannel = subscribeToPrivateChannel(`serviceProviders.${currentServiceProviderOptional.value.id}`);

        if (!serviceProviderWebsocketChannel) return;

        const providedServiceProviderWebsockerListener = new WebsocketListener(
            serviceProviderWebsocketChannel,
            useServiceProviderWebsocketListener().defaultWebsocketSubscriber,
        );

        provide(providerKeys.serviceProviderWebsocketListener, providedServiceProviderWebsockerListener);
    };

    const provideAnalytics = (): void => {
        const analytics = useAnalytics();

        provide(providerKeys.analytics, analytics);

        analytics.initAnalytics();
    };

    return {
        provideGeographyRepository,
        provideBarcodeListener,
        provideUserWebsockerListener,
        provideServiceProviderWebsockerListener,
        provideEcho,
        provideAnalytics,
    };
}
