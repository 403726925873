import { FbaTransportBoxGroup, FbaTransportBoxGroupItem, FbaTransportV2024BoxGroup, FbaTransportV2024CreatePlanItem, FbaTransportV2024Label, FbaTransportV2024Operation, FbaTransportV2024Plan as FbaTransportV2024PlanRoot, FbaTransportV2024Shipment, FbaTransportV2024ShipmentContactInformation, FbaTransportV2024ShipmentFreightClass, FbaTransportV2024ShipmentGroup, PalletDetails, TransportDimensions } from '@conveyr/shared-types';
import itemPrepDetails from './fbaTransportV2024/itemPrepDetails';
import axios, { fetchPaginatedDataFunction } from '@/helpers/forms/axios';

export class FbaTransportPlanItemClass {
    id: string;
    quantity: number;
    sku: string;
    expiration: string | null;
    labelOwner: string;
    prepOwner: string;
    updatedAt: string;

    constructor(
        id: string,
        quantity: number,
        sku: string,
        expiration: string | null,
        labelOwner: string,
        prepOwner: string,
        updatedAt: string,
    ) {
        this.id = id;
        this.quantity = quantity;
        this.sku = sku;
        this.expiration = expiration;
        this.labelOwner = labelOwner;
        this.prepOwner = prepOwner;
        this.updatedAt = updatedAt;
    }
}

export interface FbaTransportV2024Plan extends Omit<FbaTransportV2024PlanRoot, 'items'> {
    items: FbaTransportPlanItemClass[]
}

const mapV2024PlanItemsToClass = (plan: FbaTransportV2024PlanRoot): FbaTransportV2024Plan => {
    const items = plan.items.map(item => new FbaTransportPlanItemClass(item.id, item.quantity, item.msku, item.expiration, item.labelOwner, item.prepOwner, item.updatedAt));

    return Object.assign(plan, { items });
};

const getPlans = async (outboundShipmentId: number): Promise<FbaTransportV2024Plan[]> => {
    const response = await axios.get<{ data: FbaTransportV2024PlanRoot[] }>(`/api/fba-transport/v2024/plans`, {
        params: { outbound_shipment_id: outboundShipmentId.toString() },
    });

    return response.data.data.map(mapV2024PlanItemsToClass);
};

const showPlan = async (planId: string): Promise<FbaTransportV2024Plan> => {
    const response = await axios.get<{ data: FbaTransportV2024PlanRoot }>(`/api/fba-transport/v2024/plans/${planId}`);

    return mapV2024PlanItemsToClass(response.data.data);
};

const generatePackingOptions = async (planId: string): Promise<string> => {
    const response = await axios.post(`/api/fba-transport/v2024/plans/${planId}/generate-packing-options`, {
        plan_id: planId,
    });

    return response.data;
};

const fetchPackingOptions = async (planId: string): Promise<string> => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/packing-options`, location.origin);
    url.searchParams.append('plan_id', planId);

    return await axios.get(url.toString());
};

const fetchPackingGroupItems = async (planId: string, packingOptionId: string, packingGroupId: string) => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/packing-group-items`, location.origin);
    url.searchParams.append('plan_id', planId);
    url.searchParams.append('packing_option_id', packingOptionId);
    url.searchParams.append('packing_group_id', packingGroupId);

    return await axios.get(url.toString());
};

const confirmPackingOption = async (planId: string, packingOptionId: string) => {
    const response = await axios.post(`/api/fba-transport/v2024/plans/${planId}/confirm-packing-option`, {
        plan_id: planId,
        packing_option_id: packingOptionId,
    });

    return response.data;
};

const markPackingOptionAsConfirmed = async (planId: string, packingOptionId: string) => {
    const response = await axios.post(`/api/fba-transport/v2024/plans/${planId}/mark-packing-option-as-confirmed`, {
        plan_id: planId,
        packing_option_id: packingOptionId,
    });

    return response.data;
};

const createPlan = async (
    outboundShipmentId: number,
    channelId: number,
    items: FbaTransportV2024CreatePlanItem[],
): Promise<FbaTransportV2024Plan> => {
    const response = await axios.post<{ data: FbaTransportV2024Plan }>(`/api/fba-transport/v2024/plans`, {
        outboundShipmentId,
        channelId,
        items,
    });

    return response.data.data;
};

const updatePlan = async (planId: string): Promise<void> => {
    return await axios.post(`/api/fba-transport/v2024/plans/${planId}/update`);
};

const getOperations = fetchPaginatedDataFunction<FbaTransportV2024Operation>('/api/fba-transport/v2024/operations');

const showOperation = async (operationId: string): Promise<FbaTransportV2024Operation> => {
    const response = await axios.get<{ data: FbaTransportV2024Operation }>(`/api/fba-transport/v2024/operations/${operationId}`);

    return response.data.data;
};

const fetchOperationUpdate = async (operationId: string): Promise<FbaTransportV2024Operation> => {
    const response = await axios.get<{ data: FbaTransportV2024Operation }>(`/api/fba-transport/v2024/operations/${operationId}/update`);

    return response.data.data;
};

const generatePlacementOptions = async (planId: string) => {
    const response = await axios.post(`/api/fba-transport/v2024/plans/${planId}/generate-placement-options`, {
        plan_id: planId,
    });

    return response.data;
};

const fetchPlacementOptions = async (planId: string): Promise<void> => {
    return await axios.get(`/api/fba-transport/v2024/plans/${planId}/placement-options`);
};

const resetPlacementOptions = async (planId: string): Promise<string> => {
    const response = await axios.post(`/api/fba-transport/v2024/plans/${planId}/reset-placement-options`);
    return response.data;
};

const generateDeliveryWindowOptions = async (planId: string, shipmentId: string): Promise<string> => {
    const response = await axios.post(`/api/fba-transport/v2024/plans/${planId}/generate-delivery-window-options`, {
        plan_id: planId,
        shipment_id: shipmentId,
    });

    return response.data;
};

const fetchDeliveryWindowOptions = async (planId: string, placementOptionId: string, shipmentId: string): Promise<string> => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/delivery-window-options`, location.origin);
    url.searchParams.append('plan_id', planId);
    url.searchParams.append('placement_option_id', placementOptionId);
    url.searchParams.append('shipment_id', shipmentId);

    return await axios.get(url.toString());
};

const generateTransportationOptions = async (planId: string, placementOptionId: string, shipments: { shipmentId: string, startDate: string }[]) => {
    const response = await axios.post(`/api/fba-transport/v2024/plans/${planId}/generate-transportation-options`, {
        plan_id: planId,
        placement_option_id: placementOptionId,
        shipments_dates: shipments,
    });

    return response.data;
};

const fetchTransportationOptions = async (planId: string, placementOptionId: string, shipmentId: string) => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/transportation-options`, location.origin);
    url.searchParams.append('plan_id', planId);
    url.searchParams.append('placement_option_id', placementOptionId);
    url.searchParams.append('shipment_id', shipmentId);

    return await axios.get(url.toString());
};

const createBoxGroup = async (planId: string, associatedEntityId: string, dimensions: TransportDimensions): Promise<FbaTransportBoxGroup> => {
    const response = await axios.post<{ data: FbaTransportBoxGroup }>(`/api/fba-transport/v2024/plans/${planId}/box-groups`, {
        ...dimensions,
        associatedEntityId,
        version: 'v2024',
    });

    return response.data.data;
};

const updateBoxGroup = async (planId: string, boxGroupId: string, dimensions: TransportDimensions): Promise<void> => {
    return axios.put(`/api/fba-transport/v2024/plans/${planId}/box-groups/${boxGroupId}`, {
        ...dimensions,
        version: 'v2024',
    });
};

const removeBoxGroup = async (planId: string, boxGroupId: string): Promise<void> => {
    return axios.delete(`/api/fba-transport/v2024/plans/${planId}/box-groups/${boxGroupId}`, {
        data: { version: 'v2024' },
    });
};

const setBoxQuantityForBoxGroup = async (planId: string, boxGroupId: string, quantity: number): Promise<number[]> => {
    const response = await axios.post<{ boxNumbers: number[] }>(`/api/fba-transport/v2024/plans/${planId}/box-groups/${boxGroupId}/set-box-quantity`, {
        quantity,
        version: 'v2024',
    });

    return response.data.boxNumbers;
};

const createBoxGroupItem = async (
    planId: string,
    boxGroupId: string,
    item: Pick<FbaTransportBoxGroupItem, 'planItemId' | 'quantity'>,
): Promise<FbaTransportBoxGroupItem> => {
    const response = await axios.post<{ boxGroupItem: FbaTransportBoxGroupItem }>(`/api/fba-transport/v2024/plans/${planId}/box-groups/${boxGroupId}/items`, {
        ...item,
        version: 'v2024',
    });

    return response.data.boxGroupItem;
};

const updateBoxGroupItem = async (
    planId: string,
    boxGroupId: string,
    itemId: string,
    quantity: number,
): Promise<FbaTransportBoxGroupItem> => {
    const response = await axios.put<{ boxGroupItem: FbaTransportBoxGroupItem }>(`/api/fba-transport/v2024/plans/${planId}/box-groups/${boxGroupId}/items/${itemId}`, {
        quantity,
        version: 'v2024',
    });

    return response.data.boxGroupItem;
};

const removeBoxGroupItem = async (planId: string, boxGroupId: string, itemId: string): Promise<void> => {
    return axios.delete(`/api/fba-transport/v2024/plans/${planId}/box-groups/${boxGroupId}/items/${itemId}`, {
        data: { version: 'v2024' },
    });
};

const createPallet = async (planId: string, placementOptionId: string, shipmentId: string, details: PalletDetails): Promise<void> => {
    return axios.post(`/api/fba-transport/v2024/plans/${planId}/pallets`, {
        placementOptionId,
        shipmentId,
        ...details,
    });
};

const updatePallet = async (planId: string, placementOptionId: string, shipmentId: string, palletId: string, details: PalletDetails): Promise<void> => {
    return axios.put(`/api/fba-transport/v2024/plans/${planId}/pallets/${palletId}`, {
        placementOptionId,
        shipmentId,
        ...details,
    });
};

const removePallet = async (planId: string, placementOptionId: string, shipmentId: string, palletId: string): Promise<void> => {
    return axios.delete(`/api/fba-transport/v2024/plans/${planId}/pallets/${palletId}`, {
        data: {
            placementOptionId,
            shipmentId,
        },
    });
};

const setNonPartneredLtlTracking = async (
    planId: string,
    shipmentId: string,
    billOfLadingNumber: string,
    freightBillNumber: string[],
): Promise<string> => {
    const response = await axios.post(`/api/fba-transport/v2024/plans/${planId}/update-tracking-ltl-details`, {
        planId,
        shipmentId,
        billOfLadingNumber,
        freightBillNumber,
    });

    return response.data;
};

const setNonPartneredSmallParcelTracking = async (
    planId: string,
    shipmentId: string,
    boxTrackingIds: { amazonBoxId: string, trackingId: string }[],
): Promise<string> => {
    const response = await axios.post(`/api/fba-transport/v2024/plans/${planId}/update-tracking-small-details`, {
        planId,
        shipmentId,
        boxTrackingIds,
    });
    return response.data;
};

const updateContactInformation = async (planId: string, placementOptionId: string, shipmentId: string, details: FbaTransportV2024ShipmentContactInformation): Promise<void> => {
    return axios.post(`/api/fba-transport/v2024/plans/${planId}/update-contact-information`, {
        planId,
        placementOptionId,
        shipmentId,
        ...details,
    });
};

const updateFreightInformation = async (
    planId: string,
    placementOptionId: string,
    shipmentId: string,
    details: {
        freightClass: FbaTransportV2024ShipmentFreightClass
        amount?: string
        code?: string
    },
): Promise<void> => {
    return axios.post(`/api/fba-transport/v2024/plans/${planId}/update-freight-information`, {
        planId,
        placementOptionId,
        shipmentId,
        ...details,
    });
};

const setFirstAvailabilityDate = async (planId: string, placementOptionId: string, shipmentId: string, firstAvailabilityDate: string): Promise<void> => {
    return axios.post(`/api/fba-transport/v2024/plans/${planId}/set-first-availability-date`, {
        planId,
        placementOptionId,
        shipmentId,
        firstAvailabilityDate,
    });
};

const setTransportModePreference = async (planId: string, placementOptionId: string, shipmentId: string, transportModePreference: FbaTransportV2024ShipmentGroup): Promise<void> => {
    return axios.post(`/api/fba-transport/v2024/plans/${planId}/set-transport-mode-preference`, {
        planId,
        placementOptionId,
        shipmentId,
        transportModePreference,
    });
};

const getLabels = async (planId: string, shipmentId: string): Promise<FbaTransportV2024Label[]> => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/labels`, location.origin);
    url.searchParams.append('shipment_id', shipmentId);

    const response = await axios.get(url.toString());
    return response.data.data;
};

const requestPackageLabels = async (planId: string, shipmentId: string): Promise<void> => {
    return await axios.post(`/api/fba-transport/v2024/plans/${planId}/request-package-labels`, { shipmentId });
};

const requestBoxLabels = async (planId: string, shipmentId: string, boxGroupId: string | null = null): Promise<void> => {
    return await axios.post(`/api/fba-transport/v2024/plans/${planId}/request-box-labels`, { shipmentId, boxGroupId });
};

const requestPalletLabels = async (planId: string, shipmentId: string, pallets: { number: number }[]): Promise<void> => {
    return await axios.post(`/api/fba-transport/v2024/plans/${planId}/request-pallet-labels`, { shipmentId, pallets });
};

const downloadPalletLabels = async (planId: string, shipmentId: string): Promise<void> => {
    return await axios.post(`/api/fba-transport/v2024/plans/${planId}/download-pallet-labels`, { shipmentId });
};

const requestBillOfLading = async (planId: string, shipmentId: string): Promise<void> => {
    return await axios.post(`/api/fba-transport/v2024/plans/${planId}/request-bill-of-lading`, { shipmentId });
};

const resizeToFourBySix = async (planId: string, shipmentId: string, labelId: string): Promise<void> => {
    return await axios.post(`/api/fba-transport/v2024/plans/${planId}/labels/${labelId}/resize-to-4x6`, { shipmentId });
};

const changeUsing2dBarcodes = async (planId: string, using2dBarcodes: boolean): Promise<void> => {
    return axios.post(`/api/fba-transport/v2024/plans/${planId}/change-using-2d-barcodes`, { using2dBarcodes });
};

const setPackingInformation = async (planId: string) => {
    const response = axios.post(`/api/fba-transport/v2024/plans/${planId}/set-packing-information`, {
        planId,
    });

    return (await response).data;
};

const markPackingInformationAsConfirmed = async (planId: string) => {
    const response = axios.post(`/api/fba-transport/v2024/plans/${planId}/add-carton-request-to-submission`, {
        planId,
    });

    return (await response).data;
};

const resetSubmissions = async (planId: string): Promise<string> => {
    const response = await axios.post(`/api/fba-transport/v2024/plans/${planId}/reset-submissions`);
    return response.data;
};

const selectPlacementOption = async (planId: string, placementOptionId: string): Promise<string> => {
    return await axios.post(`/api/fba-transport/v2024/plans/${planId}/select-placement-option`, {
        planId,
        placementOptionId,
    });
};

const confirmPlacementOption = async (planId: string, placementOptionId: string): Promise<string> => {
    const { data } = await axios.post(`/api/fba-transport/v2024/plans/${planId}/confirm-placement-option`, {
        planId,
        placementOptionId,
    });
    return data;
};

const markPlacementOptionAsConfirmed = async (planId: string, placementOptionId: string) => {
    const { data } = await axios.post(`/api/fba-transport/v2024/plans/${planId}/mark-placement-option-as-confirmed`, {
        planId,
        placementOptionId,
    });
    return data;
};

const setBoxesIds = async (planId: string): Promise<void> => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/set-boxes-ids`, location.origin);
    return await axios.get(url.toString());
};

const updateShipmentName = async (planId: string, shipmentId: string, name: string): Promise<string> => {
    return await axios.post(`/api/fba-transport/v2024/plans/${planId}/update-shipment-name`, {
        shipmentId,
        name,
    });
};

const confirmDeliveryWindowOption = async (planId: string, placementOptionId: string, shipmentId: string, deliveryWindowOptionId: string): Promise<string> => {
    const { data } = await axios.post(`/api/fba-transport/v2024/plans/${planId}/confirm-delivery-window-option`, {
        planId,
        placementOptionId,
        shipmentId,
        deliveryWindowOptionId,
    });
    return data;
};

const markDeliveryWindowOptionAsConfirmed = async (planId: string, shipmentId: string, deliveryWindowOptionId: string) => {
    const { data } = await axios.post(`/api/fba-transport/v2024/plans/${planId}/mark-delivery-window-option-as-confirmed`, {
        planId,
        shipmentId,
        deliveryWindowOptionId,
    });
    return data;
};

const confirmTransportationOption = async (planId: string, placementOptionId: string, shipments: { shipmentId: string, transportationOptionId: string }[]): Promise<string> => {
    const { data } = await axios.post(`/api/fba-transport/v2024/plans/${planId}/confirm-transportation-option`, {
        plan_id: planId,
        placement_option_id: placementOptionId,
        shipment_transportation_ids: shipments,
    });
    return data;
};

const markTransportationOptionsAsConfirmed = async (planId: string, shipments: { shipmentId: string, transportationOptionId: string }[]) => {
    const { data } = await axios.post(`/api/fba-transport/v2024/plans/${planId}/mark-transportation-option-as-confirmed`, {
        planId,
        shipment_transportation_ids: shipments,
    });
    return data;
};

const getShipments = async (planId: string, placementOptionId: string): Promise<FbaTransportV2024Shipment[]> => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/get-shipment`, location.origin);
    url.searchParams.append('plan_id', planId);
    url.searchParams.append('placement_option_id', placementOptionId);

    const response = await axios.get<{ data: FbaTransportV2024Shipment[] }>(url.toString());

    return response.data.data;
};

const fetchShipmentBoxGroups = async (planId: string, placementOptionId: string, shipmentId: string): Promise<FbaTransportV2024BoxGroup[]> => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/list-shipment-boxes`, location.origin);
    url.searchParams.append('plan_id', planId);
    url.searchParams.append('placement_option_id', placementOptionId);
    url.searchParams.append('shipment_id', shipmentId);

    const response = await axios.get<{ data: FbaTransportV2024BoxGroup[] }>(url.toString());

    return response.data.data;
};

const cancelPlanChecker = async (planId: string) => {
    const { data } = await axios.post(`/api/fba-transport/v2024/plans/${planId}/cancel-plan-checker`, {
        planId,
    });
    return data;
};

const cancelPlan = async (planId: string) => {
    const { data } = await axios.post(`/api/fba-transport/v2024/plans/${planId}/cancel-plan`, {
        planId,
    });
    return data;
};

const markPlanAsCanceled = async (planId: string) => {
    const { data } = await axios.post(`/api/fba-transport/v2024/plans/${planId}/mark-plan-as-canceled`, {
        planId,
    });
    return data;
};

const getPickListUrl = async (planId: string, placementOptionId: string, shipmentId: string): Promise<string> => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/pick-list`, location.origin);
    url.searchParams.append('plan_id', planId);
    url.searchParams.append('placement_option_id', placementOptionId);
    url.searchParams.append('shipment_id', shipmentId);

    const res = await axios.get(url.toString());

    return res.data.url;
};

const getPackListUrl = async (planId: string, placementOptionId: string, shipmentId: string): Promise<string> => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/pack-list`, location.origin);
    url.searchParams.append('plan_id', planId);
    url.searchParams.append('placement_option_id', placementOptionId);
    url.searchParams.append('shipment_id', shipmentId);

    const res = await axios.get(url.toString());

    return res.data.url;
};

export default {
    getPlans,
    showPlan,
    createPlan,
    updatePlan,
    getOperations,
    showOperation,
    fetchOperationUpdate,
    generatePackingOptions,
    fetchPackingOptions,
    fetchPackingGroupItems,
    confirmPackingOption,
    markPackingOptionAsConfirmed,
    generatePlacementOptions,
    fetchPlacementOptions,
    resetPlacementOptions,
    generateDeliveryWindowOptions,
    fetchDeliveryWindowOptions,
    generateTransportationOptions,
    fetchTransportationOptions,
    createBoxGroup,
    updateBoxGroup,
    removeBoxGroup,
    setBoxQuantityForBoxGroup,
    createBoxGroupItem,
    updateBoxGroupItem,
    removeBoxGroupItem,
    createPallet,
    updatePallet,
    removePallet,
    setNonPartneredLtlTracking,
    setNonPartneredSmallParcelTracking,
    updateContactInformation,
    updateFreightInformation,
    setFirstAvailabilityDate,
    setTransportModePreference,
    changeUsing2dBarcodes,
    setPackingInformation,
    markPackingInformationAsConfirmed,
    resetSubmissions,
    getLabels,
    requestPackageLabels,
    requestBoxLabels,
    requestPalletLabels,
    downloadPalletLabels,
    requestBillOfLading,
    resizeToFourBySix,
    selectPlacementOption,
    confirmPlacementOption,
    markPlacementOptionAsConfirmed,
    setBoxesIds,
    updateShipmentName,
    confirmDeliveryWindowOption,
    markDeliveryWindowOptionAsConfirmed,
    confirmTransportationOption,
    markTransportationOptionsAsConfirmed,
    getShipments,
    fetchShipmentBoxGroups,

    itemPrepDetails,
    cancelPlanChecker,
    cancelPlan,
    markPlanAsCanceled,
    getPickListUrl,
    getPackListUrl,
};
