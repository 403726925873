<template>
    <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="overflow-hidden sm:rounded-lg">
                    <table class="min-w-full">
                        <thead class="bg-gray-50">
                            <tr ref="tableHeaderRowRef">
                                <slot name="headers" />
                            </tr>
                        </thead>
                        <tbody>
                            <slot>
                                <template v-if="loading">
                                    <tr v-for="n in 7" :key="n">
                                        <base-table-cell
                                            v-for="c in numberOfColumns"
                                            :key="c"
                                            padding="narrow"
                                        >
                                            <div
                                                class="h-6 w-full rounded-sm animate-pulse"
                                                :class="c % 2 === 0 ? 'bg-gray-200' : 'bg-gray-300'"
                                            />
                                        </base-table-cell>
                                    </tr>
                                </template>
                            </slot>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';

defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
});

const tableHeaderRowRef = ref<InstanceType<typeof HTMLTableRowElement>>();
const numberOfColumns = computed(() => {
    return tableHeaderRowRef.value?.childElementCount ?? 5;
});
</script>
