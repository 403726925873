import { Placement, flip, offset, shift } from '@floating-ui/dom';
import { DirectiveBinding } from 'vue';
import useFloatingUi from '@/composables/useFloatingUi';

export function createTooltip(el: HTMLElement, text: string, placement: Placement) {
    if (!text) return;
    if (el.nextElementSibling?.classList.contains('vTooltip')) {
        el.nextElementSibling.textContent = text;
        return;
    };

    const tooltipId = `vTooltip-${Math.random().toString(36).slice(-5)}`;
    el.classList.add(`floatingUiTrigger-${tooltipId}`);

    const tooltip = document.createElement('div');
    tooltip.setAttribute('role', 'tooltip');
    tooltip.textContent = text;
    tooltip.classList.add('absolute', 'hidden', 'bg-gray-700', 'text-gray-100', 'font-medium', 'text-xs', 'py-1', 'px-2', 'rounded-md', 'transition-opacity', 'opacity-0', 'z-10', 'normal-case');
    tooltip.classList.add('vTooltip', `floatingUiElement-${tooltipId}`);

    const arrowElement = document.createElement('div');
    arrowElement.classList.add('floatingUiArrow', 'absolute', 'bg-gray-700', 'w-2', 'h-2', 'rotate-45');

    tooltip.append(arrowElement);

    el.insertAdjacentElement('afterend', tooltip);

    const { updateFloatingUiElement } = useFloatingUi(tooltipId, {
        placement,
        middleware: [
            flip(),
            shift({ padding: 10 }),
            offset(10),
        ],
    }, {
        addArrow: true,
    });

    addEventListeners(el, tooltip, updateFloatingUiElement);
}

export function bind(el: HTMLElement, { value, modifiers }: DirectiveBinding) {
    let placement: Placement = 'top';
    if (Object.keys(modifiers).length) {
        placement = getPlacementFromModifier(Object.keys(modifiers)[0]);
    }

    createTooltip(el, value, placement);
}

function addEventListeners(el: HTMLElement, tooltip: HTMLElement, updateFloatingUiElement: Function) {
    const eventListeners: { event: string, listener: any }[] = [
        { event: 'mouseenter', listener: () => showTooltip(tooltip, updateFloatingUiElement) },
        { event: 'focus', listener: () => showTooltip(tooltip, updateFloatingUiElement) },
        { event: 'mouseleave', listener: () => hideTooltip(tooltip) },
        { event: 'blur', listener: () => hideTooltip(tooltip) },
    ];

    eventListeners.forEach(({ event, listener }) => {
        el.addEventListener(event, listener);
    });

    const observeElement = el.parentElement;
    if (!observeElement) return;

    new MutationObserver((_mutationsList) => {
        if (el.offsetParent === null) {
            hideTooltip(tooltip);
        }
    }).observe(observeElement, {
        attributes: true,
        childList: true,
    });
}

function showTooltip(tooltip: HTMLElement, updateFloatingUiElement: Function) {
    tooltip.classList.add('tooltip-will-show');
    setTimeout(() => {
        if (tooltip.classList.contains('tooltip-will-show')) {
            tooltip.classList.remove('opacity-0', 'hidden');
            tooltip.classList.add('opacity-90');

            updateFloatingUiElement();
        }
    }, 300);

    setTimeout(() => {
        // Nuclear option if an overlay comes up
        hideTooltip(tooltip);
    }, 10000);
}

function hideTooltip(tooltip: HTMLElement) {
    tooltip.classList.add('opacity-0', 'hidden');
    tooltip.classList.remove('opacity-90');
    tooltip.classList.remove('tooltip-will-show');
}

function getPlacementFromModifier(modifier: string): Placement {
    const validPlacements = ['top', 'right', 'bottom', 'left', 'top-start', 'top-end', 'right-start', 'right-end', 'bottom-start', 'bottom-end', 'left-start', 'left-end'];
    if (!validPlacements.includes(modifier)) {
        console.error(`v-tooltip argument is not valid: ${modifier}`);

        return 'top';
    }

    return modifier as Placement;
}

export default {
    mounted: bind,
    updated: bind,
};
